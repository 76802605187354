import logo from "../../assets/finqube-navbar-logo.png";
import logosm from "../../assets/Finqube-05.png";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Logout} from "../../actions/actions";
import React from "react";

function LandingPageNavbar() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    return (
        <>
            <div className={'landing-page-navbar'}>
                <Link to={'/market-overview/'} className={'landing-page-navbar-img-link'}
                      style={{margin: "auto auto auto 0"}}>
                    <img alt={"logo"} src={logo} className={'landing-page-navbar-img'}/>
                </Link>

                <Link to={'/market-overview/'} className={'landing-page-navbar-img-sm-link'}
                      style={{margin: "auto auto auto 0"}}>
                    <img alt={"logo"} src={logosm} className={'landing-page-navbar-img-sm'}/>
                </Link>

                <a href={'https://web.finqube.io/about'} className={'landing-page-navbar-link'}>About</a>
                <a href={'https://web.finqube.io/about'} className={'landing-page-navbar-link'}>FAQ</a>
                <a href={'https://web.finqube.io/tools/'} className={'landing-page-navbar-link'}>Tools</a>
                {/*<a href={'https://web.finqube.io/blog'} className={'landing-page-navbar-link'}>Blog</a>*/}
                <Link to={'/payment/'}
                      className={'landing-page-navbar-link'}>Pricing</Link>

                {isAuthenticated &&
                    <div className={'profile-image-container'} onClick={() => navigate(`/profile/`)}
                         style={{backgroundImage: user.img !== '' && `url(${user.img})`}}
                    >
                    </div>
                }

                {isAuthenticated ?
                    <button className={'btn btn-warning'}
                            onClick={() => dispatch(Logout())}
                            style={{fontSize: 10, fontWeight: 700, height: 30, margin: "auto 0 auto 10px"}}>
                        Logout
                    </button>
                    :
                    <button className={'btn btn-warning'}
                            onClick={() => navigate('/login')}
                            style={{fontSize: 10, fontWeight: 700, height: 30, margin: "auto 0 auto 10px"}}>
                        Login
                    </button>
                }
            </div>
        </>
    );
}

export default LandingPageNavbar;