import React, {useEffect, useState} from "react";
import axios from "axios";
import {divide, formatNumericValue, return_obj, return_string, subtract} from "../Utils";
import RevGrowthNetOCFFCFChart from "./FinancialTabs/FinancialOverview/FinancialCharts/RevGrowthNetOCFFCFChart";
import iSqoreGrowthDark from "../../assets/blockers/iSqoreGrowthDark.png";
import iSqoreGrowthLight from "../../assets/blockers/iSqoreGrowthLight.png";
import RestrictedContent from "../RestrictedContent";
import ISqoreTable from "../ISqoreTabs/ISqoreTable";

const GrowthTab = ({code, exchange}) => {
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState(null)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-value/${code}/${exchange}/`)
            .then(response => {
                format_data(response.data)
                setLoading(false)
            })
            .catch(error => console.log(error.message))
    }, [code, exchange])

    const format_data = (values) => {
        const eps_growth_fwd_value = formatNumericValue({
            value: ![-1, null].includes(divide(subtract(values['earning-trend']['epsTrendCurrent'], values['earning-trend-0']['epsTrendCurrent']), Math.abs(values['earning-trend-0']['epsTrendCurrent']))) ?
                divide(subtract(values['earning-trend']['epsTrendCurrent'], values['earning-trend-0']['epsTrendCurrent']), Math.abs(values['earning-trend-0']['epsTrendCurrent'])) : null,
            multiply: 1
        })
        const eps_growth_fwd_grade = ![-1, null].includes(divide(subtract(values['earning-trend']['epsTrendCurrent'], values['earning-trend-0']['epsTrendCurrent']), Math.abs(values['earning-trend-0']['epsTrendCurrent']))) ?
            values['iSqore']['earningsPerShareGrowthEstimateGrade'] : '-'

        const data = {
            tableData: [
                return_obj(
                    "Revenue Growth (Fwd)",
                    values['earning-trend']['revenueEstimateGrowth'] !== -1 ? values['earning-trend']['revenueEstimateGrowth'] : null,
                    values['iSqore']['totalRevenueGrowthEstimateGrade'],

                    values['median_sector']['totalRevenueGrowthEstimate'],
                    values['calculation-3-year-avg']['historical_data']['totalRevenue'],
                    values['calculation-5-year-avg']['historical_data']['totalRevenue'],
                    true
                ),
                return_obj(
                    "Revenue Growth (TTM)",
                    values['calculation-growth-quarterly-ttm']['historical_data']['totalRevenue'],
                    values['iSqore']['revenueGrowthTTMGrade'],

                    values['median_sector']['revenueGrowthTTM'],
                    values['calculation-3-year-avg']['historical_data']['totalRevenue'],
                    values['calculation-5-year-avg']['historical_data']['totalRevenue'],
                    true
                ),
                return_obj(
                    "Revenue Growth (YoY)",
                    values['calculation-quarterly-yoy']['historical_data']['totalRevenue'],
                    values['iSqore']['revenueGrowthYOYGrade'],

                    values['median_sector']['revenueGrowthYOY'],
                    values['calculation-3-year-avg']['historical_data']['totalRevenue'],
                    values['calculation-5-year-avg']['historical_data']['totalRevenue'],
                    true
                ),
                return_obj(
                    "Revenue Growth (Year)",
                    values['calculation-growth-yearly']['historical_data']['totalRevenue'],
                    values['iSqore']['revenueGrowthYearGrade'],

                    values['median_sector']['revenueGrowthYear'],
                    values['calculation-3-year-avg']['historical_data']['totalRevenue'],
                    values['calculation-5-year-avg']['historical_data']['totalRevenue'],
                    true
                ),

                return_obj(
                    "Net Income Growth (Fwd)",
                    values['earning-trend']['earningsEstimateGrowth'] !== -1 ? values['earning-trend']['earningsEstimateGrowth'] : null,
                    values['iSqore']['earningsGrowthEstimateGrade'],

                    values['median_sector']['earningsGrowthEstimate'],
                    values['calculation-3-year-avg']['historical_data']['netIncome_income_statement'],
                    values['calculation-5-year-avg']['historical_data']['netIncome_income_statement'],
                    true
                ),
                return_obj(
                    "Net Income Growth (TTM)",
                    values['calculation-growth-quarterly-ttm']['historical_data']['netIncome_income_statement'],
                    values['iSqore']['earningsGrowthTTMGrade'],

                    values['median_sector']['earningsGrowthTTM'],
                    values['calculation-3-year-avg']['historical_data']['netIncome_income_statement'],
                    values['calculation-5-year-avg']['historical_data']['netIncome_income_statement'],
                    true
                ),
                return_obj(
                    "Net Income Growth (YoY)",
                    values['calculation-quarterly-yoy']['historical_data']['netIncome_income_statement'],
                    values['iSqore']['earningsGrowthYOYGrade'],

                    values['median_sector']['earningsGrowthYOY'],
                    values['calculation-3-year-avg']['historical_data']['netIncome_income_statement'],
                    values['calculation-5-year-avg']['historical_data']['netIncome_income_statement'],
                    true
                ),
                return_obj(
                    "Net Income Growth (Year)",
                    values['calculation-growth-yearly']['historical_data']['netIncome_income_statement'],
                    values['iSqore']['earningsGrowthYearGrade'],

                    values['median_sector']['earningsGrowthYear'],
                    values['calculation-3-year-avg']['historical_data']['netIncome_income_statement'],
                    values['calculation-5-year-avg']['historical_data']['netIncome_income_statement'],
                    true
                ),

                return_obj(
                    "EPS Growth (Fwd)",
                    eps_growth_fwd_value,
                    eps_growth_fwd_grade,

                    values['median_sector']['earningsPerShareGrowthEstimate'],
                    values['calculation-3-year-avg']['historical_data']['earningsPerShare'],
                    values['calculation-5-year-avg']['historical_data']['earningsPerShare'],
                    true
                ),
                return_obj(
                    "EPS Growth (TTM)",
                    values['calculation-growth-quarterly-ttm']['historical_data']['earningsPerShare'],
                    values['iSqore']['earningsPerShareGrowthTTMGrade'],

                    values['median_sector']['earningsPerShareGrowthTTM'],
                    values['calculation-3-year-avg']['historical_data']['earningsPerShare'],
                    values['calculation-5-year-avg']['historical_data']['earningsPerShare'],
                    true
                ),
                return_obj(
                    "EPS Growth (YoY)",
                    values['calculation-quarterly-yoy']['historical_data']['earningsPerShare'],
                    values['iSqore']['earningsPerShareGrowthYOYGrade'],

                    values['median_sector']['earningsPerShareGrowthYOY'],
                    values['calculation-3-year-avg']['historical_data']['earningsPerShare'],
                    values['calculation-5-year-avg']['historical_data']['earningsPerShare'],
                    true
                ),
                return_obj(
                    "EPS Growth (Year)",
                    values['calculation-growth-yearly']['historical_data']['earningsPerShare'],
                    values['iSqore']['earningsPerShareGrowthYearGrade'],

                    values['median_sector']['earningsPerShareGrowthYear'],
                    values['calculation-3-year-avg']['historical_data']['earningsPerShare'],
                    values['calculation-5-year-avg']['historical_data']['earningsPerShare'],
                    true
                ),


                return_obj(
                    "EBIT Growth (TTM)",
                    values['calculation-growth-quarterly-ttm']['historical_data']['ebit'],
                    values['iSqore']['ebitGrowthTTMGrade'],

                    values['median_sector']['ebitGrowthTTM'],
                    values['calculation-3-year-avg']['historical_data']['ebit'],
                    values['calculation-5-year-avg']['historical_data']['ebit'],
                    true
                ),
                return_obj(
                    "EBIT Growth (YoY)",
                    values['calculation-quarterly-yoy']['historical_data']['ebit'],
                    values['iSqore']['ebitGrowthYOYGrade'],

                    values['median_sector']['ebitGrowthYOY'],
                    values['calculation-3-year-avg']['historical_data']['ebit'],
                    values['calculation-5-year-avg']['historical_data']['ebit'],
                    true
                ),
                return_obj(
                    "EBIT Growth (Year)",
                    values['calculation-growth-yearly']['historical_data']['ebit'],
                    values['iSqore']['ebitGrowthYearGrade'],

                    values['median_sector']['ebitGrowthYear'],
                    values['calculation-3-year-avg']['historical_data']['ebit'],
                    values['calculation-5-year-avg']['historical_data']['ebit'],
                    true
                ),


                return_obj(
                    "EBITDA Growth (TTM)",
                    values['calculation-growth-quarterly-ttm']['historical_data']['ebitda'],
                    values['iSqore']['ebitdaGrowthTTMGrade'],

                    values['median_sector']['ebitdaGrowthTTM'],
                    values['calculation-3-year-avg']['historical_data']['ebitda'],
                    values['calculation-5-year-avg']['historical_data']['ebitda'],
                    true
                ),
                return_obj(
                    "EBITDA Growth (YoY)",
                    values['calculation-quarterly-yoy']['historical_data']['ebitda'],
                    values['iSqore']['ebitdaGrowthYOYGrade'],

                    values['median_sector']['ebitdaGrowthYOY'],
                    values['calculation-3-year-avg']['historical_data']['ebitda'],
                    values['calculation-5-year-avg']['historical_data']['ebitda'],
                    true
                ),
                return_obj(
                    "EBITDA Growth (Year)",
                    values['calculation-growth-yearly']['historical_data']['ebitda'],
                    values['iSqore']['ebitdaGrowthYearGrade'],

                    values['median_sector']['ebitdaGrowthYear'],
                    values['calculation-3-year-avg']['historical_data']['ebitda'],
                    values['calculation-5-year-avg']['historical_data']['ebitda'],
                    true
                ),


                return_obj(
                    "FCF Growth (TTM)",
                    values['calculation-growth-quarterly-ttm']['historical_data']['freeCashFlow'],
                    values['iSqore']['freeCashFlowGrowthTTMGrade'],

                    values['median_sector']['freeCashFlowGrowthTTM'],
                    values['calculation-3-year-avg']['historical_data']['freeCashFlow'],
                    values['calculation-5-year-avg']['historical_data']['freeCashFlow'],
                    true
                ),
                return_obj(
                    "FCF Growth (YoY)",
                    values['calculation-quarterly-yoy']['historical_data']['freeCashFlow'],
                    values['iSqore']['freeCashFlowGrowthYOYGrade'],

                    values['median_sector']['freeCashFlowGrowthYOY'],
                    values['calculation-3-year-avg']['historical_data']['freeCashFlow'],
                    values['calculation-5-year-avg']['historical_data']['freeCashFlow'],
                    true
                ),
                return_obj(
                    "FCF Growth (Year)",
                    values['calculation-growth-yearly']['historical_data']['freeCashFlow'],
                    values['iSqore']['freeCashFlowGrowthYearGrade'],

                    values['median_sector']['freeCashFlowGrowthYear'],
                    values['calculation-3-year-avg']['historical_data']['freeCashFlow'],
                    values['calculation-5-year-avg']['historical_data']['freeCashFlow'],
                    true
                ),

                return_obj(
                    "High Growth Ratio (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['highGrowthRatio'],
                    values['iSqore']['highGrowthRatioTTMGrade'],

                    values['median_sector']['highGrowthRatioTTM'],
                    values['calculation-3-year-avg']['historical_data']['highGrowthRatio'],
                    values['calculation-5-year-avg']['historical_data']['highGrowthRatio'],
                    true
                ),
                return_obj(
                    "High Growth Ratio (Year)",
                    values['additional_cal']['highGrowthRatio'],
                    values['iSqore']['highGrowthRatioYearGrade'],

                    values['median_sector']['highGrowthRatioYear'],
                    values['calculation-3-year-avg']['historical_data']['highGrowthRatio'],
                    values['calculation-5-year-avg']['historical_data']['highGrowthRatio'],
                    true
                )
            ],
            iSqore: {
                value: values['iSqore']['growth'],
                grade: return_string(values['iSqore'], 'growthGrade'),
            }
        }
        setData(data)
    }

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use  this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={iSqoreGrowthDark}
            lightBlockerImg={iSqoreGrowthLight}
            code={code}
            exchange={exchange}

            content={
                loading ?
                    <div style={{width: '100%', height: '100%', display: ' flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <>
                        <div className="row g-0">

                            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12">
                                <ISqoreTable
                                    name={'Growth'}
                                    data={data}/>
                            </div>

                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12">
                                <RevGrowthNetOCFFCFChart code={code} exchange={exchange} type={'annual'}
                                                         colors={["#3e9fed", "#29f3a7", "#ee1981"]}/>
                            </div>

                        </div>
                    </>
            }
        />
    );
}

export default GrowthTab;