import React, {useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../Context/ThemeContext";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Config from "../../Config";
import axios from "axios";
import {return_strong_num, tableDataRow} from "../Utils";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {load_common_stock} from "../../actions/actions";
import * as Utils from "../Utils";
import {toast} from "react-hot-toast";
import clsx from "clsx";
import LoginPaymentBlocker from "../LoginPaymentBlocker";
import indexComponentBlockerDark from "../../assets/blockers/index-components-blocker-dark.png";
import indexComponentBlockerLight from "../../assets/blockers/index-components-blocker-light.png";
import LgSelectableTable from "../LgSelectableTable";
import {Modal} from "@material-ui/core";
import SelectWatchListModal from "../Tabs/IndicesPageTabs/SelectWatchListModal";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import building from "../../assets/default-building.png";

const get_index_components = async (url, code) => {

    try {
        const response = await axios.post(url, {code}, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

function TsiIndexTable({selectedIndex}) {
    const {webTheme} = useContext(ThemeContext);
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [columns, setColumns] = useState([
        {
            index: 0,
            value: '',
            label: '',
            isFixed: true,
            state: 0,
            sort: false,
            className: "indices-table-star"
        },
        {
            index: 1,
            value: 'code',
            label: 'Ticker',
            isFixed: true,
            state: 0,
            sort: false,
            className: "indices-table-code"
        },
        {
            index: 2,
            value: 'name',
            label: 'Company Name',
            isFixed: true,
            state: 0,
            sort: false,
            className: "tsi-company-name"
        },
        {
            index: 3,
            value: 'market_cap',
            label: 'MCap (mln)',
            state: 0,
            sort: false,
            className: "tsi-company-generic-col item-right-align-th"
        },
        {
            index: 4,
            value: 'adjusted_close',
            label: 'Last Price',
            state: 0,
            sort: false,
            className: "tsi-company-generic-col item-right-align-th"
        },
        {
            index: 5,
            value: 'change_p',
            label: 'Change %',
            state: 0,
            sort: false,
            className: "tsi-company-generic-col item-right-align-th"
        },
        {
            index: 6,
            value: 'TSI Rank',
            label: 'TSI Rank',
            isFixed: true,
            state: 0,
            sort: false,
            className: "tsi-company-generic-col item-right-align-th"
        },
        {
            index: 7,
            value: 'TSI Score',
            label: 'TSI Score',
            isFixed: true,
            state: 0,
            sort: false,
            className: "tsi-company-generic-col item-right-align-th"
        },
    ])
    const [selectedIndexComponents, setSelectedIndexComponents] = useState([]);
    const [paginatedTableData, setPaginatedTableData] = useState([])

    const [loadingSelectedComponentData, setLoadingSelectedComponentData] = useState(true);

    const url = `${process.env.REACT_APP_API_URL}/finqube-api/get-tsi-index-components/?page=1`

    const [componentLoadingStat, setComponentLoadingStat] = useState({
        url: url, length: 0, see_more: false
    })

    useEffect(() => {
        if (isAuthenticated) {

            setComponentLoadingStat({
                url: url, length: 0, see_more: false
            })
            setLoadingSelectedComponentData(true)
            setPage(0)

            setPaginatedTableData([])
            setSelectedIndexComponents([])
        }
    }, [selectedIndex, isAuthenticated])

    const {data: indexComponentsReturn, isLoading: indexComponentReturnIsLoading, refetch} = useQuery({
        queryKey: [`get-index-components`, componentLoadingStat.url, selectedIndex],
        queryFn: () => get_index_components(componentLoadingStat.url, selectedIndex),
        staleTime: 5 * 60000,
        enabled: false
    })

    useEffect(() => {
        if (loadingSelectedComponentData && selectedIndexComponents.length === 0) {
            void refetch()
        }
    }, [selectedIndexComponents]);

    useEffect(() => {
        if (!indexComponentReturnIsLoading && indexComponentsReturn) {
            load_components(indexComponentsReturn)
        }
    }, [indexComponentsReturn, indexComponentReturnIsLoading]);

    const load_components = (data) => {
        const indexComponents = return_row(data.results)
        setSelectedIndexComponents([...selectedIndexComponents, ...indexComponents])

        setComponentLoadingStat({
            url: data.next,
            length: data.count,
            see_more: data.next !== null
        })
        setLoadingSelectedComponentData(false)
    }

    useEffect(() => {
        setPaginatedTableData(selectedIndexComponents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => item))
    }, [selectedIndexComponents]);

    const save_ticker = (watchLists) => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/save-ticker-bulk/`, {
            company_id: selectedTicker.company_id,
            watchLists: watchLists
        }, config)
            .then(response => {
                setSelectedIndexComponents(selectedIndexComponents.map(component => {
                        if (component.name === selectedTicker.company_id) {
                            return {
                                ...component,
                                rowItems: [
                                    {
                                        ...component.rowItems[0],
                                        content: response.data['ticker_exists'] ?
                                            <AiFillStar className={'watchlist-star'}/> :
                                            <AiOutlineStar className={'watchlist-star'}/>
                                    },
                                    ...component.rowItems.slice(1, component.rowItems.length)
                                ]
                            }
                        }
                        return component
                    })
                )

                setPaginatedTableData(selectedIndexComponents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => item))

                const queries = queryClient.getQueryCache().getAll().filter(query => query.queryKey.includes(selectedIndex));

                queries.map((query) => {
                    const previousData = queryClient.getQueryData(query.queryKey);

                    if (previousData) {
                        queryClient.setQueryData(query.queryKey, {
                            ...previousData,
                            results: previousData.results.map((item) => {
                                if (item.company_id === selectedTicker.company_id) {
                                    item.watchlistticker_exists = response.data['ticker_exists']
                                }
                                return item
                            })
                        });
                    }
                })

                toast.success('Watchlist Updated.', {duration: 1000})
                handleCloseModal()
            })
            .catch(err => console.log(err.message))
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        if (componentLoadingStat.see_more) {
            setLoadingSelectedComponentData(true)
            void refetch()
        } else {
            setPaginatedTableData(selectedIndexComponents.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage).map(item => item))
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const rowsPerPage = +event.target.value
        setPaginatedTableData(selectedIndexComponents.slice(0, rowsPerPage).map(item => item))
        setRowsPerPage(rowsPerPage);
        setPage(0);
    };

    const return_row = (data) => {
        let indexComponents = []

        data.map((item) => {
            indexComponents.push({
                name: item.company_id,
                rowClass: '',
                rowItems: [
                    tableDataRow({
                        content: item.watchlistticker_exists ? <AiFillStar className={'watchlist-star'}/> :
                            <AiOutlineStar className={'watchlist-star'}/>,
                        onClick: () => {
                            handleOpenModal(item)
                        }, label: ""
                    }),

                    tableDataRow({
                        content: <>
                            <img src={item.LogoURL} className={'price-target-company-logo'}
                                 onError={({currentTarget}) => {
                                     currentTarget.onerror = null;
                                     currentTarget.src = building;
                                 }}/> {item.symbol}</>,
                        onClick: () => {
                            dispatch(load_common_stock(item.symbol, item.exchange_code))
                            navigate('/company-overview/' + item.symbol + '/' + item.exchange_code + '/')
                        }, label: "Ticker",
                    }),
                    tableDataRow({
                        content: item.name, label: "Company Name", hoverCount: 25, hoverText: true
                    }),
                    tableDataRow({
                        content: Utils.return_strong_num(item.last_price),
                        className: 'item-right-align-th', label: "Last Price"
                    }),
                    tableDataRow({
                        content: Utils.return_strong_num(item.per_change),
                        className: 'item-right-align-th td-per-change',
                        per: true, label: "Change %",
                        additional_attr: {changePer: true}
                    }),
                    tableDataRow({
                        content: item.m_cap_trading,
                        className: 'item-right-align-th',
                        valueInMillion: true,
                        valueInComma: true,
                        label: "MCap (mln)"
                    }),
                    tableDataRow({
                        content: !item.ranking_trend_score ? '-' : parseInt(return_strong_num(item.ranking_trend_score)),
                        className: 'item-right-align-th',
                        label: "TSI Rank"
                    }),
                    tableDataRow({
                        content: !item.trend_score ? '- %' : `${return_strong_num(item.trend_score * 100)} %`,
                        className: 'item-right-align-th',
                        label: "TSI Score"
                    }),
                ]
            })
        })

        return indexComponents

    }

    const [selectedTicker, setSelectedTicker] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = (item) => {
        setSelectedTicker(item)
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    return (
        <>
            <div>

                <LgSelectableTable
                    columns={columns}
                    setColumns={setColumns}
                    tableData={paginatedTableData}
                    setTableData={setPaginatedTableData}

                    inputField={false}

                    selectableField={false}

                    pagination={true}
                    page={page}
                    count={componentLoadingStat.length}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />

                {loadingSelectedComponentData && <div style={{display: 'flex', width: '100%', height: '100%'}}>
                    <div className="spinner-border text-warning" role="status" style={{margin: "auto"}}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                }
            </div>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SelectWatchListModal
                    selectedTicker={selectedTicker}
                    save_ticker={save_ticker}
                    handleCloseModal={handleCloseModal}
                />
            </Modal>

        </>
    );
}

export default TsiIndexTable;