import React, {useEffect, useState} from 'react';
import {getModalStyle, return_per_div, return_td_color} from "../Utils";
import clsx from "clsx";
import {CgClose} from "react-icons/cg";
import axios from "axios";
import Config from "../../Config";
import {toast} from "react-hot-toast";
import {IoIosHelpCircleOutline} from "react-icons/io";
import {Tooltip} from "@material-ui/core";

function CompanyNotesModal({name, priceData, currency, code, exchange, handleCloseModal, get_user_notes_price_alert}) {
    const [modalStyle] = useState(getModalStyle);

    const [low, setLow] = useState(null)
    const [high, setHigh] = useState(null)
    const [active, setActive] = useState(false)
    const [notes, setNotes] = useState("")

    const get_notes = (code, exchange) => {
        const config = Config();

        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-notes/${code}/${exchange}/`, config)
            .then(response => {
                const data = response.data
                setNotes(data?.notes)
                setHigh(data?.high)
                setLow(data?.low)
                setActive(data?.active)
            })
            .catch(err => console.log(err.message))
    }
    useEffect(() => {
        get_notes(code, exchange)
    }, []);

    const save_notes = (code, exchange) => {
        const config = Config();

        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/company-notes/${code}/${exchange}/`,
            {
                "notes": notes,
                "high": high,
                "low": low,
                "active": active
            }, config)
            .then(response => {
                try {
                    get_user_notes_price_alert()
                } catch (e) {

                }
                toast.success("Notes Saved", {duration: 1500})
                handleCloseModal()
            })
            .catch(err => console.log(err.message))
    }

    const handleHighLowChange = (e) => {
        if (e.target.name === 'high') {
            setHigh(e.target.value !== '' ? e.target.value : null)
        } else {
            setLow(e.target.value !== '' ? e.target.value : null)
        }
    }

    const handleNotesChange = (e) => {
        setNotes(e.target.value)
    }

    return (
        <div style={modalStyle}
             className={clsx('add-component-to-watchlist-modal')}>
            <div style={{display: 'flex'}}>
                <div style={{display: 'flex'}}>
                    <p className={'add-component-to-watchlist-heading'}
                       style={{margin: '0 5px 0 0'}}>{name} ({code})</p>
                    <p className={'adjusted-close'}
                       style={{margin: "auto 5px auto 0", fontSize: 12, fontWeight: 500}}>
                        {priceData?.price} {currency} &nbsp;
                        <span className={'adjusted-close-last-available'}
                              style={{color: return_td_color(priceData?.change)}}>
                                 {priceData?.change}
                                </span>
                    </p>
                    {return_per_div(priceData?.changesPercentage, 10, 50, "auto")}
                </div>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>

            <div style={{display: 'flex', margin: "20px 0"}}>
                <div style={{margin: 'auto auto auto 0'}}>
                    <p style={{fontSize: 12, fontWeight: 600}}>
                        Lower Than
                    </p>
                    <input style={{fontSize: 12}}
                           className={'form-control'}
                           value={low}
                           name={'low'}
                           type={"number"}
                           placeholder={'low'}
                           onChange={handleHighLowChange}
                    />
                </div>
                <div style={{margin: 'auto'}}>
                    <p style={{fontSize: 12, fontWeight: 600}}>
                        Higher Than
                    </p>
                    <input style={{fontSize: 12}}
                           className={'form-control'}
                           name={'high'}
                           value={high}
                           type={"number"}
                           placeholder={'high'}
                           onChange={handleHighLowChange}
                    />
                </div>

                <div className={'d-flex'}
                onChange={() => {
                    setActive(!active)
                }}>
                    <p style={{margin: "auto 5px auto 0", fontSize: 12}}>Active</p>
                    <input className="form-check-input filter-exchange-checkbox"
                                         type="checkbox" value="" id="flexCheckChecked" checked={active} />
                </div>
            </div>

            <textarea
                onChange={handleNotesChange}
                value={notes}
                style={{fontSize: 12, height: 150}}
                className={'form-control my-2 fg-color color-text'}
                placeholder={'Add Notes ...'}
            />

            <div style={{display: 'flex'}}>
                <button className={'btn btn-success'}
                        onClick={() => {
                            save_notes(code, exchange)
                        }}
                        style={{fontSize: 12, fontWeight: 600, marginLeft: 'auto'}}>Save
                </button>
            </div>
        </div>
    );
}

export default CompanyNotesModal;