import {return_per_div} from "../../Utils";
import React from "react";
import clsx from "clsx";

function FinancialsTable({groupedData, groupedDataSec, highlightHeadings = [], tableClassName = ""}) {

    const boldKeys = ['Total Revenue', 'Net Income', 'Free Cash Flow', 'Cash & Short Term Investments', "Total Current Assets", "Total Non-Current Assets", "Total Assets", "Total Current Liabilities", "Total Non Current Liabilities", "Total Liabilities", "Total Equity", "Total Liabilities & Equity", "Cash from Operation", "Cash from Operations", "Capital expenditures", "Cash from Investing", "Cash from Financing", ...highlightHeadings]

    return (
        <div className={"table-responsive generic-lg-table-container"}>
            <table className="table generic-lg-table company-overview-financial-table">
                <thead>
                <tr>
                    <th className={clsx('company-overview-financial-table-field-names', tableClassName)}>
                    </th>
                    {groupedData['dates'].map((date, index) => {
                        return (
                            <th key={index} className={clsx(date === "" && "financial-table-block-no-style")}>
                                {date}
                            </th>
                        )
                    })}
                </tr>
                </thead>

                {Object.entries(groupedData.content).map(([heading, index]) => (
                    <>
                        <thead>
                        <tr>
                            {groupedDataSec ?
                                <>
                                    <th style={{fontWeight: 700}}
                                        className={groupedData.content[heading]['name'].length > 25 && "hovertext"}
                                        data-hover={groupedData.content[heading]['name']}>
                                        {groupedData.content[heading]['name'].length > 25 ? groupedData.content[heading]['name'].substring(0, 25) + "..." : groupedData.content[heading]['name']}
                                    </th>
                                    {groupedData['dates'].map((date, index) => {
                                        return (
                                            <th key={index} className={clsx(date === "" && "financial-table-block-no-style")}>

                                            </th>
                                        )
                                    })}
                                </>
                                :
                                <th colSpan={groupedData['dates'].length + 1}
                                    style={{fontWeight: 700}}
                                    className={groupedData.content[heading]['name'].length > 25 && "hovertext"}
                                    data-hover={groupedData.content[heading]['name']}>
                                    {groupedData.content[heading]['name'].length > 25 ? groupedData.content[heading]['name'].substring(0, 25) + "..." : groupedData.content[heading]['name']}
                                </th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {Object.entries(groupedData.content[heading]['data']).map(([key, value]) => (
                            <tr>
                                <td className={key.length > 25 && "hovertext"} data-hover={key}
                                    style={{
                                        fontWeight: boldKeys.includes(key) ? 700 : 400,
                                        marginLeft: (key.includes("%") && key !== "Effective Tax Rate %") ? 20 : 10
                                    }}>{key.length > 25 ? key.substring(0, 25) + "..." : key}
                                </td>
                                {groupedData.content[heading]['data'][key].map((item) => {
                                    return (
                                        key.includes("%") ?
                                            <td>
                                                {return_per_div(item, 11, 60, "0 auto 0 0")}
                                            </td>
                                            :
                                            <td style={{fontWeight: boldKeys.includes(key) ? 700 : 400}}>
                                                {item}{ key === 'ROIC' && "%"}
                                            </td>
                                    )
                                })}
                                {groupedDataSec &&
                                    <>
                                        <td className={'financial-table-block-no-style'}>

                                        </td>
                                        {groupedDataSec[heading]['data'][key].map((item) => {
                                            return (
                                                key.includes("%") ?
                                                    <td>
                                                        {return_per_div(item, 11, 60, "0 auto 0 0")}
                                                    </td>
                                                    :
                                                    <td style={{fontWeight: boldKeys.includes(key) ? 700 : 400}}>
                                                        {item}
                                                    </td>
                                            )
                                        })}
                                    </>
                                }
                            </tr>
                        ))}
                        </tbody>
                    </>
                ))}
            </table>
        </div>
    );
}

export default FinancialsTable;