import React, {useState} from 'react';
import {AiOutlineClose} from "react-icons/ai";
import NavbarSearchTabBody from "../Navbar/NavbarSearchTabBody";
import {getModalStyle} from "../Utils";
import axios from "axios";
import {toast} from "react-hot-toast";

function CompareStocksSearch({
                                 compareData,
                                 setCompareData,
                                 handleCloseSearchModal,
                                 search_type = 'general'
                             }) {
    const [modalStyle] = useState(getModalStyle);

    const stock_onclick = (stock_type, code, exchange) => {
        if (search_type === 'compare_stocks') {
            axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-compare-stock-detail/${code}/${exchange}/`)
                .then(response => {
                    setCompareData((prev) => [...prev, {index: compareData.length, ...response.data}])
                })
                .catch(err => console.log(err.message))
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-isqore-detail/${code}/${exchange}/`)
                .then(response => {
                    setCompareData((prev) => [...prev, {index: compareData.length, ...response.data}])
                })
                .catch(err => console.log(err.message))
        }

        handleCloseSearchModal()
    }

    return (
        <div style={modalStyle} className={'generic-modal-body search-modal-body'}>
            <div style={{display: 'flex'}}>
                <p className={'search-modal-text-heading'}>Finqube Advance Company Search Engine</p>
                <AiOutlineClose onClick={handleCloseSearchModal}
                                className={'search-close-modal-button'}/>
            </div>

            <NavbarSearchTabBody stock_type={'Common Stock'}
                                 stock_onclick={stock_onclick}/>
        </div>
    );
}

export default CompareStocksSearch;