import React, {useState} from 'react';
import {return_strong_num} from "../Utils";
import {AiOutlineInfoCircle} from "react-icons/ai";

function return_color(value, currentPrice) {
    if (value === null) {
        return "white";
    } else if (return_strong_num(value) > currentPrice) {
        return '#4ebe96'
    } else if (return_strong_num(value) < currentPrice) {
        return '#d84f68'
    } else {
        return 'white'
    }
}


function FairValueInputTable({
                                 editableFields,
                                 yearsData,
                                 lowFairValueCalculation,
                                 midFairValueCalculation,
                                 highFairValueCalculation,
                                 changeInputData,
                                 currentPrice
                             }) {

    const [playVideo, setPlayVideo] = useState(false);

    const handlePlayVideoClick = () => {
        setPlayVideo(true)
    }

    return (
        <>
            <div style={{padding: "0", marginBottom: 5}}>
                <div className={'company-overview-table-body-container company-overview-bottom-table-body-container'}
                     style={{padding: "9px 10px"}}>
                    <table
                        className={'table generic-table-body company-overview-sm-table-body fair-value-sm-table-body'}
                        style={{margin: 0}}>
                        <thead>
                        <tr>
                            <th></th>
                            <th style={{width: 160}}>My Assumptions</th>
                            <th style={{textAlign: "center"}}>1 Year</th>
                            <th style={{textAlign: "center"}}>3 Years</th>
                            <th style={{textAlign: "center"}}>5 Years</th>
                            <th style={{width: 75, textAlign: "center"}}>Low</th>
                            <th style={{width: 75, textAlign: "center"}}>Mid</th>
                            <th style={{width: 75, textAlign: "center"}}>High</th>
                        </tr>
                        </thead>
                        <tbody>

                        {Object.keys(editableFields).map((key, index) => (
                            key !== 'code' &&
                            <tr key={index}
                                style={{border: Object.keys(editableFields).length === index + 1 ? "None" : ""}}>
                                <td className={'hovertext'} data-hover={editableFields[key]['info']}
                                    style={{verticalAlign: 'middle'}}>
                                    <AiOutlineInfoCircle style={{
                                        margin: "auto 0 auto auto",
                                        fontSize: 17,
                                        textAlign: "right"
                                    }}/>
                                </td>
                                <td style={{verticalAlign: 'middle'}}
                                    className={'sm-body-table-row-key'}>
                                    {editableFields[key]['text']}
                                </td>

                                <td style={{verticalAlign: "middle", textAlign: 'center'}}>
                                    {yearsData[key]['year1'] && return_strong_num(yearsData[key]['year1'] * (editableFields[key]['text'].includes('%') ? 100 : 1))} {(editableFields[key]['text'].includes('%') && yearsData[key]['year1']) && "%"}
                                </td>

                                <td style={{verticalAlign: "middle", textAlign: 'center'}}>
                                    {yearsData[key]['year3'] && return_strong_num(yearsData[key]['year3'] * (editableFields[key]['text'].includes('%') ? 100 : 1))} {(editableFields[key]['text'].includes('%') && yearsData[key]['year3']) && "%"}
                                </td>

                                <td style={{verticalAlign: "middle", textAlign: 'center'}}>
                                    {yearsData[key]['year5'] && return_strong_num(yearsData[key]['year5'] * (editableFields[key]['text'].includes('%') ? 100 : 1))} {(editableFields[key]['text'].includes('%') && yearsData[key]['year5']) && "%"}
                                </td>

                                {Object.keys(editableFields[key].fields).map((k, index) => {
                                    return (
                                        <td style={{verticalAlign: "middle"}}>
                                            <div style={{display: 'flex', width: "100%", height: "100%"}}>
                                                <input
                                                    onChange={(e) => changeInputData(e, key, k)}
                                                    type={'number'} value={editableFields[key].fields[k].value}
                                                    className={'form-control sm-body-editable-field'}
                                                    style={{margin: "auto"}}/>
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>


            <div className="row g-0 mb-2">
                <div className={'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12'}>
                    <div style={{padding: "0 5px 0 0"}}>
                        {!playVideo ?
                            <img onClick={handlePlayVideoClick}
                                src={"https://finqube-static-bucket.s3.eu-central-1.amazonaws.com/Fair+Value+Image.jpeg"}
                                style={{borderRadius: 10}}
                                width={"100%"}
                                height={133}
                            />
                            :
                            <iframe
                                width={"100%"}
                                height={133}
                                src="https://www.youtube.com/embed/Bh2B0vQKpQA?autoplay=1"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                showInfo={'0'}
                                autoFocus={'1'}
                                rel={'0'}
                                title="Finqube ISqore Fair Value"
                                style={{borderRadius: 10}}
                            ></iframe>

                            // <iframe width="1440" height="613" src="https://www.youtube.com/embed/Bh2B0vQKpQA" title="Fair Value Tutorial 💡| Finqube.io" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        }
                    </div>
                </div>

                <div className={'col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12'}>
                    <div style={{padding: "0"}}>
                        <div
                            className={'company-overview-table-body-container company-overview-bottom-table-body-container'}
                            style={{padding: "9px 10px"}}>
                            <table
                                className={'table generic-table-body company-overview-sm-table-body fair-value-sm-table-body'}
                                style={{margin: 0}}>
                                <thead>
                                <tr>
                                    <th style={{width: 200}}>Valuation</th>
                                    <th style={{width: 75, textAlign: "center"}}>Low</th>
                                    <th style={{width: 75, textAlign: "center"}}>Mid</th>
                                    <th style={{width: 75, textAlign: "center"}}>High</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"sm-body-table-row-key"}>
                                        Earnings Valuation
                                    </td>
                                    <td style={{
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        color: return_color(lowFairValueCalculation.earnings_valuation.intrinsic_value, currentPrice)
                                    }}>
                                        {return_strong_num(lowFairValueCalculation.earnings_valuation.intrinsic_value)}
                                    </td>
                                    <td style={{
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        color: return_color(midFairValueCalculation.earnings_valuation.intrinsic_value, currentPrice)
                                    }}>
                                        {return_strong_num(midFairValueCalculation.earnings_valuation.intrinsic_value)}
                                    </td>
                                    <td style={{
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        color: return_color(highFairValueCalculation.earnings_valuation.intrinsic_value, currentPrice)
                                    }}>
                                        {return_strong_num(highFairValueCalculation.earnings_valuation.intrinsic_value)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"sm-body-table-row-key"}>
                                        DCF Valuation
                                    </td>
                                    <td style={{
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        color: return_color(lowFairValueCalculation.dcf_valuation.intrinsic_value, currentPrice)
                                    }}>
                                        {return_strong_num(lowFairValueCalculation.dcf_valuation.intrinsic_value)}
                                    </td>
                                    <td style={{
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        color: return_color(midFairValueCalculation.dcf_valuation.intrinsic_value, currentPrice)
                                    }}>
                                        {return_strong_num(midFairValueCalculation.dcf_valuation.intrinsic_value)}
                                    </td>
                                    <td style={{
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        color: return_color(highFairValueCalculation.dcf_valuation.intrinsic_value, currentPrice)
                                    }}>
                                        {return_strong_num(highFairValueCalculation.dcf_valuation.intrinsic_value)}
                                    </td>
                                </tr>
                                <tr style={{border: "None"}}>
                                    <td className={"sm-body-table-row-key"}>
                                        Potential Return p.a. %
                                    </td>
                                    <td style={{
                                        textAlign: 'center',
                                        fontWeight: 600,
                                    }}>
                                        {return_strong_num(lowFairValueCalculation.potential_return * 100)} %
                                    </td>
                                    <td style={{
                                        textAlign: 'center',
                                        fontWeight: 600,
                                    }}>
                                        {return_strong_num(midFairValueCalculation.potential_return * 100)} %
                                    </td>
                                    <td style={{
                                        textAlign: 'center',
                                        fontWeight: 600
                                    }}>
                                        {return_strong_num(highFairValueCalculation.potential_return * 100)} %
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
        ;
}

export default FairValueInputTable;