import React, {useEffect, useState} from 'react';
import iSqoreMatrixDark from "../../assets/blockers/iSqoreMatrixDark.png";
import iSqoreMatrixLight from "../../assets/blockers/iSqoreMatrixLight.png";
import CompanyOverviewBottomTable from "../CompanyTabs/CompanyTables/CompanyOverviewBottomTable";
import {formatNumericValue, return_strong_num} from "../Utils";
import axios from "axios";
import CompanyOverviewFinancialsOverviewChart1
    from "../CompanyTabs/FinancialTabs/FinancialOverview/CompanyOverviewFinancialsOverviewChart1";
import GrossMarginOperatingNetChart
    from "../CompanyTabs/FinancialTabs/FinancialOverview/FinancialCharts/GrossMarginOperatingNetChart";
import QuickCheckTable from "./QuickCheckTable";
import Config from "../../Config";
import RestrictedContent from "../RestrictedContent";
import CompanyOverviewLatestNews from "../CompanyTabs/CompanyTables/CompanyOverviewLatestNews";

function ISqoreMatrixTab({logoURL, code, exchange}) {
    const [bottomTableData, setBottomTableData] = useState(null)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-bottom-table/${code}/${exchange}/`, Config())
            .then(response => {
                    setBottomTableData(response.data)
                }
            )
            .catch(error => console.log(error))
    }, [code, exchange])

    const [quickCheck, setQuickCheck] = useState(null)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/isqore-matrix-quick-check/${code}/${exchange}/`)
            .then(response => {
                    setQuickCheck(response.data)
                }
            )
            .catch(error => console.log(error))
    }, [code, exchange])

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use  this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={iSqoreMatrixDark}
            lightBlockerImg={iSqoreMatrixLight}
            code={code}
            exchange={exchange}

            content={
                <>
                    {(quickCheck && bottomTableData) &&
                        <div className="row g-0"
                             style={{position: 'relative', width: '100%', height: '100%'}}
                        >
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-8 col-xs-12">
                                <CompanyOverviewBottomTable
                                    title={"Quality"}
                                    grade={bottomTableData?.iSqore?.qualityGrade}
                                    data={[
                                        {
                                            name: 'Revenue Growth (Fwd)',
                                            value: formatNumericValue({value: bottomTableData?.earnings?.revenueEstimateGrowth, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.totalRevenueGrowthEstimateGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Revenue Growth TTM',
                                            value: formatNumericValue({value: bottomTableData?.growth_ttm?.totalRevenue, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.revenueGrowthTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Earnings Growth TTM',
                                            value: formatNumericValue({value: bottomTableData?.growth_ttm?.netIncome_income_statement, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.earningsGrowthTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Return on Invested Capital',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.returnOnInvestedCapital, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.returnOnInvestedCapitalTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Gross Margin',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.grossMargin, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.grossMarginTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Net Margin',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.netMargin, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.netMarginTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'FCF Margin',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.fcfMargin, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.fcfMarginTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Cash Ratio',
                                            value: formatNumericValue({value: bottomTableData?.quarterly?.cashRatio}),
                                            grade: bottomTableData?.iSqore?.cashRatioGrade,
                                            per: false
                                        },
                                        {
                                            name: 'FCF/Debt',
                                            value: formatNumericValue({value: bottomTableData?.quarterly?.fcfDebt}),
                                            grade: bottomTableData?.iSqore?.fcfDebtGrade,
                                            per: false
                                        },
                                        // {
                                        //     name: 'NetDebt/Asset',
                                        //     value: formatNumericValue({value: bottomTableData?.quarterly?.netDebtAsset, multiply: 100}),
                                        //     grade: bottomTableData?.iSqore?.netDebtAssetGrade,
                                        //     per: true
                                        // },
                                        // {
                                        //     name: 'NetDebt/EBIT',
                                        //     value: formatNumericValue({value: bottomTableData?.ttm?.netDebtEBIT, multiply: 100}),
                                        //     grade: bottomTableData?.iSqore?.netDebtEbitTTMGrade,
                                        //     per: true
                                        // },
                                    ]}
                                />
                            </div>
                            <div className="col-xl-5 col-lg-9 col-md-9 col-sm-12 col-xs-12">
                                <CompanyOverviewLatestNews logoURL={logoURL} code={code} exchange={exchange} isqore={true}/>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <GrossMarginOperatingNetChart code={code} exchange={exchange}
                                                              type={'annual'}
                                                              iSqoreClass={'isqore-matrix-chart-inner-container'}
                                                              colors={["#3e9fed", "#29f3a7", "#ee1981"]}/>
                            </div>
                        </div>
                    }

                    {(bottomTableData) &&
                        <div className={"row g-0 company-overview-bottom-table"}
                             style={{position: 'relative', width: '100%', height: '100%'}}
                        >
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <CompanyOverviewBottomTable
                                    title={"Value"}
                                    grade={bottomTableData?.iSqore?.valueGrade}
                                    data={[
                                        {
                                            name: 'P/Earnings (Fwd)',
                                            value: formatNumericValue({value: bottomTableData?.valuation?.ForwardPE}),
                                            grade: bottomTableData?.iSqore?.priceEarningsForwardGrade
                                        },
                                        {
                                            name: 'P/Earnings',
                                            value: formatNumericValue({value: bottomTableData?.current?.priceEarnings}),
                                            grade: bottomTableData?.iSqore?.priceEarningsCurrentGrade
                                        },
                                        {
                                            name: 'P/FCF',
                                            value: formatNumericValue({value: bottomTableData?.current?.priceFreeCashFlow}),
                                            grade: bottomTableData?.iSqore?.priceFreeCashFlowCurrentGrade
                                        },
                                        {
                                            name: 'P/Sales',
                                            value: formatNumericValue({value: bottomTableData?.current?.priceSales}),
                                            grade: bottomTableData?.iSqore?.priceSalesCurrentGrade
                                        },
                                        // {
                                        //     name: 'PEG Ratio',
                                        //     value: formatNumericValue({value: bottomTableData?.current?.pegRatio}),
                                        //     grade: bottomTableData?.iSqore?.pegRatioCurrentGrade
                                        // },
                                        {
                                            name: 'EV/Revenue',
                                            value: formatNumericValue({value: bottomTableData?.current?.evRevenue}),
                                            grade: bottomTableData?.iSqore?.evRevenueCurrentGrade
                                        },
                                        {
                                            name: 'EV/EBIT',
                                            value: formatNumericValue({value: bottomTableData?.current?.eveBit}),
                                            grade: bottomTableData?.iSqore?.eveBitCurrentGrade
                                        },
                                        {
                                            name: 'EV/EBITDA',
                                            value: formatNumericValue({value: bottomTableData?.current?.eveBitda}),
                                            grade: bottomTableData?.iSqore?.eveBitdaCurrentGrade
                                        },
                                        {
                                            name: 'Earnings Yield',
                                            value: formatNumericValue({value: bottomTableData?.current?.earningsYield, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.earningsYieldCurrentGrade,
                                            per: true
                                        },
                                        {
                                            name: 'FCF Yield',
                                            value: formatNumericValue({value: bottomTableData?.current?.fcfYield, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.fcfYieldTTMGrade,
                                            per: true
                                        },
                                    ]}
                                />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <CompanyOverviewBottomTable
                                    title={"Profitability"}
                                    grade={bottomTableData?.iSqore?.profitabilityGrade}
                                    data={[
                                        // {
                                        //     name: 'Return on Assets',
                                        //     value: formatNumericValue({value: bottomTableData?.ttm?.returnOnAssets, multiply: 100}),
                                        //     grade: bottomTableData?.iSqore?.returnOnAssetsTTMGrade,
                                        //     per: true
                                        // },
                                        {
                                            name: 'Return on Equity',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.returnOnEquity, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.returnOnEquityTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Return on Cap Empl.',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.returnOnCapitalEmployed, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.returnOnCapitalEmployedTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Return on Invested Capital',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.returnOnInvestedCapital, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.returnOnInvestedCapitalTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Gross Margin',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.grossMargin, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.grossMarginTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Operating Margin',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.operatingMargin, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.operatingMarginTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Net Margin',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.netMargin, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.netMarginTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'EBIT Margin',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.ebitMargin, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.ebitMarginTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'EBITDA Margin',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.ebitdaMargin, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.ebitdaMarginTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'FCF Margin',
                                            value: formatNumericValue({value: bottomTableData?.ttm?.fcfMargin, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.fcfMarginTTMGrade,
                                            per: true
                                        },
                                    ]}
                                />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <CompanyOverviewBottomTable
                                    title={"Growth"}
                                    grade={bottomTableData?.iSqore?.growthGrade}
                                    data={[
                                        {
                                            name: 'Revenue Growth TTM',
                                            value: formatNumericValue({value: bottomTableData?.growth_ttm?.totalRevenue, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.revenueGrowthTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Revenue Growth YoY',
                                            value: formatNumericValue({value: bottomTableData?.growth_yoy?.totalRevenue, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.revenueGrowthYOYGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Earnings Growth TTM',
                                            value: formatNumericValue({value: bottomTableData?.growth_ttm?.netIncome_income_statement, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.earningsGrowthTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'Earnings Growth YoY',
                                            value: formatNumericValue({value: bottomTableData?.growth_yoy?.netIncome_income_statement, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.earningsGrowthYOYGrade,
                                            per: true
                                        },
                                        {
                                            name: 'EPS Growth TTM',
                                            value: formatNumericValue({value: bottomTableData?.growth_ttm?.earningsPerShare, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.earningsPerShareGrowthTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'EPS Growth YoY',
                                            value: formatNumericValue({value: bottomTableData?.growth_yoy?.earningsPerShare, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.earningsPerShareGrowthYOYGrade,
                                            per: true
                                        },
                                        {
                                            name: 'EBIT Growth',
                                            value: formatNumericValue({value: bottomTableData?.growth_ttm?.ebit, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.ebitGrowthTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'EBITDA Growth',
                                            value: formatNumericValue({value: bottomTableData?.growth_ttm?.ebitda, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.ebitdaGrowthTTMGrade,
                                            per: true
                                        },
                                        {
                                            name: 'FCF Growth',
                                            value: formatNumericValue({value: bottomTableData?.growth_ttm?.freeCashFlow, multiply: 100}),
                                            grade: bottomTableData?.iSqore?.freeCashFlowGrowthTTMGrade,
                                            per: true
                                        },
                                    ]}
                                />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <CompanyOverviewBottomTable
                                    title={"Health"}
                                    grade={bottomTableData?.iSqore?.healthGrade}
                                    data={[
                                        {
                                            name: 'Cash Ratio',
                                            value: formatNumericValue({value: bottomTableData?.quarterly?.cashRatio}),
                                            grade: bottomTableData?.iSqore?.cashRatioGrade
                                        },
                                        {
                                            name: 'Current Ratio',
                                            value: formatNumericValue({value: bottomTableData?.quarterly?.currentRatio}),
                                            grade: bottomTableData?.iSqore?.currentRatioGrade
                                        },
                                        {
                                            name: 'Quick Ratio',
                                            value: formatNumericValue({value: bottomTableData?.quarterly?.quickRatio}),
                                            grade: bottomTableData?.iSqore?.quickRatioGrade
                                        },
                                        {
                                            name: 'Equity/Assets',
                                            value: formatNumericValue({value: bottomTableData?.quarterly?.equityAssets}),
                                            grade: bottomTableData?.iSqore?.equityAssetsGrade
                                        },
                                        {
                                            name: 'Debt/Equity',
                                            value: formatNumericValue({value: bottomTableData?.quarterly?.debtEquity}),
                                            grade: bottomTableData?.iSqore?.debtEquityGrade
                                        },
                                        {
                                            name: 'Debt/Assets',
                                            value: formatNumericValue({value: bottomTableData?.quarterly?.debtAsset}),
                                            grade: bottomTableData?.iSqore?.debtAssetGrade
                                        },
                                        {
                                            name: 'FCF/Debt',
                                            value: formatNumericValue({value: bottomTableData?.quarterly?.fcfDebt}),
                                            grade: bottomTableData?.iSqore?.fcfDebtGrade
                                        },
                                        {
                                            name: 'Piotroski F-Score',
                                            value: formatNumericValue({value: bottomTableData?.current?.piotroskiFScore}),
                                            grade: bottomTableData?.iSqore?.piotroskiFScoreGrade
                                        },
                                        // {
                                        //     name: 'Debt/EBIT',
                                        //     value: formatNumericValue({value: bottomTableData?.ttm?.debtEBIT}),
                                        //     grade: bottomTableData?.iSqore?.debtEbitTTMGrade
                                        // },
                                        // {
                                        //     name: 'Debt/EBITDA',
                                        //     value: formatNumericValue({value: bottomTableData?.ttm?.debtEBITDA}),
                                        //     grade: bottomTableData?.iSqore?.debtEbitdaTTMGrade
                                        // },
                                        {
                                            name: 'Altman Z-Score',
                                            value: formatNumericValue({value: bottomTableData?.current?.altmanZScore}),
                                            grade: bottomTableData?.iSqore?.altmanZScoreCurrentGrade
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    }
                </>
            }
        />
    );
}

export default ISqoreMatrixTab;