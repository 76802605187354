import React, {useEffect, useState} from "react";
import {numberWithCommas, return_num, return_strong_num, return_value_in_million} from "../../Utils";
import FinancialsControls from "./FinancialsControls";
import FinancialsTable from "./FinancialsTable";
import axios from "axios";

const CashFlowTab = ({code, exchange}) => {
    const [active, setActive] = useState('annual')
    const [loading, setLoading] = useState(true)

    const [groupedData, setGroupedData] = useState(null)

    const [meta, setMeta] = useState({curr_symbol: "", currency: ""})

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financial-meta/${code}/${exchange}/`)
            .then(response => {
                setMeta(response.data)
            })
    }, [code, exchange])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financials-cashflow/${code}/${exchange}/${active}/`)
            .then(response => {
                const data = response.data
                group_cash(data)
                setLoading(false)
            })
            .catch(error => console.log(error.message))
    }, [active, code, exchange])

    const group_cash = (_data) => {
        let data = {
            dates: [],
            content: {
                net_income: {
                    name: 'Net Income',
                    data: {
                        'Net Income': [],
                        ...(active === 'annual' ? {'% Growth Yearly': []} : active === 'quarter' ? {'% Growth Quarterly': []} : {'% Growth TTM': []}),
                    }
                },
                operating_activities: {
                    name: "Operating Activities",
                    data: {
                        'Depreciation & Amortization': [],
                        ...(active === 'annual' ? {'% Growth Yearly': []} : active === 'quarter' ? {'% Growth Quarterly': []} : {'% Growth TTM': []}),
                        'Change in Net Income': [],
                        'Change in inventories': [],
                        'Change In Accounts Receivable': [],
                        'Change in Liabilities': [],
                        'Other Operating Activities': [],
                        'Cash from Operations': [],
                        ...(active === 'annual' ? {'% Growth Yearly ': []} : active === 'quarter' ? {'% Growth Quarterly ': []} : {'% Growth TTM ': []}),
                    }
                },
                investing_activities: {
                    name: "Investing Activities",
                    data: {
                        'Investments': [],
                        'Capital expenditures': [],
                        ...(active === 'annual' ? {'% Growth Yearly ': []} : active === 'quarter' ? {'% Growth Quarterly ': []} : {'% Growth TTM ': []}),
                        'Other Investing Activities': [],
                        'Cash from Investing': [],
                        ...(active === 'annual' ? {'% Growth Yearly  ': []} : active === 'quarter' ? {'% Growth Quarterly  ': []} : {'% Growth TTM  ': []}),
                    }
                },
                financing_activities: {
                    name: "Financing Activities",
                    data: {
                        'Dividends Paid': [],
                        'Repurchase of Stock': [],
                        'Net Borrowings': [],
                        'Other Financing Activities': [],
                        'Cash from Financing': [],
                        ...(active === 'annual' ? {'% Growth Yearly   ': []} : active === 'quarter' ? {'% Growth Quarterly   ': []} : {'% Growth TTM   ': []}),
                        'Net Change in Cash': []
                    }
                },
                free_cash_flow: {
                    name: "Free Cash Flow",
                    data: {
                        'Free Cash Flow': [],
                        ...(active === 'annual' ? {'% Growth Yearly    ': []} : active === 'quarter' ? {'% Growth Quarterly    ': []} : {'% Growth TTM    ': []}),
                        'FCF Margin %': [],
                        'FCF per Share': []
                    }
                },
                // stock_based_compensation: {
                //     name: "Stock Based Compensation",
                //     data: {
                //         'Stock Based Compensation': [],
                //         ...(active === 'annual' ? {'% Growth Yearly': []} : active === 'quarter' ? {'% Growth Quarterly': []} : {'% Growth TTM': []}),
                //     }
                // }
            }
        }

        if (active === 'annual' || active === 'quarter') {
            _data.map((item) => {
                data['dates'].push(item.date)

                data['content']['net_income']['data']['Net Income'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['netIncome']))))
                if (active === 'annual') {
                    data['content']['net_income']['data']['% Growth Yearly'].push(return_strong_num(item['growth']['historical_data']['netIncome_income_statement'] * 100))
                } else {
                    data['content']['net_income']['data']['% Growth Quarterly'].push(return_strong_num(item['growth']['historical_data']['netIncome_income_statement'] * 100))
                }

                data['content']['operating_activities']['data']['Depreciation & Amortization'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['depreciation']))))
                if (active === 'annual') {
                    data['content']['operating_activities']['data']['% Growth Yearly'].push(return_strong_num(item['growth']['historical_data']['depreciation'] * 100))
                } else {
                    data['content']['operating_activities']['data']['% Growth Quarterly'].push(return_strong_num(item['growth']['historical_data']['depreciation'] * 100))
                }
                data['content']['operating_activities']['data']['Change in Net Income'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['changeToNetincome']))))
                data['content']['operating_activities']['data']['Change in inventories'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['changeToInventory']))))
                data['content']['operating_activities']['data']['Change In Accounts Receivable'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['changeToAccountReceivables']))))
                data['content']['operating_activities']['data']['Change in Liabilities'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['changeToLiabilities']))))
                data['content']['operating_activities']['data']['Other Operating Activities'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['changeToOperatingActivities']))))
                data['content']['operating_activities']['data']['Cash from Operations'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['totalCashFromOperatingActivities']))))
                if (active === 'annual') {
                    data['content']['operating_activities']['data']['% Growth Yearly '].push(return_strong_num(item['growth']['historical_data']['totalCashFromOperatingActivities'] * 100))
                } else {
                    data['content']['operating_activities']['data']['% Growth Quarterly '].push(return_strong_num(item['growth']['historical_data']['totalCashFromOperatingActivities'] * 100))
                }

                // investing activities
                data['content']['investing_activities']['data']['Investments'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['investments']))))
                data['content']['investing_activities']['data']['Capital expenditures'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['capitalExpenditures']))))
                if (active === 'annual') {
                    data['content']['investing_activities']['data']['% Growth Yearly '].push(return_strong_num(item['growth']['historical_data']['capitalExpenditures'] * 100))
                } else {
                    data['content']['investing_activities']['data']['% Growth Quarterly '].push(return_strong_num(item['growth']['historical_data']['capitalExpenditures'] * 100))
                }
                data['content']['investing_activities']['data']['Other Investing Activities'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['otherCashflowsFromInvestingActivities']))))
                data['content']['investing_activities']['data']['Cash from Investing'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['totalCashflowsFromInvestingActivities']))))
                if (active === 'annual') {
                    data['content']['investing_activities']['data']['% Growth Yearly  '].push(return_strong_num(item['growth']['historical_data']['totalCashflowsFromInvestingActivities'] * 100))
                } else {
                    data['content']['investing_activities']['data']['% Growth Quarterly  '].push(return_strong_num(item['growth']['historical_data']['totalCashflowsFromInvestingActivities'] * 100))
                }

                // financing_activities
                data['content']['financing_activities']['data']['Dividends Paid'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['dividendsPaid']))))
                data['content']['financing_activities']['data']['Repurchase of Stock'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['salePurchaseOfStock']))))
                data['content']['financing_activities']['data']['Net Borrowings'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['netBorrowings']))))
                data['content']['financing_activities']['data']['Other Financing Activities'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['otherCashflowsFromFinancingActivities']))))
                data['content']['financing_activities']['data']['Cash from Financing'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['totalCashFromFinancingActivities']))))
                if (active === 'annual') {
                    data['content']['financing_activities']['data']['% Growth Yearly   '].push(return_strong_num(item['growth']['historical_data']['totalCashFromFinancingActivities'] * 100))
                } else {
                    data['content']['financing_activities']['data']['% Growth Quarterly   '].push(return_strong_num(item['growth']['historical_data']['totalCashFromFinancingActivities'] * 100))
                }
                data['content']['financing_activities']['data']['Net Change in Cash'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['changeInCash']))))

                // free_cash_flow
                data['content']['free_cash_flow']['data']['Free Cash Flow'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['freeCashFlow']))))
                if (active === 'annual') {
                    data['content']['free_cash_flow']['data']['% Growth Yearly    '].push(return_strong_num(item['growth']['historical_data']['freeCashFlow'] * 100))
                } else {
                    data['content']['free_cash_flow']['data']['% Growth Quarterly    '].push(return_strong_num(item['growth']['historical_data']['freeCashFlow'] * 100))
                }

                data['content']['free_cash_flow']['data']['FCF per Share'].push(return_strong_num(item['cash_flow']['freeCashFlowPerShare']))
                data['content']['free_cash_flow']['data']['FCF Margin %'].push(return_strong_num(item['additional']['fcfMargin'] * 100))

                // stock_based_compensation
                // data['content']['stock_based_compensation']['data']['Stock Based Compensation'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['stockBasedCompensation']))))
                // if (active === 'annual') {
                //     data['content']['stock_based_compensation']['data']['% Growth Yearly'].push(return_strong_num(item['growth']['historical_data']['stockBasedCompensation'] * 100))
                // } else {
                //     data['content']['stock_based_compensation']['data']['% Growth Quarterly'].push(return_strong_num(item['growth']['historical_data']['stockBasedCompensation'] * 100))
                // }

            })
        }
        else if (active === 'ttm') {
            _data.map((item) => {
                console.log("item =>", item)
                data['dates'].push(item.date)


                data['content']['net_income']['data']['Net Income'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['netIncome_income_statement']))))
                data['content']['net_income']['data']['% Growth TTM'].push(return_strong_num(item['growth']['historical_data']['netIncome_income_statement'] * 100))

                data['content']['operating_activities']['data']['Depreciation & Amortization'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['depreciation']))))
                data['content']['operating_activities']['data']['% Growth TTM'].push(return_strong_num(item['growth']['historical_data']['depreciation'] * 100))
                data['content']['operating_activities']['data']['Change in Net Income'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['changeToNetincome']))))
                data['content']['operating_activities']['data']['Change in inventories'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['changeToInventory']))))
                data['content']['operating_activities']['data']['Change In Accounts Receivable'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['changeToAccountReceivables']))))
                data['content']['operating_activities']['data']['Change in Liabilities'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['changeToLiabilities']))))
                data['content']['operating_activities']['data']['Other Operating Activities'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['changeToOperatingActivities']))))
                data['content']['operating_activities']['data']['Cash from Operations'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['totalCashFromOperatingActivities']))))
                data['content']['operating_activities']['data']['% Growth TTM '].push(return_strong_num(item['growth']['historical_data']['totalCashFromOperatingActivities'] * 100))
                // cash_from_operations

                // investing activities
                data['content']['investing_activities']['data']['Investments'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['investments']))))
                data['content']['investing_activities']['data']['Capital expenditures'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['capitalExpenditures']))))
                data['content']['investing_activities']['data']['% Growth TTM '].push(return_strong_num(item['growth']['historical_data']['capitalExpenditures'] * 100))
                data['content']['investing_activities']['data']['Other Investing Activities'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['otherCashflowsFromInvestingActivities']))))
                data['content']['investing_activities']['data']['Cash from Investing'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['totalCashflowsFromInvestingActivities']))))
                data['content']['investing_activities']['data']['% Growth TTM  '].push(return_strong_num(item['growth']['historical_data']['totalCashflowsFromInvestingActivities'] * 100))

                // financing_activities
                data['content']['financing_activities']['data']['Dividends Paid'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['dividendsPaid']))))
                data['content']['financing_activities']['data']['Repurchase of Stock'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['salePurchaseOfStock']))))
                data['content']['financing_activities']['data']['Net Borrowings'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['netBorrowings']))))
                data['content']['financing_activities']['data']['Other Financing Activities'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['otherCashflowsFromFinancingActivities']))))

                data['content']['financing_activities']['data']['Cash from Financing'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['totalCashFromFinancingActivities']))))
                data['content']['financing_activities']['data']['% Growth TTM   '].push(return_strong_num(item['growth']['historical_data']['totalCashFromFinancingActivities'] * 100))
                data['content']['financing_activities']['data']['Net Change in Cash'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['changeInCash']))))

                // Supplemental Items
                data['content']['free_cash_flow']['data']['Free Cash Flow'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['freeCashFlow']))))
                data['content']['free_cash_flow']['data']['% Growth TTM    '].push(return_strong_num(item['growth']['historical_data']['freeCashFlow'] * 100))
                data['content']['free_cash_flow']['data']['FCF per Share'].push(return_strong_num(item['ttm']['historical_data']['freeCashFlowPerShare']))
                data['content']['free_cash_flow']['data']['FCF Margin %'].push(return_strong_num(item['ttm']['historical_data']['fcfMargin'] * 100))
            })
        }

        setGroupedData(data)
    }

    const handleActive = (type) => {
        setActive(type)
        setLoading(true)
    }

    return (
        <>
            <FinancialsControls
                options={[{label: "Annual", key: "annual"}, {label: "Quarterly", key: 'quarter'}, {label: "TTM", key: 'ttm'}]}
                handleActive={handleActive} active={active} meta={meta}/>

            {
                loading ?
                    <div style={{width: '100%', height: '100%', display: ' flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    groupedData &&
                    <FinancialsTable groupedData={groupedData} highlightHeadings={["Depreciation & Amortization", ""]}/>
            }
        </>
    );
}

export default CashFlowTab;