import React, {useEffect, useState} from "react";
import axios from "axios";
import {formatNumericValue, return_grade_color, return_obj, return_string, return_strong_num} from "../Utils";
import FinancialBreakdownChart from "./FinancialTabs/FinancialOverview/FinancialCharts/FinancialBreakdownChart";
import iSqoreHealthDark from "../../assets/blockers/iSqoreHealthDark.png";
import iSqoreHealthLight from "../../assets/blockers/iSqoreHealthLight.png";
import RestrictedContent from "../RestrictedContent";
import ISqoreTable from "../ISqoreTabs/ISqoreTable";

const HealthTab = ({code, exchange}) => {
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState(null)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-value/${code}/${exchange}/`)
            .then(response => {
                format_data(response.data)
                setLoading(false)
            })
            .catch(error => console.log(error.message))
    }, [code, exchange])

    const format_data = (values) => {
        const data = {
            tableData: [
                return_obj(
                    "Cash/Debt (Quarterly)",
                    values['additional_cal_quarter']['cashTotalDebt'],
                    values['iSqore']['cashTotalDebtGrade'],

                    values['median_sector']['cashTotalDebt'],
                    values['calculation-3-year-avg']['historical_data']['cashTotalDebt'],
                    values['calculation-5-year-avg']['historical_data']['cashTotalDebt']
                ),
                return_obj(
                    "Cash/Debt (Year)",
                    values['additional_cal']['cashTotalDebt'],
                    values['iSqore']['cashTotalDebtYearGrade'],

                    values['median_sector']['cashTotalDebtYear'],
                    values['calculation-3-year-avg']['historical_data']['cashTotalDebt'],
                    values['calculation-5-year-avg']['historical_data']['cashTotalDebt']
                ),


                return_obj(
                    "Debt/Capital (Quarterly)",
                    values['additional_cal_quarter']['debtCapital'],
                    values['iSqore']['debtCapitalGrade'],

                    values['median_sector']['cashDebt'],
                    values['calculation-3-year-avg']['historical_data']['debtCapital'],
                    values['calculation-5-year-avg']['historical_data']['debtCapital']
                ),
                return_obj(
                    "Debt/Capital (Year)",
                    values['additional_cal']['debtCapital'],
                    values['iSqore']['debtCapitalYearGrade'],

                    values['median_sector']['cashDebtYear'],
                    values['calculation-3-year-avg']['historical_data']['debtCapital'],
                    values['calculation-5-year-avg']['historical_data']['debtCapital']
                ),

                return_obj(
                    "FCF/Debt (Quarterly)",
                    values['additional_cal_quarter']['fcfDebt'],
                    values['iSqore']['fcfDebtGrade'],

                    values['median_sector']['fcfDebt'],
                    values['calculation-3-year-avg']['historical_data']['fcfDebt'],
                    values['calculation-5-year-avg']['historical_data']['fcfDebt']
                ),
                return_obj(
                    "FCF/Debt (Year)",
                    values['additional_cal']['fcfDebt'],
                    values['iSqore']['fcfDebtYearGrade'],

                    values['median_sector']['fcfDebtYear'],
                    values['calculation-3-year-avg']['historical_data']['fcfDebt'],
                    values['calculation-5-year-avg']['historical_data']['fcfDebt']
                ),


                return_obj(
                    "Interest Coverage (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['interestCoverage'],
                    values['iSqore']['interestCoverageTTMGrade'],

                    values['median_sector']['interestCoverageTTMGrade'],
                    values['calculation-3-year-avg']['historical_data']['interestCoverage'],
                    values['calculation-5-year-avg']['historical_data']['interestCoverage']
                ),
                return_obj(
                    "Interest Coverage (Year)",
                    values['additional_cal']['interestCoverage'],
                    values['iSqore']['interestCoverageYearGrade'],

                    values['median_sector']['interestCoverageYear'],
                    values['calculation-3-year-avg']['historical_data']['interestCoverage'],
                    values['calculation-5-year-avg']['historical_data']['interestCoverage']
                ),


                return_obj(
                    "Cash/Ratio (Quarterly)",
                    values['additional_cal_quarter']['cashRatio'],
                    values['iSqore']['cashRatioGrade'],

                    values['median_sector']['cashRatio'],
                    values['calculation-3-year-avg']['historical_data']['cashRatio'],
                    values['calculation-5-year-avg']['historical_data']['cashRatio']
                ),
                return_obj(
                    "Cash/Ratio (Year)",
                    values['additional_cal']['cashRatio'],
                    values['iSqore']['cashRatioYearGrade'],

                    values['median_sector']['cashRatioYear'],
                    values['calculation-3-year-avg']['historical_data']['cashRatio'],
                    values['calculation-5-year-avg']['historical_data']['cashRatio']
                ),

                return_obj(
                    "Debt/Equity (Quarterly)",
                    return_strong_num(values['additional_cal_quarter']['debtEquity']),
                    values['iSqore']['debtEquityGrade'],

                    values['median_sector']['debtEquity'],
                    values['calculation-3-year-avg']['historical_data']['debtEquity'],
                    values['calculation-5-year-avg']['historical_data']['debtEquity']
                ),
                return_obj(
                    "Debt/Equity (Year)",
                    return_strong_num(values['additional_cal']['debtEquity']),
                    values['iSqore']['debtEquityYearGrade'],

                    values['median_sector']['debtEquityYear'],
                    values['calculation-3-year-avg']['historical_data']['debtEquity'],
                    values['calculation-5-year-avg']['historical_data']['debtEquity']
                ),

                return_obj(
                    "NetDebt/Equity (Quarterly)",
                    return_strong_num(values['additional_cal_quarter']['netDebtEquity']),
                    values['iSqore']['netDebtEquityGrade'],

                    values['median_sector']['netDebtEquity'],
                    values['calculation-3-year-avg']['historical_data']['netDebtEquity'],
                    values['calculation-5-year-avg']['historical_data']['netDebtEquity']
                ),
                return_obj(
                    "NetDebt/Equity (Year)",
                    return_strong_num(values['additional_cal']['netDebtEquity']),
                    values['iSqore']['netDebtEquityYearGrade'],

                    values['median_sector']['netDebtEquityYear'],
                    values['calculation-3-year-avg']['historical_data']['netDebtEquity'],
                    values['calculation-5-year-avg']['historical_data']['netDebtEquity']
                ),

                return_obj(
                    "Equity/Assets (Quarterly)",
                    return_strong_num(values['additional_cal_quarter']['equityAssets']),
                    values['iSqore']['equityAssetsGrade'],

                    values['median_sector']['equityAssets'],
                    values['calculation-3-year-avg']['historical_data']['equityAssets'],
                    values['calculation-5-year-avg']['historical_data']['equityAssets']
                ),
                return_obj(
                    "Equity/Assets (Year)",
                    return_strong_num(values['additional_cal']['equityAssets']),
                    values['iSqore']['equityAssetsYearGrade'],

                    values['median_sector']['equityAssetsYear'],
                    values['calculation-3-year-avg']['historical_data']['equityAssets'],
                    values['calculation-5-year-avg']['historical_data']['equityAssets']
                ),

                return_obj(
                    "Debt/Asset (Quarterly)",
                    return_strong_num(values['additional_cal_quarter']['debtAsset']),
                    values['iSqore']['debtAssetGrade'],

                    values['median_sector']['debtAsset'],
                    values['calculation-3-year-avg']['historical_data']['debtAsset'],
                    values['calculation-5-year-avg']['historical_data']['debtAsset']
                ),
                return_obj(
                    "Debt/Asset (Year)",
                    return_strong_num(values['additional_cal']['debtAsset']),
                    values['iSqore']['debtAssetYearGrade'],

                    values['median_sector']['debtAssetYear'],
                    values['calculation-3-year-avg']['historical_data']['debtAsset'],
                    values['calculation-5-year-avg']['historical_data']['debtAsset']
                ),

                return_obj(
                    "NetDebt/Asset (Quarterly)",
                    return_strong_num(values['additional_cal_quarter']['netDebtAsset']),
                    values['iSqore']['netDebtAssetGrade'],

                    values['median_sector']['netDebtAsset'],
                    values['calculation-3-year-avg']['historical_data']['netDebtAsset'],
                    values['calculation-5-year-avg']['historical_data']['netDebtAsset']
                ),
                return_obj(
                    "NetDebt/Asset (Year)",
                    return_strong_num(values['additional_cal']['netDebtAsset']),
                    values['iSqore']['netDebtAssetYearGrade'],

                    values['median_sector']['netDebtAssetYear'],
                    values['calculation-3-year-avg']['historical_data']['netDebtAsset'],
                    values['calculation-5-year-avg']['historical_data']['netDebtAsset']
                ),

                return_obj(
                    "Debt/EBIT (TTM)",
                    return_strong_num(values['calculation-quarterly-ttm']['historical_data']['debtEBIT']),
                    values['iSqore']['debtEbitTTMGrade'],

                    values['median_sector']['debtEbitTTM'],
                    values['calculation-3-year-avg']['historical_data']['debtEBIT'],
                    values['calculation-5-year-avg']['historical_data']['debtEBIT']
                ),
                return_obj(
                    "Debt/EBIT (Year)",
                    return_strong_num(values['additional_cal']['debtEBIT']),
                    values['iSqore']['debtEbitYearGrade'],

                    values['median_sector']['debtEbitYear'],
                    values['calculation-3-year-avg']['historical_data']['debtEBIT'],
                    values['calculation-5-year-avg']['historical_data']['debtEBIT']
                ),

                return_obj(
                    "NetDebt/EBIT (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['netDebtEBIT'],
                    values['iSqore']['netDebtEbitTTMGrade'],
                    values['median_sector']['netDebtEbitTTM'],
                    values['calculation-3-year-avg']['historical_data']['netDebtEBIT'],
                    values['calculation-5-year-avg']['historical_data']['netDebtEBIT']
                ),
                return_obj(
                    "NetDebt/EBIT (Year)",
                    values['additional_cal']['netDebtEBIT'],
                    values['iSqore']['netDebtEbitYearGrade'],
                    values['median_sector']['netDebtEbitYear'],
                    values['calculation-3-year-avg']['historical_data']['netDebtEBIT'],
                    values['calculation-5-year-avg']['historical_data']['netDebtEBIT']
                ),

                return_obj(
                    "Debt/EBITDA (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['debtEBITDA'],
                    values['iSqore']['debtEbitdaTTMGrade'],
                    values['median_sector']['debtEbitdaTTM'],
                    values['calculation-3-year-avg']['historical_data']['debtEBITDA'],
                    values['calculation-5-year-avg']['historical_data']['debtEBITDA']
                ),
                return_obj(
                    "Debt/EBITDA (Year)",
                    values['additional_cal']['debtEBITDA'],
                    values['iSqore']['debtEbitdaYearGrade'],
                    values['median_sector']['debtEbitdaYear'],
                    values['calculation-3-year-avg']['historical_data']['debtEBITDA'],
                    values['calculation-5-year-avg']['historical_data']['debtEBITDA']
                ),

                return_obj(
                    "NetDebt/EBITDA (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['netDebtEBITDA'],
                    values['iSqore']['netDebtEbitdaTTMGrade'],
                    values['median_sector']['netDebtEbitdaTTM'],
                    values['calculation-3-year-avg']['historical_data']['netDebtEBITDA'],
                    values['calculation-5-year-avg']['historical_data']['netDebtEBITDA']
                ),
                return_obj(
                    "NetDebt/EBITDA (Year)",
                    values['additional_cal']['netDebtEBITDA'],
                    values['iSqore']['netDebtEbitdaYearGrade'],
                    values['median_sector']['netDebtEbitdaYear'],
                    values['calculation-3-year-avg']['historical_data']['netDebtEBITDA'],
                    values['calculation-5-year-avg']['historical_data']['netDebtEBITDA']
                ),

                return_obj(
                    "Current Ratio (Quarterly)",
                    values['additional_cal_quarter']['currentRatio'],
                    values['iSqore']['currentRatioGrade'],
                    values['median_sector']['currentRatio'],
                    values['calculation-3-year-avg']['historical_data']['currentRatio'],
                    values['calculation-5-year-avg']['historical_data']['currentRatio']
                ),
                return_obj(
                    "Current Ratio (Year)",
                    values['additional_cal']['currentRatio'],
                    values['iSqore']['currentRatioYearGrade'],
                    values['median_sector']['currentRatioYear'],
                    values['calculation-3-year-avg']['historical_data']['currentRatio'],
                    values['calculation-5-year-avg']['historical_data']['currentRatio']
                ),

                return_obj(
                    "Quick Ratio (Quarterly)",
                    values['additional_cal_quarter']['quickRatio'],
                    values['iSqore']['quickRatioGrade'],
                    values['median_sector']['quickRatio'],
                    values['calculation-3-year-avg']['historical_data']['quickRatio'],
                    values['calculation-5-year-avg']['historical_data']['quickRatio']
                ),
                return_obj(
                    "Quick Ratio (Year)",
                    values['additional_cal']['quickRatio'],
                    values['iSqore']['quickRatioYearGrade'],
                    values['median_sector']['quickRatioYear'],
                    values['calculation-3-year-avg']['historical_data']['quickRatio'],
                    values['calculation-5-year-avg']['historical_data']['quickRatio']
                ),

                return_obj(
                    "Altman Z-Score (Cur)",
                    values['current-cal']['altmanZScore'],
                    values['iSqore']['altmanZScoreCurrentGrade'],
                    values['median_sector']['altmanZScoreCurrent'],
                    values['calculation-3-year-avg']['historical_data']['altmanZScore'],
                    values['calculation-5-year-avg']['historical_data']['altmanZScore']
                ),

                return_obj(
                    "Altman Z-Score (Quarterly)",
                    values['additional_cal_quarter']['altmanZScore'],
                    values['iSqore']['altmanZScoreQuarterGrade'],
                    values['median_sector']['altmanZScoreQuarter'],
                    values['calculation-3-year-avg']['historical_data']['altmanZScore'],
                    values['calculation-5-year-avg']['historical_data']['altmanZScore']
                ),

                return_obj(
                    "Altman Z-Score (Year)",
                    values['additional_cal']['altmanZScore'],
                    values['iSqore']['altmanZScoreYearGrade'],
                    values['median_sector']['altmanZScoreYear'],
                    values['calculation-3-year-avg']['historical_data']['altmanZScore'],
                    values['calculation-5-year-avg']['historical_data']['altmanZScore']
                ),

                return_obj(
                    "Piotroski F-Score (Cur)",
                    values['current-cal']['piotroskiFScore'],
                    values['iSqore']['piotroskiFScoreGrade'],
                    values['median_sector']['piotroskiFScore'],
                    values['calculation-3-year-avg']['historical_data']['piotroskiFScore'],
                    values['calculation-5-year-avg']['historical_data']['piotroskiFScore']
                ),
                return_obj(
                    "Piotroski F-Score (Year)",
                    values['additional_cal']['piotroskiFScore'],
                    values['iSqore']['piotroskiFScoreYearGrade'],
                    values['median_sector']['piotroskiFScoreYear'],
                    values['calculation-3-year-avg']['historical_data']['piotroskiFScore'],
                    values['calculation-5-year-avg']['historical_data']['piotroskiFScore']
                )
            ],
            iSqore: {
                value: values['iSqore']['health'],
                grade: return_string(values['iSqore'], 'healthGrade'),
            }
        }
        setData(data)
    }

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use  this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={iSqoreHealthDark}
            lightBlockerImg={iSqoreHealthLight}
            code={code}
            exchange={exchange}

            content={
                loading ?
                    <div style={{width: '100%', height: '100%', display: ' flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <>
                        <div className="row g-0">

                            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12">
                                <ISqoreTable
                                    name={'Health'}
                                    data={data}
                                />
                            </div>

                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12">
                                <FinancialBreakdownChart code={code} exchange={exchange} type={'annual'}
                                                         colors={["#3e9fed", "#29f3a7", "#ee1981"]}/>
                            </div>

                        </div>
                    </>
            }
        />
    );
}

export default HealthTab;