import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import SummaryTabGraphContainer from "../MarketOverview/Summary/SummaryTabGraphContainer";
import CompanyOverViewPerformanceTable from "./CompanyTables/CompanyOverViewPerformanceTable";
import CompanyOverviewHighlightsTable from "./CompanyTables/CompanyOverviewHighlightsTable";
import CompanyOverviewBottomTable from "./CompanyTables/CompanyOverviewBottomTable";
import {formatNumericValue, return_strong_num} from "../Utils";
import {useSelector} from "react-redux";
import companyOverviewTablesBlockerDark from "../../assets/blockers/company-overview-tables-blocker-dark.png";
import companyOverviewTablesBlockerLight from "../../assets/blockers/company-overview-tables-blocker-light.png";
import {ThemeContext} from "../../Context/ThemeContext";
import Config from "../../Config";
import CompanyOverviewLatestNews from "./CompanyTables/CompanyOverviewLatestNews";
import {useQuery} from "@tanstack/react-query";
import CompanyOverviewCharts from "./CompanyOverviewCharts";
import FinancialsControls from "./FinancialTabs/FinancialsControls";
import RestrictedContent from "../RestrictedContent";

const company_overview_body = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-body/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const company_overview_bottom_table = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-bottom-table/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const company_overview_fmp_prices = async (fmp_code) => {
    if (fmp_code !== null) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-fmp-prices/${fmp_code}/`, Config());
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

const CompanySummaryTab = ({logoURL, code, exchange}) => {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    const {webTheme} = useContext(ThemeContext);
    const [data, setData] = useState(null)
    const [fmpCode, setFMPCode] = useState(null)

    const [chartData, setChartData] = useState({name: "", code: "", type: "Common Stock"})

    const [marketCap, setMarketCap] = useState(0)

    const {data: companyOverviewBodyReturn, isLoading: companyOverviewBodyIsLoading} = useQuery({
        queryKey: [`company-overview-body/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_overview_body(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (!companyOverviewBodyIsLoading) {
            setData(companyOverviewBodyReturn)
            setFMPCode(companyOverviewBodyReturn['fmp_code'])
            setMarketCap(companyOverviewBodyReturn['m_cap_financial'])
            setChartData({name: companyOverviewBodyReturn.name, code: code, exchange: exchange, type: "Common Stock"})
        }
    }, [companyOverviewBodyReturn, companyOverviewBodyIsLoading])

    const [bottomTableData, setBottomTableData] = useState({})

    const {data: bottomTableDataReturn, isLoading: bottomTableDataIsLoading} = useQuery({
        queryKey: [`company-overview-bottom-table/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_overview_bottom_table(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (!bottomTableDataIsLoading && bottomTableDataReturn) {
            setBottomTableData(bottomTableDataReturn)
        }
    }, [bottomTableDataReturn, bottomTableDataIsLoading])


    const {data: companyOverviewFMPCodeReturn, isLoading: companyOverviewFMPCodeIsLoading} = useQuery({
        queryKey: [`company-overview-fmp-prices/${fmpCode}/`, fmpCode],
        queryFn: () => company_overview_fmp_prices(fmpCode),
        staleTime: 30000
    })

    useEffect(() => {
        if (!companyOverviewFMPCodeIsLoading) {
            if (companyOverviewFMPCodeReturn) {
                setMarketCap(companyOverviewFMPCodeReturn.marketCap)
            }
        }
    }, [companyOverviewFMPCodeReturn, companyOverviewFMPCodeIsLoading]);

    const [meta, setMeta] = useState({curr_symbol: "", currency: ""})

    const [active, setActive] = useState('annual')

    const handleActive = (type) => {
        setActive(type)
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financial-meta/${code}/${exchange}/`)
            .then(response => {
                setMeta(response.data)
            })
    }, [code, exchange])

    return (
        <div>
            {data &&
                <>
                    <div className={'row g-0'}>
                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <SummaryTabGraphContainer chartData={chartData}
                                                      container={'company-overview-chart-container'}
                                                      innerContainer={'company-overview-chart-inner-container'}/>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <CompanyOverViewPerformanceTable title={'Performance'} performance={data.performance}/>
                        </div>
                        <div className="col-xl-6 col-lg-9 col-md-9 col-sm-12 col-xs-12">
                            <CompanyOverviewLatestNews logoURL={logoURL} code={code} exchange={exchange}/>
                        </div>
                    </div>

                    <RestrictedContent
                        conditionText={'You must be paid subscriber to use  this feature'}
                        blockerLg={true}
                        loginRequired={true}
                        subscriptionRequired={true}

                        darkBlockerImg={companyOverviewTablesBlockerDark}
                        lightBlockerImg={companyOverviewTablesBlockerLight}
                        code={code}
                        exchange={exchange}

                        content={
                            <>
                                <div className={"row g-0 company-overview-bottom-table"}
                                     style={{position: 'relative', width: '100%', height: '100%'}}
                                >
                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <CompanyOverviewBottomTable
                                            title={"Value"}
                                            grade={bottomTableData?.iSqore?.valueGrade}
                                            data={[
                                                {
                                                    name: 'P/Earnings (Fwd)',
                                                    value: formatNumericValue({value: bottomTableData?.valuation?.ForwardPE}),
                                                    grade: bottomTableData?.iSqore?.priceEarningsForwardGrade
                                                },
                                                {
                                                    name: 'P/Earnings',
                                                    value: formatNumericValue({value: bottomTableData?.current?.priceEarnings}),
                                                    grade: bottomTableData?.iSqore?.priceEarningsCurrentGrade
                                                },
                                                {
                                                    name: 'P/FCF',
                                                    value: formatNumericValue({value: bottomTableData?.current?.priceFreeCashFlow}),
                                                    grade: bottomTableData?.iSqore?.priceFreeCashFlowCurrentGrade
                                                },
                                                {
                                                    name: 'P/Sales',
                                                    value: formatNumericValue({value: bottomTableData?.current?.priceSales}),
                                                    grade: bottomTableData?.iSqore?.priceSalesCurrentGrade
                                                },
                                                // {
                                                //     name: 'PEG Ratio',
                                                //     value: formatNumericValue({value: bottomTableData?.current?.pegRatio}),
                                                //     grade: bottomTableData?.iSqore?.pegRatioCurrentGrade
                                                // },
                                                {
                                                    name: 'EV/Revenue',
                                                    value: formatNumericValue({value: bottomTableData?.current?.evRevenue}),
                                                    grade: bottomTableData?.iSqore?.evRevenueCurrentGrade
                                                },
                                                {
                                                    name: 'EV/EBIT',
                                                    value: formatNumericValue({value: bottomTableData?.current?.eveBit}),
                                                    grade: bottomTableData?.iSqore?.eveBitCurrentGrade
                                                },
                                                {
                                                    name: 'EV/EBITDA',
                                                    value: formatNumericValue({value: bottomTableData?.current?.eveBitda}),
                                                    grade: bottomTableData?.iSqore?.eveBitdaCurrentGrade
                                                },
                                                {
                                                    name: 'Earnings Yield',
                                                    value: formatNumericValue({value: bottomTableData?.current?.earningsYield, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.earningsYieldCurrentGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'FCF Yield',
                                                    value: formatNumericValue({value: bottomTableData?.current?.fcfYield, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.fcfYieldTTMGrade,
                                                    per: true
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <CompanyOverviewBottomTable
                                            title={"Profitability"}
                                            grade={bottomTableData?.iSqore?.profitabilityGrade}
                                            data={[
                                                // {
                                                //     name: 'Return on Assets',
                                                //     value: formatNumericValue({value: bottomTableData?.ttm?.returnOnAssets, multiply: 100}),
                                                //     grade: bottomTableData?.iSqore?.returnOnAssetsTTMGrade,
                                                //     per: true
                                                // },
                                                {
                                                    name: 'Return on Equity',
                                                    value: formatNumericValue({value: bottomTableData?.ttm?.returnOnEquity, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.returnOnEquityTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'Return on Cap Empl.',
                                                    value: formatNumericValue({value: bottomTableData?.ttm?.returnOnCapitalEmployed, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.returnOnCapitalEmployedTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'Return on Invested Capital',
                                                    value: formatNumericValue({value: bottomTableData?.ttm?.returnOnInvestedCapital, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.returnOnInvestedCapitalTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'Gross Margin',
                                                    value: formatNumericValue({value: bottomTableData?.ttm?.grossMargin, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.grossMarginTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'Operating Margin',
                                                    value: formatNumericValue({value: bottomTableData?.ttm?.operatingMargin, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.operatingMarginTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'Net Margin',
                                                    value: formatNumericValue({value: bottomTableData?.ttm?.netMargin, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.netMarginTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'EBIT Margin',
                                                    value: formatNumericValue({value: bottomTableData?.ttm?.ebitMargin, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.ebitMarginTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'EBITDA Margin',
                                                    value: formatNumericValue({value: bottomTableData?.ttm?.ebitdaMargin, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.ebitdaMarginTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'FCF Margin',
                                                    value: formatNumericValue({value: bottomTableData?.ttm?.fcfMargin, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.fcfMarginTTMGrade,
                                                    per: true
                                                },
                                            ]}
                                        />
                                    </div>

                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <CompanyOverviewBottomTable
                                            title={"Growth"}
                                            grade={bottomTableData?.iSqore?.growthGrade}
                                            data={[
                                                {
                                                    name: 'Revenue Growth TTM',
                                                    value: formatNumericValue({value: bottomTableData?.growth_ttm?.totalRevenue, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.revenueGrowthTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'Revenue Growth YoY',
                                                    value: formatNumericValue({value: bottomTableData?.growth_yoy?.totalRevenue, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.revenueGrowthYOYGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'Earnings Growth TTM',
                                                    value: formatNumericValue({value: bottomTableData?.growth_ttm?.netIncome_income_statement, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.earningsGrowthTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'Earnings Growth YoY',
                                                    value: formatNumericValue({value: bottomTableData?.growth_yoy?.netIncome_income_statement, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.earningsGrowthYOYGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'EPS Growth TTM',
                                                    value: formatNumericValue({value: bottomTableData?.growth_ttm?.earningsPerShare, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.earningsPerShareGrowthTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'EPS Growth YoY',
                                                    value: formatNumericValue({value: bottomTableData?.growth_yoy?.earningsPerShare, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.earningsPerShareGrowthYOYGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'EBIT Growth',
                                                    value: formatNumericValue({value: bottomTableData?.growth_ttm?.ebit, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.ebitGrowthTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'EBITDA Growth',
                                                    value: formatNumericValue({value: bottomTableData?.growth_ttm?.ebitda, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.ebitdaGrowthTTMGrade,
                                                    per: true
                                                },
                                                {
                                                    name: 'FCF Growth',
                                                    value: formatNumericValue({value: bottomTableData?.growth_ttm?.freeCashFlow, multiply: 100}),
                                                    grade: bottomTableData?.iSqore?.freeCashFlowGrowthTTMGrade,
                                                    per: true
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <CompanyOverviewBottomTable
                                            title={"Health"}
                                            grade={bottomTableData?.iSqore?.healthGrade}
                                            data={[
                                                {
                                                    name: 'Cash Ratio',
                                                    value: formatNumericValue({value: bottomTableData?.quarterly?.cashRatio}),
                                                    grade: bottomTableData?.iSqore?.cashRatioGrade
                                                },
                                                {
                                                    name: 'Current Ratio',
                                                    value: formatNumericValue({value: bottomTableData?.quarterly?.currentRatio}),
                                                    grade: bottomTableData?.iSqore?.currentRatioGrade
                                                },
                                                {
                                                    name: 'Quick Ratio',
                                                    value: formatNumericValue({value: bottomTableData?.quarterly?.quickRatio}),
                                                    grade: bottomTableData?.iSqore?.quickRatioGrade
                                                },
                                                {
                                                    name: 'Equity/Assets',
                                                    value: formatNumericValue({value: bottomTableData?.quarterly?.equityAssets}),
                                                    grade: bottomTableData?.iSqore?.equityAssetsGrade
                                                },
                                                {
                                                    name: 'Debt/Equity',
                                                    value: formatNumericValue({value: bottomTableData?.quarterly?.debtEquity}),
                                                    grade: bottomTableData?.iSqore?.debtEquityGrade
                                                },
                                                {
                                                    name: 'Debt/Assets',
                                                    value: formatNumericValue({value: bottomTableData?.quarterly?.debtAsset}),
                                                    grade: bottomTableData?.iSqore?.debtAssetGrade
                                                },
                                                {
                                                    name: 'FCF/Debt',
                                                    value: formatNumericValue({value: bottomTableData?.quarterly?.fcfDebt}),
                                                    grade: bottomTableData?.iSqore?.fcfDebtGrade
                                                },
                                                {
                                                    name: 'Piotroski F-Score',
                                                    value: formatNumericValue({value: bottomTableData?.current?.piotroskiFScore}),
                                                    grade: bottomTableData?.iSqore?.piotroskiFScoreGrade
                                                },
                                                // {
                                                //     name: 'Debt/EBIT',
                                                //     value: formatNumericValue({value: bottomTableData?.ttm?.debtEBIT}),
                                                //     grade: bottomTableData?.iSqore?.debtEbitTTMGrade
                                                // },
                                                // {
                                                //     name: 'Debt/EBITDA',
                                                //     value: formatNumericValue({value: bottomTableData?.ttm?.debtEBITDA}),
                                                //     grade: bottomTableData?.iSqore?.debtEbitdaTTMGrade
                                                // },
                                                {
                                                    name: 'Altman Z-Score',
                                                    value: formatNumericValue({value: bottomTableData?.current?.altmanZScore}),
                                                    grade: bottomTableData?.iSqore?.altmanZScoreCurrentGrade
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <FinancialsControls
                                        options={[{label: "Annual", key: "annual"}, {
                                            label: "Quarterly",
                                            key: 'quarter'
                                        }]}
                                        handleActive={handleActive} active={active} meta={meta} balance_sheet={true}/>

                                    <CompanyOverviewCharts code={code} exchange={exchange} active={active}/>
                                </div>
                            </>
                        }
                    />
                </>
            }
        </div>
    );
}

export default CompanySummaryTab;