import React, {useEffect, useState} from 'react';
import {getModalStyle} from "../Utils";
import {AiOutlineCloseCircle} from "react-icons/ai";
import {useSelector} from "react-redux";
import clsx from "clsx";
import {FaLock} from "react-icons/fa";
import {Chip} from "@material-ui/core";
import ScreenerModalFilterJson from "../ScreenerModalFilterJson";

function ScreenerFilterModal({
                                 filterScreener,
                                 setFilterScreener,

                                 handleFilterCloseModal,
                                 compareStockModelFilterJSON = JSON.parse(JSON.stringify(ScreenerModalFilterJson))
                             }) {

    const [filterScreenerModal, setFilterScreenerModal] = useState(compareStockModelFilterJSON);

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    const [modalStyle] = useState(getModalStyle);

    const [selectedKey, setSelectedKey] = useState('Company Information')

    const handleSelectKey = (key) => {
        setSelectedKey(key)
    }

    useEffect(() => {
        let temp_filter = {...filterScreenerModal}
        let active_filters = []

        Object.entries(filterScreener).map(([key, filter]) => {
            if (filter.display && key !== "General") {
                Object.entries(filter.components).map(([component_key, component]) => {
                    if (component.display) {
                        active_filters.push(component_key)
                    }
                })
            }
        })

        Object.entries(temp_filter).map(([key, sub]) => {
            Object.entries(sub).map(([filterKey, filter]) => {
                if (filter.tree) {
                    Object.entries(filter.components).map(([componentKey, component]) => {
                        if (active_filters.includes(componentKey)) {
                            component.selected = true
                        }
                    })
                } else {
                    if (active_filters.includes(filterKey)) {
                        filter.selected = true
                    }
                }


                if (sub.tree) {
                    console.log(sub)
                    Object.entries(sub.components).map(([componentKey, component]) => {
                        if (active_filters.includes(componentKey)) {
                            component.selected = true
                        }
                    })
                } else {
                    Object.entries(sub).map(([componentKey, component]) => {
                        if (active_filters.includes(componentKey)) {
                            component.selected = true
                        }
                    })
                }
            })
        })

        setFilterScreenerModal(temp_filter)
    }, []);

    const add_filter = (key, sub_key, component_key) => {
        let groupDisplay = false
        let temp_filter = {...filterScreenerModal};
        let temp_filter_screener = {...filterScreener};

        if (temp_filter[key][sub_key].tree) {
            temp_filter[key][sub_key]['components'][component_key] = {
                ...temp_filter[key][sub_key]['components'][component_key],
                selected: !temp_filter[key][sub_key]['components'][component_key].selected
            }

            temp_filter_screener[key] = {
                ...temp_filter_screener[key],
                components: {
                    ...temp_filter_screener[key].components,
                    [component_key]: {
                        ...temp_filter_screener[key]['components'][component_key],
                        display: !temp_filter_screener[key]['components'][component_key].display
                    }
                }
            }
        } else {
            temp_filter[key][sub_key] = {...temp_filter[key][sub_key], selected: !temp_filter[key][sub_key].selected}

            temp_filter_screener[key] = {
                ...temp_filter_screener[key],
                components: {
                    ...temp_filter_screener[key].components,
                    [sub_key]: {
                        ...temp_filter_screener[key]['components'][sub_key],
                        display: !temp_filter_screener[key]['components'][sub_key].display,
                    }
                }
            }
        }

        Object.entries(temp_filter_screener[key].components).map(([_componentKey, filter]) => {
            if (filter.display) {
                groupDisplay = true
            }
        })

        temp_filter_screener[key].display = groupDisplay

        setFilterScreenerModal(temp_filter)

        setFilterScreener(temp_filter_screener)
    }

    const handleDelete = (key, component_key) => {
        let groupDisplay = false
        let temp_filter_screener = {...filterScreener};

        temp_filter_screener[key] = {
            ...temp_filter_screener[key],
            components: {
                ...temp_filter_screener[key].components,
                [component_key]: {
                    ...temp_filter_screener[key]['components'][component_key],
                    display: false
                }
            }
        }

        Object.entries(temp_filter_screener[key].components).map(([_componentKey, filter]) => {
            if (filter.display) {
                groupDisplay = true
            }
        })

        temp_filter_screener[key].display = groupDisplay

        setFilterScreener(temp_filter_screener)

        let temp_filter = {...filterScreenerModal}

        Object.entries(temp_filter[key]).map(([subKey, sub]) => {
            if (sub.tree) {
                Object.entries(sub.components).map(([componentKey, filter]) => {
                    if (componentKey === component_key) {
                        filter.selected = false
                    }
                })
            } else {
                if (subKey === component_key) {
                    sub.selected = false
                }
            }
        })

        setFilterScreenerModal(temp_filter)
    }

    const [searchFilter, setSearchFilter] = useState("")
    const handleChangeSearchFilter = (e) => {
        setSearchFilter(e.target.value)
    }

    // const auth_paid_condition = (key, isAuthenticated, user) => {
    //     if (key === 'TSI' || key === 'iSqore') {
    //         if (!isAuthenticated || (isAuthenticated && ))
    //     }
    //     if ((!((key === "TSI" || key === "iSqore") && (!isAuthenticated || (isAuthenticated && user.subscription.package_name !== 'Free')))))
    //
    //     return false
    // }

    return (
        <div style={modalStyle}
             className={'generic-modal-body screener-filter-modal new-screener-filter-modal'}>

            <div style={{display: 'flex', marginBottom: 10}}>
                <p className={'screener-modal-add-filter-text'}>Advanced Filters</p>
                <AiOutlineCloseCircle onClick={handleFilterCloseModal}
                                      className={'filter-component-group-container-close'}/>
            </div>


            <input onChange={handleChangeSearchFilter}
                   className={'form-control screener-filter-search mb-2'}
                   placeholder={'Search Filters'}
            />

            <div className={'d-flex'} style={{width: "100%"}}>
                {searchFilter !== "" ?
                    <div style={{width: "60%", padding: "0 10px"}}>
                        <p className={'screener-filter-model-heading'}>Select Filters</p>
                        <div className="screener-filter-filters-container" style={{display: 'flex', flexWrap: "wrap"}}>
                            {Object.entries(filterScreenerModal).map(([filter_cat_key, filter_cat]) => {
                                return Object.entries(filter_cat).map(([filter_key, filter]) => {
                                    return (
                                        filter.tree ?
                                            Object.entries(filter.components).map(([component_key, component]) => {
                                                if (component.display_name.toLowerCase().includes(searchFilter.toLowerCase())) {
                                                    return (
                                                        <div
                                                            onClick={() => {
                                                                add_filter(filter_cat_key, filter_key, component_key)
                                                            }}
                                                            className={clsx('screener-filter-select-div', component.selected && "screener-filter-select-div-selected")}>
                                                            {component.display_name}
                                                        </div>
                                                    )
                                                }
                                            })
                                            :
                                            filter.display_name.toLowerCase().includes(searchFilter.toLowerCase()) &&
                                            <div className={'d-flex'}>
                                                <div
                                                    onClick={() => {
                                                        add_filter(filter_cat_key, filter_key)
                                                    }}
                                                    className={clsx('screener-filter-select-div my-1', filter.selected && "screener-filter-select-div-selected")}>
                                                    {filter.display_name}
                                                </div>
                                            </div>
                                    )
                                })
                            })}
                        </div>
                    </div>
                    :
                    <>
                        <div style={{width: "20%"}}>
                            <p className={'screener-filter-container-headings'}>
                                Category
                            </p>
                            {Object.entries(filterScreenerModal).map(([key, filter]) => {
                                return (
                                    <div key={key} onClick={() => {
                                        if (!((key === "TSI" || key === "iSqore") && (!isAuthenticated || (isAuthenticated && user.subscription.package_name === 'Free')))) {
                                            handleSelectKey(key)
                                        }
                                    }}
                                         className={clsx(
                                             'screener-filter-category-items',
                                             selectedKey === key && "screener-filter-category-items-selected",
                                             ((key === "TSI" || key === "iSqore") && (!isAuthenticated || (isAuthenticated && user.subscription.package_name === 'Free'))) && "screener-filter-category-items-locked"
                                         )}>
                                        <p>{key}</p>
                                        {((key === "TSI" || key === "iSqore") && (!isAuthenticated || (isAuthenticated && user.subscription.package_name === 'Free'))) &&
                                            <FaLock style={{margin: "auto 0 auto auto"}}/>
                                        }
                                    </div>
                                )
                            })}

                        </div>
                        <div style={{width: "40%", padding: "0 10px"}}>
                            <p className={'screener-filter-container-headings'}>
                                Filters
                            </p>

                            <div className={'screener-filter-filters-container'}>
                                {Object.entries(filterScreenerModal[selectedKey]).map(([sub_key, filter]) => {
                                    return (
                                        filter.tree ?
                                            <div>
                                                <p className={'screener-filter-container-headings'} style={{
                                                    fontSize: 14,
                                                    fontWeight: 600,
                                                    marginLeft: 5,
                                                    marginBottom: 5
                                                }}>{filter.name}</p>
                                                <div className={'d-flex mb-2'} style={{flexWrap: "wrap"}}>
                                                    {Object.entries(filter.components).map(([component_key, component]) => {
                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    add_filter(selectedKey, sub_key, component_key)
                                                                }}
                                                                className={clsx('screener-filter-select-div', component.selected && "screener-filter-select-div-selected")}>
                                                                {component.name}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            :
                                            <div className={'d-flex'}>
                                                <div
                                                    onClick={() => {
                                                        add_filter(selectedKey, sub_key)
                                                    }}
                                                    className={clsx('screener-filter-select-div', filter.selected && "screener-filter-select-div-selected")}>
                                                    {filter.name}
                                                </div>
                                            </div>
                                    )
                                })}
                            </div>

                        </div>
                    </>
                }
                <div style={{width: "40%", padding: "0 10px"}}>
                    <p className={'screener-filter-container-headings'}>
                        Selected Filters
                    </p>

                    <div className={'screener-filter-filters-container'}
                         style={{display: 'flex', flexWrap: "wrap"}}>
                        {Object.entries(filterScreener).map(([key, filter]) => {
                            if (key !== 'General' && filter.display) {
                                return Object.entries(filter.components).map(([component_key, component_filter]) => {
                                    if (component_filter.display) {
                                        return (
                                            <Chip className={'m-1'} key={component_key}
                                                  label={component_filter.name}
                                                  onDelete={() => handleDelete(key, component_key)}/>
                                        )
                                    }
                                })
                            }
                        })}
                    </div>

                </div>
            </div>

        </div>
    );
}

export default ScreenerFilterModal;