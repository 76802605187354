import {Box} from "@material-ui/core";
import React, {useContext} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ThemeContext} from "../Context/ThemeContext";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

export function is_number(num) {
    return !isNaN(parseFloat(num)) && isFinite(num);
}

export function return_float(value) {
    if (value && is_number(value)) {
        return parseFloat(value);
    } else {
        return 0;
    }
}

export function return_float_fixed(value) {
    return parseFloat(value).toFixed(2);
}

export function return_per_bg_color(value) {
    if (value === null || return_strong_num(value) === '0.00') {
        return '#89898966'
    } else if (return_strong_num(value) > 0) {
        return '#4ebe9629'
    } else {
        return '#d84f6829'
    }
}

export function return_per_div(per, fontSize = 11, width = 55, margin = "0 0 0 auto", add_word = null, add_word_1, add_word_2) {
    return (
        <div style={{
            color: (per === null || return_strong_num(per) === '0.00' || return_strong_num(per) === '-100.00') ? 'white' : return_td_color(per),
            fontWeight: 600,
            margin: margin,
            width: width,
            fontSize: fontSize,
            backgroundColor: (per === null || return_strong_num(per) === '0.00' || return_strong_num(per) === '-100.00') ? '#89898966' : return_strong_num(per) > 0 ? '#4ebe9629' : '#d84f6829',
            padding: 2,
            borderRadius: 5,
            textAlign: "center",
        }}>
            {(per !== null && return_strong_num(per) !== '0.00' && return_strong_num(per) !== '-100.00') ?
                `${return_strong_num(per)}% ${add_word ? (return_strong_num(per) > 0 ? add_word_1 : add_word_2) : ""}`
                :
                '- %'
            }
        </div>
    )
}

export function return_per_div_string(text, fontSize = 11, width = 55, margin = "0 0 0 auto") {
    return (
        <div style={{
            color: "white",
            fontWeight: 600,
            margin: margin,
            width: width,
            fontSize: fontSize,
            backgroundColor: text === 'New Buy' ? '#54cb97' : '#ff646e',
            padding: 2,
            borderRadius: 5,
            textAlign: "center",
        }}>
            {text}
        </div>
    )
}

export function get_fair_value_growth(expectedCAGRValues, multiply = 100) {
    let value = null
    if (expectedCAGRValues.expectedTotalRevenueGrowth5YCARG && expectedCAGRValues.expectedTotalRevenueGrowth3YCARG) {
        const avg = (expectedCAGRValues.expectedTotalRevenueGrowth5YCARG + expectedCAGRValues.expectedTotalRevenueGrowth3YCARG) / 2
        value = multiple(avg, multiply) > 25 ? 25 : multiple(avg, multiply)
    } else if (expectedCAGRValues.expectedTotalRevenueGrowth5YCARG) {
        value = multiple(expectedCAGRValues.expectedTotalRevenueGrowth5YCARG, multiply) > 25 ? 25 : multiple(expectedCAGRValues.expectedTotalRevenueGrowth5YCARG, multiply)
    } else if (expectedCAGRValues.expectedTotalRevenueGrowth3YCARG) {
        value = multiple(expectedCAGRValues.expectedTotalRevenueGrowth3YCARG, multiply) > 25 ? 25 : multiple(expectedCAGRValues.expectedTotalRevenueGrowth3YCARG, multiply)
    }
    if (value) {
        if (multiply === 1) {
            return value
        } else {
            return return_strong_num(value)
        }
    }
    return '-'
}

export function formatNumericValue({value, multiply = 1}) {
    if (is_number(value)) {
        value = value * multiply

        if (Math.abs(value) < 1000000000) {
            return parseFloat(value).toFixed(2);
        } else {
            return Math.floor(value);
        }
    } else {
        return null;
    }
}

export function return_strong_num(value) {
    if (is_number(value)) {
        if (Math.abs(value) < 1000000000) {
            return parseFloat(value).toFixed(2);
        } else {
            return Math.floor(value);
        }
    } else {
        return null;
    }
}

export function return_quarter_earnings_row(title, value, per = false, surprise = null, surpriseValue = null) {
    return (
        <div className={'earnings-table-row'}>
            <p style={{fontSize: 12, fontWeight: 600}}>{title}</p>
            {per ?
                return_per_div(value, 11, 100, "auto 0 auto auto")
                :
                <p style={{fontSize: 12, marginLeft: "auto"}}>{value}</p>
            }

            {surprise &&
                return_quarter_earnings_surprise_div(11, 100, "auto 0 auto 10px", surprise, surpriseValue)
            }
        </div>
    )
}

export function return_quarter_earnings_surprise_div(fontSize = 11, width = 55, margin = "0 0 0 auto", surprise, surpriseValue) {
    return (
        <div style={{
            color: (surprise === null || surprise === "In Line") ? 'white' : surprise === 'Beat' ? '#4ebe96' : '#d84f68',
            fontWeight: 600,
            margin: margin,
            width: width,
            fontSize: fontSize,
            backgroundColor: (surprise === null || surprise === "In Line") ? '#89898966' : surprise === 'Beat' ? '#4ebe9629' : '#d84f6829',
            padding: 2,
            borderRadius: 5,
            textAlign: "center",
        }}>
            {surprise !== null ?
                `${surprise !== "In Line" ? `${Math.abs(surpriseValue)} % ` : ""} ${surprise}`
                :
                "Upcoming"
            }
        </div>
    )
}

export function return_value_in_million(value) {
    if (Math.abs(value) < 1000000000) {
        return parseFloat(value / 1000000).toFixed(2)
    } else {
        return Math.floor(value / 1000000)
    }
}

export function return_strong_string(value) {
    if (is_number(value)) {
        return parseFloat(value).toFixed(2);
    } else {
        return "-";
    }
}

export function return_num(obj, key) {
    if (obj && key in obj && obj[key] && is_number(obj[key])) {
        return Number.isInteger(obj[key]) ? obj[key] : parseFloat(obj[key]).toFixed(2);
    } else {
        return 0
    }
}

export function return_string(obj, key) {
    if (obj && key in obj && obj[key]) {
        return obj[key]
    } else {
        return "-"
    }
}

export function divide(a, b) {
    if (is_number(a) && is_number(b) && parseFloat(b) !== 0.0) {
        return a / b
    } else {
        return null
    }
}

export function multiple(a, b) {
    if (is_number(a) && is_number(b)) {
        return a * b
    } else {
        return null
    }
}

export function subtract(a, b) {
    if (is_number(a) && is_number(b)) {
        return a - b
    } else {
        return null
    }
}

export function add(a, b) {
    if (is_number(a) && is_number(b)) {
        return a + b
    } else {
        return null
    }
}

export function return_td_percentage(value) {
    return (
        <td style={{color: value > 0 ? '#4ebe96' : '#fc2f2f'}}>
            {return_strong_num(return_strong_num(value) * 100)} %
        </td>
    )
}

export function return_td_color(value) {
    if (value === null) {
        return "white";
    } else if (return_strong_num(value) > 0) {
        return '#4ebe96'
    } else if (return_strong_num(value) < 0) {
        return '#d84f68'
    } else {
        return 'white'
    }
}

export function sort(name, order, setColumns, columns, data, setData) {

    setColumns(columns.map((item) => {
        if (item.name === name) {
            item.state = !item.state
            return item
        } else
            return item
    }))

    if (order) {
        setData(
            [...data].sort((a, b) => {
                let _a = a[name]
                if (is_number(_a)) {
                    _a = parseFloat(_a)
                }

                let _b = b[name]
                if (is_number(_b)) {
                    _b = parseFloat(_b)
                }

                if (_a < _b) {
                    return -1;
                }
                if (_a > _b) {
                    return 1;
                }
                return 0;
            })
        );
    } else {
        setData(
            [...data].sort((a, b) => {

                let _a = a[name]
                if (is_number(_a)) {
                    _a = parseFloat(_a)
                }

                let _b = b[name]
                if (is_number(_b)) {
                    _b = parseFloat(_b)
                }

                if (_a > _b) {
                    return -1;
                }
                if (_a < _b) {
                    return 1;
                }
                return 0;
            })
        );
    }
}

export function company_overview_sort(name, order, setColumns, columns, data, setData) {
    setColumns(columns.map((item) => {
        if (item.key === name) {
            item.state = !item.state
            return item
        } else {
            item.state = 0
            return item
        }
    }))

    if (order) {
        setData(
            {
                ...data,
                tableData: [...data.tableData].sort((a, b) => {
                    if (is_number(a)) {
                        a = parseFloat(a)
                    }
                    if (is_number(b)) {
                        b = parseFloat(b)
                    }
                    if (a[name] < b[name]) {
                        return -1;
                    }
                    if (a[name] > b[name]) {
                        return 1;
                    }
                    return 0;
                })
            }
        );
    } else {
        setData(
            {
                ...data,
                tableData: [...data.tableData].sort((a, b) => {
                    if (is_number(a)) {
                        a = parseFloat(a)
                    }
                    if (is_number(b)) {
                        b = parseFloat(b)
                    }
                    if (a[name] > b[name]) {
                        return -1;
                    }
                    if (a[name] < b[name]) {
                        return 1;
                    }
                    return 0;
                })
            }
        );
    }
}

export const tableDataRow = ({
                                 content = null,
                                 onClick = null,
                                 className = "",
                                 hoverText = false,
                                 hoverCount = 25,
                                 per = false,
                                 valueInMillion = false,
                                 valueInComma = false,
                                 additional_attr = {},
                                 label = ""
                             }) => {
    return {
        content: content,
        onClick: onClick,
        className: className,
        hoverText: hoverText,
        hoverCount: hoverCount,
        valueInMillion: valueInMillion,
        valueInComma: valueInComma,
        per: per,
        label: label,
        ...additional_attr
    }
}

const return_td_value = (item) => {
    let value = item['content']
    if (item.valueInMillion) {
        value = return_value_in_million(value)
    }
    if (item.valueInComma) {
        value = numberWithCommas(value)
    }
    return value
}

export const get_table_td = (row, column) => {
    const item = row.rowItems.filter(item => item.label === column.label)[0]

    return (
        <td key={item.index}
            onClick={item['onClick']}
            className={clsx(item['hoverText'] && item['content'].length > item['hoverCount'] && "hovertext", item['className'])}
            data-hover={item['content']}
            style={{
                ...(item['per'] && {
                    fontWeight: 600,
                    color: return_td_color(item['content'])
                }),
            }}
        >
            {item['changePer'] ?
                <div style={{
                    ...(item['changePer'] && {
                        fontSize: 11,
                        padding: "2px 0",
                        borderRadius: 5,
                        width: 55,
                        margin: 'auto 0 auto auto',
                        backgroundColor: return_per_bg_color(item['content']),
                        textAlign: "center",
                    })
                }}>
                    {item['content'] ? item['content'] : '-'} {item['per'] && "%"}
                </div>
                :
                <>
                    {item['iSqore'] ?
                        <>
                            <div className={'isqore-lg-table-td-div'}>
                                <p>{item['content']}</p>
                                <p className={'isqore-td-separator'}>|</p>
                                <p className={'grade_p'}
                                   style={{
                                       border: `1px solid ${return_grade_color(item['iSqoreRating'])}`,
                                       color: return_grade_color(item['iSqoreRating']),
                                       backgroundColor: return_bg_grade_color(item['iSqoreRating'])
                                   }}>
                                    {item['iSqoreRating']}</p>
                            </div>
                        </>
                        :
                        <>
                            {item['hoverText'] ?
                                item['content'].length > item['hoverCount'] ? item['content'].substring(0, item['hoverCount']) + "..." : item['content'] :
                                return_td_value(item)
                            }
                            {item['per'] && "%"}
                        </>
                    }
                </>
            }
        </td>
    )
}

export function sortDataByIndex(index, order, columns, setColumns, tableData, setTableData) {

    setColumns(columns.map((item, i) => {
        if (i === index) {
            item.state = !item.state
            return item
        } else {
            item.state = 0
            return item
        }
    }))

    const sortedData = tableData.sort((a, b) => {
        a = a['rowItems'][index].content
        if (is_number(a)) {
            a = parseFloat(a)
        }
        b = b['rowItems'][index].content
        if (is_number(b)) {
            b = parseFloat(b)
        }
        if (order) {
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            }
            return 0;
        } else {
            if (a > b) {
                return -1;
            } else if (a < b) {
                return 1;
            }
            return 0;
        }
    })
    setTableData(sortedData)

}


export function return_obj(name, ratio, grade, sector_median, avg_3, avg_5, per=false) {
    return {
        name: name,
        ratio: ratio,
        grade: grade,
        sector_median: sector_median,
        diff_to_sector: divide(ratio, sector_median),
        avg_3: avg_3,
        avg_5: avg_5,
        per: per,
    }
}

export function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

export const raise_error = (err) => {
    let error = ''
    console.log(err.response.data)
    Object.keys(err.response.data).forEach((key, index) => {
        if (typeof err.response.data === 'object')
            error += err.response.data[key]
        else
            err.response.data[key].map(item => error += item)
    })

    return error
}

const make_index_obj = (flag, name, code, country, exchange_code, currency, fmp) => {
    return {
        flag: flag,
        name: name,
        code: code,
        fmp: fmp,
        country: country,
        exchange_code: exchange_code,
        currency: currency
    }
}

export const indexData = {
    "GSPC.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "S&P 500", "GSPC", "USA", "US", "USD", "^GSPC"),
    "SP100.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "S&P 100", "SP100", "USA", "US", "USD", "^OEX"),
    "DJI.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "Dow Jones", "DJI", "USA", "US", "USD", "^DJI"),
    "NDX.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "Nasdaq 100", "NDX", "USA", "US", "USD", "^NDX"),
    "RUI.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "Russell 1000", "RUI", "USA", "US", "USD", "^RUI"),
    "RUT.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "Russell 2000", "RUT", "USA", "US", "USD", "^RUT"),

    "STOXX.INDX": make_index_obj("https://flagcdn.com/108x81/eu.png", "Europe Stoxx 600", "STOXX", "Europe", "XETRA", "USD", "^STOXX"),
    "STOXX50E.INDX": make_index_obj("https://flagcdn.com/108x81/eu.png", "EuroStoxx 50", "STOXX50E", "Europe", "XETRA", "USD", "^STOXX50E"),

    "GDAXI.INDX": make_index_obj("https://flagcdn.com/108x81/de.png", "DAX40", "GDAXI", "Germany", "XETRA", "USD", "^GDAXI"),
    "MDAXI.INDX": make_index_obj("https://flagcdn.com/108x81/de.png", "DAX Midcap", "MDAXI", "Germany", "XETRA", "USD", "^MDAXI"),
    "SDAXI.INDX": make_index_obj("https://flagcdn.com/108x81/de.png", "DAX Smallcap", "SDAXI", "Germany", "XETRA", "USD", "^SDAXI"),
    "TECDAX.INDX": make_index_obj("https://flagcdn.com/108x81/de.png", "TecDAX", "TECDAX", "Germany", "XETRA", "USD", "^TECDAX"),
    "GDAXHI.INDX": make_index_obj("https://flagcdn.com/108x81/de.png", "HDAX", "GDAXHI", "Germany", "XETRA", "USD", ""),

    "FTSE.INDX": make_index_obj("https://flagcdn.com/108x81/gb.png", "FTSE 100", "FTSE", "United Kingdom", "LSE", "USD", "^FTSE"),
    "FTMC.INDX": make_index_obj("https://flagcdn.com/108x81/gb.png", "FTSE 250", "FTMC", "United Kingdom", "LSE", "USD", "^FTMC"),
    "FTLC.INDX": make_index_obj("https://flagcdn.com/108x81/gb.png", "FTSE 350", "FTLC", "United Kingdom", "LSE", "USD", "^FTLC"),
    "FTAS.INDX": make_index_obj("https://flagcdn.com/108x81/gb.png", "FTSE All-Share", "FTAS", "United Kingdom", "LSE", "USD", ""),

    "FCHI.INDX": make_index_obj("https://flagcdn.com/108x81/fr.png", "CAC 40", "FCHI", "France", "PA", "USD", "^FCHI"),
    "CACT.INDX": make_index_obj("https://flagcdn.com/108x81/fr.png", "CAC All-Tradable", "CACT", "France", "PA", "USD", ""),
    "SPMIB.INDX": make_index_obj("https://flagcdn.com/108x81/it.png", "FTSE MIB Index", "SPMIB", "Italy", "MI", "USD", ""),

    "IBEX.INDX": make_index_obj("https://flagcdn.com/108x81/es.png", "IBEX 35 Index", "IBEX", "Spain", "MC", "USD", "^IBEX"),

    "AEX.INDX": make_index_obj("https://flagcdn.com/108x81/nl.png", "AEX Amsterdam Index", "AEX", "Netherlands", "AS", "USD", "^AEX"),

    "ATX.INDX": make_index_obj("https://flagcdn.com/108x81/at.png", "Austrian Traded Index", "ATX", "Austria", "VI", "USD", "^ATX"),

    "BEL20.INDX": make_index_obj("https://flagcdn.com/108x81/be.png", "BEL-20 Index", "BEL20", "Belgium", "BR", "USD", "^BFX"),

    "OMXC20.INDX": make_index_obj("https://flagcdn.com/108x81/dk.png", "OMX Copenhagen 20 Index", "OMXC20", "Denmark", "CO", "USD", ""),
    "OMXCGI.INDX": make_index_obj("https://flagcdn.com/108x81/dk.png", "OMX Copenhagen All shares", "OMXCGI", "Denmark", "CO", "USD", ""),

    "OMXH25.INDX": make_index_obj("https://flagcdn.com/108x81/fi.png", "OMX Helsinki 25", "OMXH25", "Finland", "HE", "USD", ""),
    "OMXHPI.INDX": make_index_obj("https://flagcdn.com/108x81/fi.png", "OMX Helsinki All Shares", "OMXHPI", "Finland", "HE", "USD", ""),

    "OBX.INDX": make_index_obj("https://flagcdn.com/108x81/no.png", "OBX Oslo 25", "OBX", "Norway", "OL", "USD", ""),
    "OSEAX.INDX": make_index_obj("https://flagcdn.com/108x81/no.png", "OBX Oslo All Shares", "OSEAX", "Norway", "OL", "USD", ""),

    "OMXS30GI.INDX": make_index_obj("https://flagcdn.com/108x81/se.png", "OMX Stockholm 30", "OMXS30GI", "Sweden", "ST", "USD", ""),
    "OMXSPI.INDX": make_index_obj("https://flagcdn.com/108x81/se.png", "OMX Stockholm All Shares", "OMXSPI", "Sweden", "ST", "USD", ""),

    "IETP.INDX": make_index_obj("https://flagcdn.com/108x81/ie.png", "ISEQ 20", "IETP", "Ireland", "IR", "USD", ""),

    "TA35.INDX": make_index_obj("https://flagcdn.com/108x81/il.png", "Tel Aviv 35 Index", "TA35", "Israel", "TA", "USD", ""),
    "TA125.INDX": make_index_obj("https://flagcdn.com/108x81/il.png", "Tel Aviv 100 Index", "TA125", "Israel", "TA", "USD", ""),

    "WIG30.INDX": make_index_obj("https://flagcdn.com/108x81/pl.png", "WIG 30", "WIG30", "Poland", "WAR", "USD", ""),
    "WIG.INDX": make_index_obj("https://flagcdn.com/108x81/pl.png", "WIG All Shares", "WIG", "Poland", "WAR", "USD", ""),

    "PSI20.INDX": make_index_obj("https://flagcdn.com/108x81/pt.png", "PSI 20 Stock Index", "PSI20", "Portugal", "LS", "USD", ""),

    "SSMI.INDX": make_index_obj("https://flagcdn.com/108x81/ch.png", "Swiss Market Index", "SSMI", "Switzerland", "SW", "USD", "^SSMI"),
    "SSHI.INDX": make_index_obj("https://flagcdn.com/108x81/ch.png", "Swiss Performance Index", "SSHI", "Switzerland", "SW", "USD", ""),

    "XU030.INDX": make_index_obj("https://flagcdn.com/108x81/tr.png", "BIST 30", "XU030", "Turkey", "IS", "USD", ""),
    "XU100.INDX": make_index_obj("https://flagcdn.com/108x81/tr.png", "BIST 100", "XU100", "Turkey", "IS", "USD", "XU100.IS"),

    "AXAF.INDX": make_index_obj("https://flagcdn.com/108x81/au.png", "ASX 50", "AXAF", "Australia", "AU", "USD", ""),
    "ATOI.INDX": make_index_obj("https://flagcdn.com/108x81/au.png", "ASX 100", "ATOI", "Australia", "AU", "USD", ""),
    "AXAT.INDX": make_index_obj("https://flagcdn.com/108x81/au.png", "ASX 200", "AXAT", "Australia", "AU", "USD", "^AXJO"),

    "IBX50.INDX": make_index_obj("https://flagcdn.com/108x81/br.png", "Bovespa Brazil 50", "IBX50", "Brazil", "SA", "USD", ""),
    "BVSP.INDX": make_index_obj("https://flagcdn.com/108x81/br.png", "Bovespa", "BVSP", "Brazil", "SA", "USD", "^BVSP"),

    "GSPTSE.INDX": make_index_obj("https://flagcdn.com/108x81/ca.png", "TSX 60", "GSPTSE", "Canada", "TO", "USD", "TX60.TS"),

    "SPIPSA.INDX": make_index_obj("https://flagcdn.com/108x81/cl.png", "S&P CLX IPSA", "SPIPSA", "Chile", "SN", "USD", ""),

    "N225.INDX": make_index_obj("https://flagcdn.com/108x81/jp.png", "Nikkei 225", "N225", "Japan", "TSE", "USD", "^N225"),

    "SZSC.INDX": make_index_obj("https://flagcdn.com/108x81/cn.png", "SZSE Composite", "SZSC", "China", "SHG", "USD", ""),
    "SSE180.INDX": make_index_obj("https://flagcdn.com/108x81/cn.png", "SSE 180 Index", "SSE180", "China", "SHG", "USD", ""),

    "HSI.INDX": make_index_obj("https://flagcdn.com/108x81/hk.png", "Hang Seng", "HSI", "Hong Kong", "HK", "USD", "^HSI"),
    "HSCE.INDX": make_index_obj("https://flagcdn.com/108x81/hk.png", "Hang Seng China Enterprise", "HSCE", "Hong Kong", "HK", "USD", "^HSI"),
    "HSCI.INDX": make_index_obj("https://flagcdn.com/108x81/hk.png", "Hang Seng Composite", "HSCI", "Hong Kong", "HK", "USD", ""),
    "HSTECH.INDX": make_index_obj("https://flagcdn.com/108x81/hk.png", "Hang Seng Tech", "HSTECH", "Hong Kong", "HK", "USD", ""),

    "NSEI.INDX": make_index_obj("https://flagcdn.com/108x81/in.png", "Nifty 50", "NSEI", "India", "NSE", "USD", "^NSEI"),

    "KOSDAQ.INDX": make_index_obj("https://flagcdn.com/108x81/kr.png", "Kosdaq Composite", "KOSDAQ", "Korea", "KQ", "USD", ""),

    "KLSE.INDX": make_index_obj("https://flagcdn.com/108x81/my.png", "FTSE Malaysia KLCI", "KLSE", "Malaysia", "KLSE", "USD", "^KLSE"),

    "KSE100.INDX": make_index_obj("https://flagcdn.com/108x81/pk.png", "Karachi 100", "KSE100", "Pakistan", "KAR", "USD", ""),

    "PSEI.INDX": make_index_obj("https://flagcdn.com/108x81/ph.png", "PSEI Index", "PSEI", "Philippines", "PSE", "USD", ""),

    "TWII.INDX": make_index_obj("https://flagcdn.com/108x81/tw.png", "Taiwan Weighted Index", "TWII", "Taiwan", "TW", "USD", "^TWII"),

    "TASI.INDX": make_index_obj("https://flagcdn.com/108x81/sa.png", "Tadawul All Share", "TASI", "Saudi Arabia", "SR", "USD", "^TASI.SR"),

    "SET.INDX": make_index_obj("https://flagcdn.com/108x81/th.png", "Thailand SET Index", "SET", "Thailand", "BK", "USD", "")
}

export const selected_table_styles = (webTheme) => {
    return {
        menu: (styles) => ({
            ...styles,
            backgroundColor: webTheme === 'dark' ? '#03111E' : '#FFFFFF',
        }),
        placeholder: (styles) => ({
            ...styles,
            fontSize: 14
        }),
        option: (styles, {data, isDisabled, isFocused, isSelected}) => ({
            ...styles,
            backgroundColor: webTheme === 'dark' ? '#0C1B29' : '#F5F6FA',
            color: webTheme === 'dark' ? '#FFFFFF' : 'black',
            padding: 10,
            fontSize: 12,
            fontWeight: 600,
            ':hover': {
                color: webTheme === 'dark' ? '#FFFFFF' : '#0C1B29',
                backgroundColor: webTheme === 'dark' ? '#03111E' : '#FFFFFF'
            }
        }),
        control: (styles) => ({
            ...styles,
            backgroundColor: webTheme === 'dark' ? '#03111E' : '#F5F6FA',
            borderColor: webTheme === 'dark' ? '#265862' : '#F5F6FA',
            padding: "4px 0",
            ':active': {
                borderColor: 'green'
            }
        }),
        multiValue: (styles, {data}) => {
            return {
                ...styles,
                backgroundColor: '#1BA2DA',
                color: '#FFFFFF',
            };
        },
        multiValueLabel: (styles, {data}) => ({
            ...styles,
            color: '#FFFFFF',
            fontSize: 10,
            fontWeight: 700
        }),
        multiValueRemove: (styles, {data}) => ({
            ...styles,
            display: data.isFixed ? 'none' : styles.display,
            color: '#FFFFFF',
            ':hover': {
                backgroundColor: '#196785',
                color: 'white',
            },
        }),
    }
}

// export const return_grade_color = (grade) => {
//     let color = '#ea165d';
//     if (grade) {
//         if (grade === 'A+') {
//             color = '#28c07f'
//         } else if (grade === 'A') {
//             color = '#28c07f'
//         } else if (grade === 'A-') {
//             color = '#28c07f'
//         } else if (grade === 'B+') {
//             color = '#d8bd28'
//         } else if (grade === 'B') {
//             color = '#d8bd28'
//         } else if (grade === 'B-') {
//             color = '#d8bd28'
//         } else if (grade === 'C+') {
//             color = '#f57b5e'
//         } else if (grade === 'C') {
//             color = '#f57b5e'
//         } else if (grade === 'C-') {
//             color = '#f57b5e'
//         } else if (grade === 'D+') {
//             color = '#ea165d'
//         } else if (grade === 'D') {
//             color = '#ea165d'
//         } else if (grade === 'D-') {
//             color = '#ea165d'
//         } else if (grade === '-') {
//             color = '#CACBCD'
//         }
//     }
//     return color
// }

export const return_grade_color = (grade) => {
    let color = '#ea165d';
    if (grade) {
        if (grade.includes('A')) {
            color = '#28c07f'
        } else if (grade.includes('B')) {
            color = '#a1d357'
        } else if (grade.includes('C')) {
            color = '#d8bd28'
        } else if (grade.includes('D')) {
            color = '#f57b5e'
        } else if (grade.includes('E')) {
            color = '#f24c68'
        } else if (grade === 'F') {
            color = '#ea165d'
        } else if (grade === '-') {
            color = '#868686'
        } else {
            color = '#868686'
        }
    }
    return color
}

export const return_bg_grade_color = (grade) => {
    let color = '#ea165d20';
    if (grade) {
        if (grade.includes('A')) {
            color = '#28c07f33';
        } else if (grade.includes('B')) {
            color = '#a1d35733'
        } else if (grade.includes('C')) {
            color = '#d8bd2833'
        } else if (grade.includes('D')) {
            color = '#f57b5e33'
        } else if (grade.includes('E')) {
            color = '#f24c6833'
        } else if (grade.includes('F')) {
            color = '#ea165d33'
        } else if (grade === '-') {
            color = '#86868633'
        } else {
            color = '#86868633'
        }
    }
    return color
}

export const return_grade_background_color = (grade) => {
    let color = '#ea165d';
    if (grade) {
        if (grade === 'A+') {
            color = '#28c07f'
        } else if (grade === 'A') {
            color = '#28c07f'
        } else if (grade === 'A-') {
            color = '#28c07f'
        } else if (grade === 'B+') {
            color = '#d8bd28'
        } else if (grade === 'B') {
            color = '#d8bd28'
        } else if (grade === 'B-') {
            color = '#d8bd28'
        } else if (grade === 'C+') {
            color = '#f57b5e'
        } else if (grade === 'C') {
            color = '#f57b5e'
        } else if (grade === 'C-') {
            color = '#f57b5e'
        } else if (grade === 'D+') {
            color = '#ea165d'
        } else if (grade === 'D') {
            color = '#ea165d'
        } else if (grade === 'D-') {
            color = '#ea165d'
        }
    }
    return color
}


export function ISqoreDescription({
                                      iSqore,
                                      iSqoreGrade,
                                      value,
                                      valueGrade,
                                      profit,
                                      profitGrade,
                                      growth,
                                      growthGrade,
                                      health,
                                      healthGrade,
                                      quality,
                                      qualityGrade
                                  }) {
    return (
        <>
            <div style={{margin: "auto 20px"}}>
                <p className={'company-overview-top-bar-iSqore-text'}
                   style={{marginBottom: 5}}>iSqore</p>
                <CircularProgressWithLabel
                    value={iSqore}
                    grade={iSqoreGrade}/>
            </div>

            <div>
                <div className={'iSqore-health-indicator-container'}>
                    <p className={'indicator-name'}>Value</p>
                    <div className={"progress analyst-progress-bar"}>
                        <div className="progress-bar" role="progressbar"
                             style={{
                                 width: `${value * 100}%`,
                                 backgroundColor: return_grade_color(valueGrade)
                             }}
                             aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                    </div>
                    <p className={'indicator-grade'}>{valueGrade}</p>
                </div>

                <div className={'iSqore-health-indicator-container'}>
                    <p className={'indicator-name'}>Profit</p>

                    <div className={"progress analyst-progress-bar"}>
                        <div className="progress-bar" role="progressbar"
                             style={{
                                 width: `${profit * 100}%`,
                                 backgroundColor: return_grade_color(profitGrade)
                             }}
                             aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                    </div>
                    <p className={'indicator-grade'}>{profitGrade}</p>
                </div>

                <div className={'iSqore-health-indicator-container'}>
                    <p className={'indicator-name'}>Growth</p>
                    <div className={'progress analyst-progress-bar'}>
                        <div className="progress-bar" role="progressbar"
                             style={{
                                 width: `${growth * 100}%`,
                                 backgroundColor: return_grade_color(growthGrade)
                             }}
                             aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                    </div>
                    <p className={'indicator-grade'}>{growthGrade}</p>
                </div>

                <div className={'iSqore-health-indicator-container'}>
                    <p className={'indicator-name'}>Health</p>
                    <div className={'progress analyst-progress-bar'}>
                        <div className="progress-bar" role="progressbar"
                             style={{
                                 width: `${health * 100}%`,
                                 backgroundColor: return_grade_color(healthGrade)
                             }}
                             aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                    </div>
                    <p className={'indicator-grade'}>{healthGrade}</p>
                </div>

                <div className={'iSqore-health-indicator-container'}>
                    <p className={'indicator-name'}>Quality</p>
                    <div className={'progress analyst-progress-bar'}>
                        <div className="progress-bar" role="progressbar"
                             style={{
                                 width: `${quality * 100}%`,
                                 backgroundColor: return_grade_color(qualityGrade)
                             }}
                             aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                    </div>
                    <p className={'indicator-grade'}>{qualityGrade}</p>
                </div>
            </div>

        </>
    )
}

const useStylesCircularProgress = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    top: {
        // color: '#1a90ff',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));

export function CircularProgressWithLabel({value, grade, compare_stocks=false}) {
    const {webTheme} = useContext(ThemeContext);
    const classes = useStylesCircularProgress();

    return (
        <div style={{display: 'flex', position: 'relative', margin: compare_stocks ? "auto": 0}}>
            <div style={{position: 'relative', margin: '0 auto'}}>
                <CircularProgress
                    style={{color: webTheme === 'dark' ? "#132A3F" : "#e8e8e8"}}
                    thickness={4} variant="determinate" value={100} size={60}/>
                <CircularProgress
                    variant="determinate"
                    style={{color: return_grade_color(grade)}}
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={60}
                    thickness={4}
                    value={value * 100}
                />
            </div>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <div>
                    <p className={'etf-iSqore-text'}>{return_strong_num(value * 100)}</p>
                    <p className={'etf-iSqore-text'} style={{
                        color: return_grade_color(grade)
                    }}>{grade}</p>
                </div>
            </Box>
        </div>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
}

export function numberWithCommas(x) {
    if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return null
    }
}

export function calculate_grade(value) {
    if (!value) {
        return null
    }
    if (value > 1.033) {
        return 'A+'
    } else if (0.934 < value && value <= 1.333) {
        return 'A'
    } else if (0.849 < value && value <= 0.934) {
        return 'A-'
    } else if (0.783 < value && value <= 0.849) {
        return 'B+'
    } else if (0.717 < value && value <= 0.783) {
        return 'B'
    } else if (0.651 < value && value <= 0.717) {
        return 'B-'
    } else if (0.566 < value && value <= 0.651) {
        return 'C+'
    } else if (0.467 < value && value <= 0.566) {
        return 'C'
    } else if (0.401 < value && value <= 0.467) {
        return 'C-'
    } else if (0.316 < value && value <= 0.401) {
        return 'D+'
    } else if (0.217 <= value && value <= 0.316) {
        return 'D'
    } else if (value < 0.217) {
        return 'D-'
    }
}


export const info_complete = (user) => {
    const check = (val) => {
        return (val === null || val === "")
    }
    return !(check(user.first_name) || check(user.last_name) || check(user.birthday) || check(user.country) || check(user.zip_code) || check(user.address));
}


export const setScore = (analysisData) => {
    let score = {
        evRevenueScore: 0,
        grossMarginScore: 0,
        highGrowthRatio: 0,
        revenueGrowthScore: 0,
        debtEquityScore: 0,
        pegRatioScore: 0,
        highGrowthRatioScore: 0
    }
    score.evRevenueScore = analysisData['evRevenue'] < 0 ? 0 : (10 < analysisData['evRevenue'] && analysisData['evRevenue'] <= 12) ? 1 : ((8 < analysisData['evRevenue'] && analysisData['evRevenue'] <= 10) ? 2 : ((analysisData['evRevenue'] > 0 && analysisData['evRevenue'] <= 8) ? 3 : 0))

    score.grossMarginScore = analysisData['grossMargin'] < 0 ? 0 : (0.5 <= analysisData['grossMargin'] && analysisData['grossMargin'] < 0.65) ? 1 : ((0.65 <= analysisData['grossMargin'] && analysisData['grossMargin'] < 0.75) ? 2 : (analysisData['grossMargin'] <= 0.75 ? 3 : 0))

    score.highGrowthRatio = analysisData['highGrowthRatio'] < 0 ? 0 : (0.4 <= analysisData['highGrowthRatio'] && analysisData['highGrowthRatio'] < 0.45) ? 1 : ((0.45 <= analysisData['highGrowthRatio'] && analysisData['highGrowthRatio'] < 0.5) ? 2 : (analysisData['highGrowthRatio'] <= 0.5 ? 3 : 0))

    score.revenueGrowthScore = analysisData['revenueGrowth'] < 0 ? 0 : (0.4 <= analysisData['revenueGrowth'] && analysisData['revenueGrowth'] < 0.45) ? 1 : ((0.45 <= analysisData['revenueGrowth'] && analysisData['revenueGrowth'] < 0.5) ? 2 : (analysisData['revenueGrowth'] <= 0.5 ? 3 : 0))

    score.debtEquityScore = analysisData['debtEquity'] < 0 ? 0 : (1 < analysisData['debtEquity'] && analysisData['debtEquity'] <= 1.5) ? 1 : ((0.5 < analysisData['debtEquity'] && analysisData['debtEquity'] <= 1) ? 2 : (analysisData['debtEquity'] <= 0.5 ? 3 : 0))

    score.pegRatioScore = analysisData['pegRatio'] < 0 ? 0 : (2 <= analysisData['pegRatio'] && analysisData['pegRatio'] < 4) ? 1 : ((1 <= analysisData['pegRatio'] && analysisData['pegRatio'] < 2) ? 2 : ((0 < analysisData['pegRatio'] && analysisData['pegRatio'] < 1) ? 3 : 0))

    score.highGrowthRatioScore = score.evRevenueScore + score.grossMarginScore + score.highGrowthRatio + score.revenueGrowthScore + score.debtEquityScore + score.pegRatioScore

    return score
}

export const tab_locked = (isAuthenticated, user, code, exchange) => {
    const allowedCodes = ["AAPL", "IBM", "KO", "DIS"]
    const allowedExchanges = ["US",]
    if ((isAuthenticated && user.subscription.package_name !== 'Free') || (allowedCodes.includes(code) && allowedExchanges.includes(exchange))) {
        return false
    }
    return true
}

export const screener_isqore_mapping = {
    "iSqore": {valueKey: "iSqore", gradeKey: "iSqoreGrade"},
    "value": {valueKey: "value", gradeKey: "valueGrade"},
    "profitability": {valueKey: "profitability", gradeKey: "profitabilityGrade"},
    "growth": {valueKey: "growth", gradeKey: "growthGrade"},
    "health": {valueKey: "health", gradeKey: "healthGrade"},
    "quality": {valueKey: "quality", gradeKey: "qualityGrade"},

    "additionalcalculations_annual_highGrowthRatio": {valueKey: "highGrowthRatioTTMISqoreValue", gradeKey: "highGrowthRatioTTMGrade"},
    "ttm_highGrowthRatio": {valueKey: "highGrowthRatioYearISqoreValue", gradeKey: "highGrowthRatioYearGrade"},

    "forwardPE": {valueKey: "priceEarningsForwardISqoreValue", gradeKey: "priceEarningsForwardGrade"},
    "current_priceEarnings": {valueKey: "priceEarningsCurrentISqoreValue", gradeKey: "priceEarningsCurrentGrade"},
    "additionalcalculations_annual_priceEarnings": {valueKey: "priceEarningsYearISqoreValue", gradeKey: "priceEarningsYearGrade"},
    "calculation_3_year_average_priceEarnings": {valueKey: "priceEarnings3YearAvgISqoreValue", gradeKey: "priceEarnings3YearAvgGrade"},
    "calculation_5_year_average_priceEarnings": {valueKey: "priceEarnings5YearAvgISqoreValue", gradeKey: "priceEarnings5YearAvgGrade"},

    "current_priceSales": {valueKey: "priceSalesCurrentISqoreValue", gradeKey: "priceSalesCurrentGrade"},
    "current_priceSalesForward": {valueKey: "priceSalesForwardISqoreValue", gradeKey: "priceSalesForwardGrade"},
    "additionalcalculations_annual_priceSales": {valueKey: "priceSalesYearISqoreValue", gradeKey: "priceSalesYearGrade"},
    "calculation_3_year_average_priceSales": {valueKey: "priceSales3YearAvgISqoreValue", gradeKey: "priceSales3YearAvgGrade"},
    "calculation_5_year_average_priceSales": {valueKey: "priceSales5YearAvgISqoreValue", gradeKey: "priceSales5YearAvgGrade"},

    "current_priceFreeCashFlow": {valueKey: "priceFreeCashFlowCurrentISqoreValue", gradeKey: "priceFreeCashFlowCurrentGrade"},
    "additionalcalculations_annual_priceFreeCashFlow": {valueKey: "priceFreeCashFlowYearISqoreValue", gradeKey: "priceFreeCashFlowYearGrade"},
    "calculation_3_year_average_priceFreeCashFlow": {valueKey: "priceFreeCashFlow3YearAvgISqoreValue", gradeKey: "priceFreeCashFlow3YearAvgGrade"},
    "calculation_5_year_average_priceFreeCashFlow": {valueKey: "priceFreeCashFlow5YearAvgISqoreValue", gradeKey: "priceFreeCashFlow5YearAvgGrade"},

    "current_priceBook": {valueKey: "priceBookCurrentISqoreValue", gradeKey: "priceBookCurrentGrade"},
    "additionalcalculations_annual_priceBook": {valueKey: "priceBookYearISqoreValue", gradeKey: "priceBookYearGrade"},
    "calculation_3_year_average_priceBook": {valueKey: "priceBook3YearAvgISqoreValue", gradeKey: "priceBook3YearAvgGrade"},
    "calculation_5_year_average_priceBook": {valueKey: "priceBook5YearAvgISqoreValue", gradeKey: "priceBook5YearAvgGrade"},

    "current_evRevenue": {valueKey: "evRevenueCurrentISqoreValue", gradeKey: "evRevenueCurrentGrade"},
    "additionalcalculations_annual_evRevenue": {valueKey: "evRevenueYearISqoreValue", gradeKey: "evRevenueYearGrade"},
    "calculation_3_year_average_evRevenue": {valueKey: "evRevenue3YearAvgISqoreValue", gradeKey: "evRevenue3YearAvgGrade"},
    "calculation_5_year_average_evRevenue": {valueKey: "evRevenue5YearAvgISqoreValue", gradeKey: "evRevenue5YearAvgGrade"},

    "current_eveBit": {valueKey: "eveBitCurrentISqoreValue", gradeKey: "eveBitCurrentGrade"},
    "additionalcalculations_annual_eveBit": {valueKey: "eveBitYearISqoreValue", gradeKey: "eveBitYearGrade"},
    "calculation_3_year_average_eveBit": {valueKey: "eveBit3YearAvgISqoreValue", gradeKey: "eveBit3YearAvgGrade"},
    "calculation_5_year_average_eveBit": {valueKey: "eveBit5YearAvgISqoreValue", gradeKey: "eveBit5YearAvgGrade"},

    "current_eveBitda": {valueKey: "eveBitdaCurrentISqoreValue", gradeKey: "eveBitdaCurrentGrade"},
    "additionalcalculations_annual_eveBitda": {valueKey: "eveBitdaYearISqoreValue", gradeKey: "eveBitdaYearGrade"},
    "calculation_3_year_average_eveBitda": {valueKey: "eveBitda3YearAvgISqoreValue", gradeKey: "eveBitda3YearAvgGrade"},
    "calculation_5_year_average_eveBitda": {valueKey: "eveBitda5YearAvgISqoreValue", gradeKey: "eveBitda5YearAvgGrade"},

    "current_pegRatio": {valueKey: "pegRatioCurrentISqoreValue", gradeKey: "pegRatioCurrentGrade"},
    "current_pegRatioForward": {valueKey: "pegRatioForwardISqoreValue", gradeKey: "pegRatioForwardGrade"},
    "additionalcalculations_annual_pegRatio": {valueKey: "pegRatioYearISqoreValue", gradeKey: "pegRatioYearGrade"},
    "calculation_3_year_average_pegRatio": {valueKey: "pegRatio3YearAvgISqoreValue", gradeKey: "pegRatio3YearAvgGrade"},
    "calculation_5_year_average_pegRatio": {valueKey: "pegRatio5YearAvgISqoreValue", gradeKey: "pegRatio5YearAvgGrade"},

    "current_earningsYield": {valueKey: "earningsYieldCurrentISqoreValue", gradeKey: "earningsYieldCurrentGrade"},
    "current_earningsYieldForward": {valueKey: "earningsYieldForwardISqoreValue", gradeKey: "earningsYieldForwardGrade"},
    "additionalcalculations_annual_earningsYield": {valueKey: "earningsYieldYearISqoreValue", gradeKey: "earningsYieldYearGrade"},
    "calculation_3_year_average_earningsYield": {valueKey: "earningsYield3YearAvgISqoreValue", gradeKey: "earningsYield3YearAvgGrade"},
    "calculation_5_year_average_earningsYield": {valueKey: "earningsYield5YearAvgISqoreValue", gradeKey: "earningsYield5YearAvgGrade"},

    "ttm_returnOnAssets": {valueKey: "returnOnAssetsTTMISqoreValue", gradeKey: "returnOnAssetsTTMGrade"},
    "additionalcalculations_annual_returnOnAssets": {valueKey: "returnOnAssetsYearISqoreValue", gradeKey: "returnOnAssetsYearGrade"},
    "calculation_3_year_average_returnOnAssets": {valueKey: "returnOnAssets3YearAvgISqoreValue", gradeKey: "returnOnAssets3YearAvgGrade"},
    "calculation_5_year_average_returnOnAssets": {valueKey: "returnOnAssets5YearAvgISqoreValue", gradeKey: "returnOnAssets5YearAvgGrade"},

    "ttm_returnOnEquity": {valueKey: "returnOnEquityTTMISqoreValue", gradeKey: "returnOnEquityTTMGrade"},
    "additionalcalculations_annual_returnOnEquity": {valueKey: "returnOnEquityYearISqoreValue", gradeKey: "returnOnEquityYearGrade"},
    "calculation_3_year_average_returnOnEquity": {valueKey: "returnOnEquity3YearAvgISqoreValue", gradeKey: "returnOnEquity3YearAvgGrade"},
    "calculation_5_year_average_returnOnEquity": {valueKey: "returnOnEquity5YearAvgISqoreValue", gradeKey: "returnOnEquity5YearAvgGrade"},

    "ttm_returnOnCapital": {valueKey: "returnOnCapitalTTMISqoreValue", gradeKey: "returnOnCapitalTTMGrade"},
    "additionalcalculations_annual_returnOnCapital": {valueKey: "returnOnCapitalYearISqoreValue", gradeKey: "returnOnCapitalYearGrade"},
    "calculation_3_year_average_returnOnCapital": {valueKey: "returnOnCapital3YearAvgISqoreValue", gradeKey: "returnOnCapital3YearAvgGrade"},
    "calculation_5_year_average_returnOnCapital": {valueKey: "returnOnCapital5YearAvgISqoreValue", gradeKey: "returnOnCapital5YearAvgGrade"},

    "ttm_returnOnCapitalEmployed": {valueKey: "returnOnCapitalEmployedTTMISqoreValue", gradeKey: "returnOnCapitalEmployedTTMGrade"},
    "additionalcalculations_annual_returnOnCapitalEmployed": {valueKey: "returnOnCapitalEmployedYearISqoreValue", gradeKey: "returnOnCapitalEmployedYearGrade"},
    "calculation_3_year_average_returnOnCapitalEmployed": {valueKey: "returnOnCapitalEmployed3YearAvgISqoreValue", gradeKey: "returnOnCapitalEmployed3YearAvgGrade"},
    "calculation_5_year_average_returnOnCapitalEmployed": {valueKey: "returnOnCapitalEmployed5YearAvgISqoreValue", gradeKey: "returnOnCapitalEmployed5YearAvgGrade"},

    "ttm_returnOnInvestedCapital": {valueKey: "returnOnInvestedCapitalTTMISqoreValue", gradeKey: "returnOnInvestedCapitalTTMGrade"},
    "additionalcalculations_annual_returnOnInvestedCapital": {valueKey: "returnOnInvestedCapitalYearISqoreValue", gradeKey: "returnOnInvestedCapitalYearGrade"},
    "calculation_3_year_average_returnOnInvestedCapital": {valueKey: "returnOnInvestedCapital3YearAvgISqoreValue", gradeKey: "returnOnInvestedCapital3YearAvgGrade"},
    "calculation_5_year_average_returnOnInvestedCapital": {valueKey: "returnOnInvestedCapital5YearAvgISqoreValue", gradeKey: "returnOnInvestedCapital5YearAvgGrade"},

    "ttm_grossMargin": {valueKey: "grossMarginTTMISqoreValue", gradeKey: "grossMarginTTMGrade"},
    "additionalcalculations_annual_grossMargin": {valueKey: "grossMarginYearISqoreValue", gradeKey: "grossMarginYearGrade"},
    "calculation_3_year_average_grossMargin": {valueKey: "grossMargin3YearAvgISqoreValue", gradeKey: "grossMargin3YearAvgGrade"},
    "calculation_5_year_average_grossMargin": {valueKey: "grossMargin5YearAvgISqoreValue", gradeKey: "grossMargin5YearAvgGrade"},

    "ttm_operatingMargin": {valueKey: "operatingMarginTTMISqoreValue", gradeKey: "operatingMarginTTMGrade"},
    "additionalcalculations_annual_operatingMargin": {valueKey: "operatingMarginYearISqoreValue", gradeKey: "operatingMarginYearGrade"},
    "calculation_3_year_average_operatingMargin": {valueKey: "operatingMargin3YearAvgISqoreValue", gradeKey: "operatingMargin3YearAvgGrade"},
    "calculation_5_year_average_operatingMargin": {valueKey: "operatingMargin5YearAvgISqoreValue", gradeKey: "operatingMargin5YearAvgGrade"},

    "ttm_netMargin": {valueKey: "netMarginTTMISqoreValue", gradeKey: "netMarginTTMGrade"},
    "additionalcalculations_annual_netMargin": {valueKey: "netMarginYearISqoreValue", gradeKey: "netMarginYearGrade"},
    "calculation_3_year_average_netMargin": {valueKey: "netMargin3YearAvgISqoreValue", gradeKey: "netMargin3YearAvgGrade"},
    "calculation_5_year_average_netMargin": {valueKey: "netMargin5YearAvgISqoreValue", gradeKey: "netMargin5YearAvgGrade"},

    "ttm_ebitMargin": {valueKey: "ebitMarginTTMISqoreValue", gradeKey: "ebitMarginTTMGrade"},
    "additionalcalculations_annual_ebitMargin": {valueKey: "ebitMarginYearISqoreValue", gradeKey: "ebitMarginYearGrade"},
    "calculation_3_year_average_ebitMargin": {valueKey: "ebitMargin3YearAvgISqoreValue", gradeKey: "ebitMargin3YearAvgGrade"},
    "calculation_5_year_average_ebitMargin": {valueKey: "ebitMargin5YearAvgISqoreValue", gradeKey: "ebitMargin5YearAvgGrade"},

    "ttm_ebitdaMargin": {valueKey: "ebitdaMarginTTMISqoreValue", gradeKey: "ebitdaMarginTTMGrade"},
    "additionalcalculations_annual_ebitdaMargin": {valueKey: "ebitdaMarginYearISqoreValue", gradeKey: "ebitdaMarginYearGrade"},
    "calculation_3_year_average_ebitdaMargin": {valueKey: "ebitdaMargin3YearAvgISqoreValue", gradeKey: "ebitdaMargin3YearAvgGrade"},
    "calculation_5_year_average_ebitdaMargin": {valueKey: "ebitdaMargin5YearAvgISqoreValue", gradeKey: "ebitdaMargin5YearAvgGrade"},

    "ttm_fcfMargin": {valueKey: "fcfMarginTTMISqoreValue", gradeKey: "fcfMarginTTMGrade"},
    "additionalcalculations_annual_fcfMargin": {valueKey: "fcfMarginYearISqoreValue", gradeKey: "fcfMarginYearGrade"},
    "calculation_3_year_average_fcfMargin": {valueKey: "fcfMargin3YearAvgISqoreValue", gradeKey: "fcfMargin3YearAvgGrade"},
    "calculation_5_year_average_fcfMargin": {valueKey: "fcfMargin5YearAvgISqoreValue", gradeKey: "fcfMargin5YearAvgGrade"},

    "ttm_fcfYield": {valueKey: "fcfYieldTTMISqoreValue", gradeKey: "fcfYieldTTMGrade"},
    "additionalcalculations_annual_fcfYield": {valueKey: "fcfYieldYearISqoreValue", gradeKey: "fcfYieldYearGrade"},
    "calculation_3_year_average_fcfYield": {valueKey: "fcfYield3YearAvgISqoreValue", gradeKey: "fcfYield3YearAvgGrade"},
    "calculation_5_year_average_fcfYield": {valueKey: "fcfYield5YearAvgISqoreValue", gradeKey: "fcfYield5YearAvgGrade"},

    "ttm_assetTurnOver": {valueKey: "assetTurnOverTTMISqoreValue", gradeKey: "assetTurnOverTTMGrade"},
    "additionalcalculations_annual_assetTurnOver": {valueKey: "assetTurnOverYearISqoreValue", gradeKey: "assetTurnOverYearGrade"},
    "calculation_3_year_average_assetTurnOver": {valueKey: "assetTurnOver3YearAvgISqoreValue", gradeKey: "assetTurnOver3YearAvgGrade"},
    "calculation_5_year_average_assetTurnOver": {valueKey: "assetTurnOver5YearAvgISqoreValue", gradeKey: "assetTurnOver5YearAvgGrade"},

    "growth_quarterly_totalRevenueForward": {valueKey: "totalRevenueGrowthEstimateISqoreValue", gradeKey: "totalRevenueGrowthEstimateGrade"},
    "growth_quarterly_ttm_totalRevenue": {valueKey: "revenueGrowthTTMISqoreValue", gradeKey: "revenueGrowthTTMGrade"},
    "growth_quarterly_totalRevenue": {valueKey: "revenueGrowthQOQISqoreValue", gradeKey: "revenueGrowthQOQGrade"},
    "growth_quarterly_yoy_totalRevenue": {valueKey: "revenueGrowthYOYISqoreValue", gradeKey: "revenueGrowthYOYGrade"},
    "growth_yearly_totalRevenue": {valueKey: "revenueGrowthYearISqoreValue", gradeKey: "revenueGrowthYearGrade"},
    "calculation_3_cagr_totalRevenue": {valueKey: "revenueGrowth3YearCAGRISqoreValue", gradeKey: "revenueGrowth3YearCAGRGrade"},
    "calculation_5_cagr_totalRevenue": {valueKey: "revenueGrowth5YearCAGRISqoreValue", gradeKey: "revenueGrowth5YearCAGRGrade"},

    "growth_quarterly_netIncome_income_statementForward": {valueKey: "earningsGrowthEstimateISqoreValue", gradeKey: "earningsGrowthEstimateGrade"},
    "growth_quarterly_ttm_netIncome_income_statement": {valueKey: "earningsGrowthTTMISqoreValue", gradeKey: "earningsGrowthTTMGrade"},
    "growth_quarterly_yoy_netIncome_income_statement": {valueKey: "earningsGrowthYOYISqoreValue", gradeKey: "earningsGrowthYOYGrade"},
    "growth_quarterly_netIncome_income_statement": {valueKey: "earningsGrowthQOQISqoreValue", gradeKey: "earningsGrowthQOQGrade"},
    "growth_yearly_netIncome_income_statement": {valueKey: "earningsGrowthYearISqoreValue", gradeKey: "earningsGrowthYearGrade"},
    "calculation_3_cagr_netIncome_income_statement": {valueKey: "earningsGrowth3YearCAGRISqoreValue", gradeKey: "earningsGrowth3YearCAGRGrade"},
    "calculation_5_cagr_netIncome_income_statement": {valueKey: "earningsGrowth5YearCAGRISqoreValue", gradeKey: "earningsGrowth5YearCAGRGrade"},

    "growth_quarterly_earningsPerShareForward": {valueKey: "earningsPerShareGrowthEstimateISqoreValue", gradeKey: "earningsPerShareGrowthEstimateGrade"},
    "growth_quarterly_ttm_earningsPerShare": {valueKey: "earningsPerShareGrowthTTMISqoreValue", gradeKey: "earningsPerShareGrowthTTMGrade"},
    "growth_quarterly_yoy_earningsPerShare": {valueKey: "earningsPerShareGrowthYOYISqoreValue", gradeKey: "earningsPerShareGrowthYOYGrade"},
    "growth_quarterly_earningsPerShare": {valueKey: "earningsPerShareGrowthQOQISqoreValue", gradeKey: "earningsPerShareGrowthQOQGrade"},
    "growth_yearly_earningsPerShare": {valueKey: "earningsPerShareGrowthYearISqoreValue", gradeKey: "earningsPerShareGrowthYearGrade"},
    "calculation_3_cagr_earningsPerShare": {valueKey: "earningsPerShareGrowth3YearCAGRISqoreValue", gradeKey: "earningsPerShareGrowth3YearCAGRGrade"},
    "calculation_5_cagr_earningsPerShare": {valueKey: "earningsPerShareGrowth5YearCAGRISqoreValue", gradeKey: "earningsPerShareGrowth5YearCAGRGrade"},

    "growth_quarterly_ttm_ebit": {valueKey: "ebitGrowthTTMISqoreValue", gradeKey: "ebitGrowthTTMGrade"},
    "growth_quarterly_yoy_ebit": {valueKey: "ebitGrowthYOYISqoreValue", gradeKey: "ebitGrowthYOYGrade"},
    "growth_quarterly_ebit": {valueKey: "ebitGrowthQOQISqoreValue", gradeKey: "ebitGrowthQOQGrade"},
    "growth_yearly_ebit": {valueKey: "ebitGrowthYearISqoreValue", gradeKey: "ebitGrowthYearGrade"},
    "calculation_3_cagr_ebit": {valueKey: "ebitGrowth3YearCAGRISqoreValue", gradeKey: "ebitGrowth3YearCAGRGrade"},
    "calculation_5_cagr_ebit": {valueKey: "ebitGrowth5YearCAGRISqoreValue", gradeKey: "ebitGrowth5YearCAGRGrade"},

    "growth_quarterly_ttm_ebitda": {valueKey: "ebitdaGrowthTTMISqoreValue", gradeKey: "ebitdaGrowthTTMGrade"},
    "growth_quarterly_yoy_ebitda": {valueKey: "ebitdaGrowthYOYISqoreValue", gradeKey: "ebitdaGrowthYOYGrade"},
    "growth_quarterly_ebitda": {valueKey: "ebitdaGrowthQOQISqoreValue", gradeKey: "ebitdaGrowthQOQGrade"},
    "growth_yearly_ebitda": {valueKey: "ebitdaGrowthYearISqoreValue", gradeKey: "ebitdaGrowthYearGrade"},
    "calculation_3_cagr_ebitda": {valueKey: "ebitdaGrowth3YearCAGRISqoreValue", gradeKey: "ebitdaGrowth3YearCAGRGrade"},
    "calculation_5_cagr_ebitda": {valueKey: "ebitdaGrowth5YearCAGRISqoreValue", gradeKey: "ebitdaGrowth5YearCAGRGrade"},

    "growth_quarterly_ttm_freeCashFlow": {valueKey: "freeCashFlowGrowthTTMISqoreValue", gradeKey: "freeCashFlowGrowthTTMGrade"},
    "growth_quarterly_yoy_freeCashFlow": {valueKey: "freeCashFlowGrowthYOYISqoreValue", gradeKey: "freeCashFlowGrowthYOYGrade"},
    "growth_quarterly_freeCashFlow": {valueKey: "freeCashFlowGrowthQOQISqoreValue", gradeKey: "freeCashFlowGrowthQOQGrade"},
    "growth_yearly_freeCashFlow": {valueKey: "freeCashFlowGrowthYearISqoreValue", gradeKey: "freeCashFlowGrowthYearGrade"},
    "calculation_3_cagr_freeCashFlow": {valueKey: "freeCashFlowGrowth3YearCAGRISqoreValue", gradeKey: "freeCashFlowGrowth3YearCAGRGrade"},
    "calculation_5_cagr_freeCashFlow": {valueKey: "freeCashFlowGrowth5YearCAGRISqoreValue", gradeKey: "freeCashFlowGrowth5YearCAGRGrade"},

    "additionalcalculations_quarter_cashRatio": {valueKey: "cashRatioISqoreValue", gradeKey: "cashRatioGrade"},
    "additionalcalculations_annual_cashRatio": {valueKey: "cashRatioYearISqoreValue", gradeKey: "cashRatioYearGrade"},
    "calculation_3_year_average_cashRatio": {valueKey: "cashRatio3YearAvgISqoreValue", gradeKey: "cashRatio3YearAvgGrade"},
    "calculation_5_year_average_cashRatio": {valueKey: "cashRatio5YearAvgISqoreValue", gradeKey: "cashRatio5YearAvgGrade"},

    "additionalcalculations_quarter_quickRatio": {valueKey: "quickRatioISqoreValue", gradeKey: "quickRatioGrade"},
    "additionalcalculations_annual_quickRatio": {valueKey: "quickRatioYearISqoreValue", gradeKey: "quickRatioYearGrade"},
    "calculation_3_year_average_quickRatio": {valueKey: "quickRatio3YearAvgISqoreValue", gradeKey: "quickRatio3YearAvgGrade"},
    "calculation_5_year_average_quickRatio": {valueKey: "quickRatio5YearAvgISqoreValue", gradeKey: "quickRatio5YearAvgGrade"},

    "additionalcalculations_quarter_currentRatio": {valueKey: "currentRatioISqoreValue", gradeKey: "currentRatioGrade"},
    "additionalcalculations_annual_currentRatio": {valueKey: "currentRatioYearISqoreValue", gradeKey: "currentRatioYearGrade"},
    "calculation_3_year_average_currentRatio": {valueKey: "currentRatio3YearAvgISqoreValue", gradeKey: "currentRatio3YearAvgGrade"},
    "calculation_5_year_average_currentRatio": {valueKey: "currentRatio5YearAvgISqoreValue", gradeKey: "currentRatio5YearAvgGrade"},

    "additionalcalculations_quarter_equityAssets": {valueKey: "equityAssetsISqoreValue", gradeKey: "equityAssetsGrade"},
    "additionalcalculations_annual_equityAssets": {valueKey: "equityAssetsYearISqoreValue", gradeKey: "equityAssetsYearGrade"},
    "calculation_3_year_average_equityAssets": {valueKey: "equityAssets3YearAvgISqoreValue", gradeKey: "equityAssets3YearAvgGrade"},
    "calculation_5_year_average_equityAssets": {valueKey: "equityAssets5YearAvgISqoreValue", gradeKey: "equityAssets5YearAvgGrade"},

    "additionalcalculations_quarter_debtEquity": {valueKey: "debtEquityISqoreValue", gradeKey: "debtEquityGrade"},
    "additionalcalculations_annual_debtEquity": {valueKey: "debtEquityYearISqoreValue", gradeKey: "debtEquityYearGrade"},
    "calculation_3_year_average_debtEquity": {valueKey: "debtEquity3YearAvgISqoreValue", gradeKey: "debtEquity3YearAvgGrade"},
    "calculation_5_year_average_debtEquity": {valueKey: "debtEquity5YearAvgISqoreValue", gradeKey: "debtEquity5YearAvgGrade"},

    "additionalcalculations_quarter_netDebtEquity": {valueKey: "netDebtEquityISqoreValue", gradeKey: "netDebtEquityGrade"},
    "additionalcalculations_annual_netDebtEquity": {valueKey: "netDebtEquityYearISqoreValue", gradeKey: "netDebtEquityYearGrade"},
    "calculation_3_year_average_netDebtEquity": {valueKey: "netDebtEquity3YearAvgISqoreValue", gradeKey: "netDebtEquity3YearAvgGrade"},
    "calculation_5_year_average_netDebtEquity": {valueKey: "netDebtEquity5YearAvgISqoreValue", gradeKey: "netDebtEquity5YearAvgGrade"},

    "additionalcalculations_quarter_debtAsset": {valueKey: "debtAssetISqoreValue", gradeKey: "debtAssetGrade"},
    "additionalcalculations_annual_debtAsset": {valueKey: "debtAssetYearISqoreValue", gradeKey: "debtAssetYearGrade"},
    "calculation_3_year_average_debtAsset": {valueKey: "debtAsset3YearAvgISqoreValue", gradeKey: "debtAsset3YearAvgGrade"},
    "calculation_5_year_average_debtAsset": {valueKey: "debtAsset5YearAvgISqoreValue", gradeKey: "debtAsset5YearAvgGrade"},

    "additionalcalculations_quarter_netDebtAsset": {valueKey: "netDebtAssetISqoreValue", gradeKey: "netDebtAssetGrade"},
    "additionalcalculations_annual_netDebtAsset": {valueKey: "netDebtAssetYearISqoreValue", gradeKey: "netDebtAssetYearGrade"},
    "calculation_3_year_average_netDebtAsset": {valueKey: "netDebtAsset3YearAvgISqoreValue", gradeKey: "netDebtAsset3YearAvgGrade"},
    "calculation_5_year_average_netDebtAsset": {valueKey: "netDebtAsset5YearAvgISqoreValue", gradeKey: "netDebtAsset5YearAvgGrade"},

    "ttm_debtEBIT": {valueKey: "debtEbitTTMISqoreValue", gradeKey: "debtEbitTTMGrade"},
    "additionalcalculations_annual_debtEBIT": {valueKey: "debtEbitYearISqoreValue", gradeKey: "debtEbitYearGrade"},
    "calculation_3_year_average_debtEBIT": {valueKey: "debtEbit3YearAvgISqoreValue", gradeKey: "debtEbit3YearAvgGrade"},
    "calculation_5_year_average_debtEBIT": {valueKey: "debtEbit5YearAvgISqoreValue", gradeKey: "debtEbit5YearAvgGrade"},

    "ttm_netDebtEBIT": {valueKey: "netDebtEbitTTMISqoreValue", gradeKey: "netDebtEbitTTMGrade"},
    "additionalcalculations_annual_netDebtEBIT": {valueKey: "netDebtEbitYearISqoreValue", gradeKey: "netDebtEbitYearGrade"},
    "calculation_3_year_average_netDebtEBIT": {valueKey: "netDebtEbit3YearAvgISqoreValue", gradeKey: "netDebtEbit3YearAvgGrade"},
    "calculation_5_year_average_netDebtEBIT": {valueKey: "netDebtEbit5YearAvgISqoreValue", gradeKey: "netDebtEbit5YearAvgGrade"},

    "ttm_debtEBITDA": {valueKey: "debtEbitdaTTMISqoreValue", gradeKey: "debtEbitdaTTMGrade"},
    "additionalcalculations_annual_debtEBITDA": {valueKey: "debtEbitdaYearISqoreValue", gradeKey: "debtEbitdaYearGrade"},
    "calculation_3_year_average_debtEBITDA": {valueKey: "debtEbitda3YearAvgISqoreValue", gradeKey: "debtEbitda3YearAvgGrade"},
    "calculation_5_year_average_debtEBITDA": {valueKey: "debtEbitda5YearAvgISqoreValue", gradeKey: "debtEbitda5YearAvgGrade"},

    "ttm_netDebtEBITDA": {valueKey: "netDebtEbitdaTTMISqoreValue", gradeKey: "netDebtEbitdaTTMGrade"},
    "additionalcalculations_annual_netDebtEBITDA": {valueKey: "netDebtEbitdaYearISqoreValue", gradeKey: "netDebtEbitdaYearGrade"},
    "calculation_3_year_average_netDebtEBITDA": {valueKey: "netDebtEbitda3YearAvgISqoreValue", gradeKey: "netDebtEbitda3YearAvgGrade"},
    "calculation_5_year_average_netDebtEBITDA": {valueKey: "netDebtEbitda5YearAvgISqoreValue", gradeKey: "netDebtEbitda5YearAvgGrade"},

    "additionalcalculations_quarter_fcfDebt": {valueKey: "fcfDebtISqoreValue", gradeKey: "fcfDebtGrade"},
    "additionalcalculations_annual_fcfDebt": {valueKey: "fcfDebtYearISqoreValue", gradeKey: "fcfDebtYearGrade"},
    "calculation_3_year_average_fcfDebt": {valueKey: "fcfDebt3YearAvgISqoreValue", gradeKey: "fcfDebt3YearAvgGrade"},
    "calculation_5_year_average_fcfDebt": {valueKey: "fcfDebt5YearAvgISqoreValue", gradeKey: "fcfDebt5YearAvgGrade"},

    "additionalcalculations_quarter_cashTotalDebt": {valueKey: "cashTotalDebtISqoreValue", gradeKey: "cashTotalDebtGrade"},
    "additionalcalculations_annual_cashTotalDebt": {valueKey: "cashTotalDebtYearISqoreValue", gradeKey: "cashTotalDebtYearGrade"},
    "calculation_3_year_average_cashTotalDebt": {valueKey: "cashTotalDebt3YearAvgISqoreValue", gradeKey: "cashTotalDebt3YearAvgGrade"},
    "calculation_5_year_average_cashTotalDebt": {valueKey: "cashTotalDebt5YearAvgISqoreValue", gradeKey: "cashTotalDebt5YearAvgGrade"},

    "additionalcalculations_quarter_debtCapital": {valueKey: "debtCapitalISqoreValue", gradeKey: "debtCapitalGrade"},
    "additionalcalculations_annual_debtCapital": {valueKey: "debtCapitalYearISqoreValue", gradeKey: "debtCapitalYearGrade"},
    "calculation_3_year_average_debtCapital": {valueKey: "debtCapital3YearAvgISqoreValue", gradeKey: "debtCapital3YearAvgGrade"},
    "calculation_5_year_average_debtCapital": {valueKey: "debtCapital5YearAvgISqoreValue", gradeKey: "debtCapital5YearAvgGrade"},

    "current_piotroskiFScore": {valueKey: "piotroskiFScoreISqoreValue", gradeKey: "piotroskiFScoreGrade"},
    "additionalcalculations_annual_piotroskiFScore": {valueKey: "piotroskiFScoreYearISqoreValue", gradeKey: "piotroskiFScoreYearGrade"},
    "calculation_3_year_average_piotroskiFScore": {valueKey: "piotroskiFScore3YearAvgISqoreValue", gradeKey: "piotroskiFScore3YearAvgGrade"},
    "calculation_5_year_average_piotroskiFScore": {valueKey: "piotroskiFScore5YearAvgISqoreValue", gradeKey: "piotroskiFScore5YearAvgGrade"},

    "current_altmanZScore": {valueKey: "altmanZScoreCurrentISqoreValue", gradeKey: "altmanZScoreCurrentGrade"},
    "additionalcalculations_quarter_altmanZScore": {valueKey: "altmanZScoreQuarterISqoreValue", gradeKey: "altmanZScoreQuarterGrade"},
    "additionalcalculations_annual_altmanZScore": {valueKey: "altmanZScoreYearISqoreValue", gradeKey: "altmanZScoreYearGrade"},
    "calculation_3_year_average_altmanZScore": {valueKey: "altmanZScore3YearAvgISqoreValue", gradeKey: "altmanZScore3YearAvgGrade"},
    "calculation_5_year_average_altmanZScore": {valueKey: "altmanZScore5YearAvgISqoreValue", gradeKey: "altmanZScore5YearAvgGrade"},
}

export const get_index_body = (body) => {
    return body.map((item) => {
        return {
            name: item[0],
            symbol: item[1],
            price: item[2]['price'],
            change_p: item[2]['changesPercentage'],
            code: item[3].split('.')[0],
            exchange : item[3].split('.')[1],
            type: 'index',
            flag: item[5],
            fmp: item[6]
        }
    })
}
