import React, {useContext} from 'react';
import {useSelector} from "react-redux";
import LoginPaymentBlocker from "./LoginPaymentBlocker";
import {ThemeContext} from "../Context/ThemeContext";

function RestrictedContent({
                               conditionText,
                               blockerLg,
                               loginRequired,
                               subscriptionRequired,
                               darkBlockerImg,
                               lightBlockerImg,
                               imgWidth,
                               content,
                               code = "",
                               exchange = ""
                           }) {

    const allowedCodes = ["AAPL", "IBM", "KO", "DIS"]
    const allowedExchanges = ["US",]

    const {webTheme} = useContext(ThemeContext)

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    return (
        <div className={blockerLg ? 'entire-body-lg' : 'entire-body-sm'}>
            {(
                    !(allowedCodes.includes(code) && allowedExchanges.includes(exchange)) &&
                    ((!user.subscription || user.subscription.package_name === 'Free') || !isAuthenticated)
                ) &&
                <>
                    <LoginPaymentBlocker condition={conditionText}
                                         lg={blockerLg} login={loginRequired} subscription={subscriptionRequired}/>
                    <img src={webTheme === 'dark' ? darkBlockerImg : lightBlockerImg}
                         className={'blocker-img'} style={{width: imgWidth}}/>
                </>
            }

            {((isAuthenticated && user.subscription.package_name !== 'Free') || (allowedCodes.includes(code) && allowedExchanges.includes(exchange))) &&
                content
            }
        </div>
    );
}

export default RestrictedContent;