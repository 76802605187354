import React, {useEffect, useState} from 'react';
import HighlightsTable from "./HighlightsTable";
import axios from "axios";
import InvestorsTable from "./InvestorsTable";
import InvestorsPieChart from "./InvestorsPieChart";
import OwnershipBreakdownChart from "./OwnershipBreakdownChart";
import {return_strong_num} from "../../Utils";

function InstitutionalsTab({code, exchange}) {

    const [investors, setInvestor] = useState([])
    const [loadingInvestors, setLoadingInvestors] = useState(true)
    const [showOwnership, setShowOwnership] = useState(false)

    const [highlights, setHighlights] = useState([])
    const [date, setDate] = useState(null)

    // const [top10, setTop10] = useState(0)

    const [showError, setShowError] = useState(false)

    useEffect(() => {
        setDate(null)
        setShowError(false)
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-investor-institutional/${code}/${exchange}/`)
            .then(response => {
                if (response.data.data.length > 0) {
                    const data = response.data.data[0]
                    setDate(data.date)
                    setHighlights([
                        ['CIK', data.cik],
                        ['# Investors', `${data.investorsHolding} (${data.investorsHoldingChange > 0 ? "+" : ""} ${data.investorsHoldingChange})`],
                        ['Top 10', 0],
                        ['13F Ownership', `${data.ownershipPercent.toFixed(2)} % (${data.ownershipPercentChange > 0 ? "+" : ""} ${data.ownershipPercentChange.toFixed(2)} %)`],
                        ['New Positions', data.newPositions],
                        ['Increased Positions', data.increasedPositions],
                        ['Closed Positions', data.closedPositions],
                        ['Reduced Positions', data.reducedPositions],
                        ['Short % of Float', `${return_strong_num(response.data.short_percentage * 100)} %`]
                    ])
                    setShowError(false)
                } else {
                    setShowError(true)
                }
                setShowOwnership(true)
            })
            .catch(error => console.log(error.message))
    }, [code, exchange])

    useEffect(() => {
        if (date) {
            axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-investors/${code}/${exchange}/${date}/`)
                .then(response => {
                    setInvestor(response.data)
                    setLoadingInvestors(false)
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }, [date])

    const [ownership, setOwnership] = useState({
        PercentInsiders: 0,
        PercentInstitutions: 0
    })

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-ownership-breakdown/${code}/${exchange}/`)
            .then(response => {
                setOwnership(response.data)
            })
            .catch(err => console.log(err.message))

    }, [code, exchange]);

    useEffect(() => {
        if (investors.length > 0) {
            let top10 = 0
            investors.sort((a, b) => b.ownership - a.ownership).slice(0, 10).map(item => top10 += item.ownership)
            setHighlights(highlights.map(item => {
                if (item[0] === 'Top 10') {
                    return ['Top 10', `${top10.toFixed(2)} %`]
                } else {
                    return item
                }
            }))
        }
    }, [investors])

    return (
        <>
            <div className={'row g-0 mb-3'}>
                {!showError &&
                    <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12">
                        <HighlightsTable highlights={highlights} date={date}/>
                    </div>
                }
                {showOwnership &&
                    <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12">
                        <OwnershipBreakdownChart ownership={ownership}/>
                    </div>
                }
                {!showError &&
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                        <InvestorsPieChart investors={investors}/>
                    </div>
                }
            </div>
            {!showError &&
                <InvestorsTable investors={investors} loadingInvestors={loadingInvestors}/>
            }
        </>
    );
}

export default InstitutionalsTab;