import FinancialsTable from "../CompanyTabs/FinancialTabs/FinancialsTable";
import FairValueInfoTabs from "./FairValueInfoTabs";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {useSelector} from "react-redux";
import axios from "axios";
import Config from "../../Config";

const cagr_values = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financials-overview/${code}/${exchange}/annual`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}


function FairValueInfoTable({
    code, exchange,
                                groupedData,
                                expectedCAGRValues, expectedCARG
                            }) {

    const user = useSelector(state => state.actions.user);
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const [cagrValues, setCAGRValues] = useState({
        totalRevenueGrowth3YCARG: null,
        totalRevenueGrowth5YCARG: null,

        netIncomeGrowth3YCARG: null,
        netIncomeGrowth5YCARG: null,

        totalCashFromOperatingActivitiesGrowth3YCARG: null,
        totalCashFromOperatingActivitiesGrowth5YCARG: null,

        freeCashFlowGrowth3YCARG: null,
        freeCashFlowGrowth5YCARG: null,

        profitMarginGrowth3YCARG: null,
        profitMarginGrowth5YCARG: null,

        fcfMarginGrowth3YCARG: null,
        fcfMarginGrowth5YCARG: null
    })

    const {data: cagrValuesReturn, isLoading: isLoadingCAGRValues} = useQuery({
        queryKey: [`company-overview-financials-overview/${code}/${exchange}`, code, exchange],
        queryFn: () => cagr_values(code, exchange),
        staleTime: 5 * 60000,
    })

    useEffect(() => {
        if (!isLoadingCAGRValues && cagrValuesReturn) {
            setCAGRValues({
                totalRevenueGrowth3YCARG: cagrValuesReturn['3YCARG'].totalRevenue,
                netIncomeGrowth3YCARG: cagrValuesReturn['3YCARG'].netIncome,
                totalCashFromOperatingActivitiesGrowth3YCARG: cagrValuesReturn['3YCARG'].totalCashFromOperatingActivities,
                freeCashFlowGrowth3YCARG: cagrValuesReturn['3YCARG'].freeCashFlow,
                profitMarginGrowth3YCARG: cagrValuesReturn['3YAVG'].netMargin,
                fcfMarginGrowth3YCARG: cagrValuesReturn['3YAVG'].fcfMargin,

                totalRevenueGrowth5YCARG: cagrValuesReturn['5YCARG'].totalRevenue,
                netIncomeGrowth5YCARG: cagrValuesReturn['5YCARG'].netIncome,
                totalCashFromOperatingActivitiesGrowth5YCARG: cagrValuesReturn['5YCARG'].totalCashFromOperatingActivities,
                freeCashFlowGrowth5YCARG: cagrValuesReturn['5YCARG'].freeCashFlow,
                profitMarginGrowth5YCARG: cagrValuesReturn['5YAVG'].netMargin,
                fcfMarginGrowth5YCARG: cagrValuesReturn['5YAVG'].fcfMargin,
            })
        }
    }, [isLoadingCAGRValues, cagrValuesReturn])

    return (
        <>
            {/*<FairValueInfoTabs data={cagrValues} expected={expectedCAGRValues} expectedCARG={expectedCARG}/>*/}

            {groupedData &&
                <FinancialsTable
                    highlightHeadings={["Cash & Equivalents", "Total Debt", "ROIC", "P/E", "P/FCF", "P/S", "Debt/Equity", "Debt/Assets"]}
                    groupedData={groupedData}
                    tableClassName={"company-overview-financial-table-field-names-fair-value"}/>
            }

        </>
    );
}

export default FairValueInfoTable;
