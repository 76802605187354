export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const COMMON_STOCK_LOAD_SUCCESS = "COMMON_STOCK_LOAD_SUCCESS";
export const COMMON_STOCK_LOADED_FAILED = "COMMON_STOCK_LOADED_FAILED";

export const ETF_LOAD_SUCCESS = "ETF_LOAD_SUCCESS";
export const ETF_LOADED_FAILED = "ETF_LOADED_FAILED";

export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS"
export const SUBSCRIPTION_FAILED = "SUBSCRIPTION_FAILED"

export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS"
export const LOAD_USER_FAILED = "LOAD_USER_FAILED"

export const REFRESH_TOKEN = "REFRESH_TOKEN"

export const CHANGE_THEME = "CHANGE_THEME"
