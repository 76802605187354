import React, {useContext, useEffect, useState} from 'react';
import FinancialsControls from "../FinancialsControls";
import axios from "axios";
import {useSelector} from "react-redux";

import financialOverviewDark from "../../../../assets/blockers/financial-oveview-dark.png";
import financialOverviewLight from "../../../../assets/blockers/financial-overview-light.png";

import {ThemeContext} from "../../../../Context/ThemeContext";
import FinancialOverviewSummaryTable from "./FinancialOverviewSummaryTable";
import CompanyOverviewCharts from "../../CompanyOverviewCharts";
import RestrictedContent from "../../../RestrictedContent";

function FinancialOverview({code, exchange}) {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    const {webTheme} = useContext(ThemeContext);

    const [active, setActive] = useState('yoy')

    const [meta, setMeta] = useState({curr_symbol: "", currency: ""})

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financial-meta/${code}/${exchange}/`)
            .then(response => {
                setMeta(response.data)
            })
    }, [code, exchange])

    const handleActive = (type) => {
        setActive(type)
    }

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use  this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={financialOverviewDark}
            lightBlockerImg={financialOverviewLight}
            code={code}
            exchange={exchange}

            content={
                <>
                    <div style={{overflow: 'hidden'}}>
                        {/*<FinancialsControls*/}
                        {/*    options={[{*/}
                        {/*        label: "YoY / QoQ",*/}
                        {/*        key: 'yoy'*/}
                        {/*    },*/}
                        {/*        // {label: "Annual", key: "annual"}, {label: "Quarterly", key: 'quarter'}*/}
                        {/*    ]}*/}
                        {/*    handleActive={handleActive} active={active} meta={meta}/>*/}

                        <FinancialOverviewSummaryTable code={code} exchange={exchange} active={active}/>

                        <CompanyOverviewCharts code={code} exchange={exchange}
                                               active={active === 'yoy' ? "quarter" : active}/>
                    </div>
                </>
            }
        />
    );
}

export default FinancialOverview;
