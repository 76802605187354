import clsx from "clsx";
import {BsFacebook, BsSearch, BsTwitter, BsYoutube} from "react-icons/bs";
import React, {useEffect, useState} from "react";
import {Modal} from "@material-ui/core";
import SearchModalBody from "../Container/Navbar/SearchModalBody";
import {FaLinkedinIn, FaTiktok} from "react-icons/fa";
import LandingPageNavbar from "../Container/LandingPage/landing-page-navbar";
import {gtag} from 'ga-gtag';
import {load_user, RefreshToken, VerifyToken} from "../actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";

const LandingPage = () => {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    gtag('event', 'conversion', {'send_to': 'AW-918911974/0MgcCMnIjosYEOb3lbYD'});

    const [searchModal, setSearchModal] = useState(false)

    const handleOpenSearchModal = () => {
        setSearchModal(true)
    }

    const handleCloseSearchModal = () => {
        setSearchModal(false)
    }

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(VerifyToken(navigate))
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(load_user())
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            setInterval(() => {
                dispatch(RefreshToken())
            }, 5 * 60000);
        }
    }, [isAuthenticated]);

    const stocks = [
        {
            code: "AAPL",
            img: "https://ik.imagekit.io/finqube/AAPL.svg",
            alt: "aapl-logo",
            link: "/company-overview/AAPL/US/"
        },
        {
            code: "IBM",
            img: "https://ik.imagekit.io/finqube/IBM.svg",
            alt: "ibm-logo",
            link: "/company-overview/IBM/US/"
        },
        {
            code: "KO",
            img: "https://ik.imagekit.io/finqube/KO.svg",
            alt: "ko-logo",
            link: "/company-overview/KO/US/"
        },
        {
            code: "DIS",
            img: "https://ik.imagekit.io/finqube/DIS.svg",
            alt: "dis-logo",
            link: "/company-overview/DIS/US/"
        },
    ]

    return (
        <>
            <Helmet>
                <title>Finqube.io: Invest Smart, Financial Charts, Analysis and Stock Screener</title>
                <meta name={"description"}
                      content={"Innovative Financial Platform for Screening, Researching and Analyzing Stocks, ETFs & Indices. Our powerful analytical tools will bring stock-picking possibilities to the next level. Finqube.io simplifying financial data for you."}/>
            </Helmet>
            <div className={'landing-page-container'}>

                <div className={'ornament-2'}/>
                <div className={'landing-page'}>
                    <div className="container">

                        <LandingPageNavbar/>

                        <div className={'landing-page-content-container'}>
                            <div className="row landing-page-content-inner-container">

                                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex">
                                    <div style={{margin: 'auto', width: '100%'}}>
                                        <h1 className={'landing-page-text-heading'}>Invest Smart</h1>
                                        <h2 className={'landing-page-text'}>Informed decisions with just a click.</h2>

                                        <div className={'landing-page-search-container'}>
                                            <div
                                                onClick={handleOpenSearchModal}
                                                className={clsx('landing-page-search-bar-container')}>
                                                <p className={'search-bar-container-text'}>Search Here</p>
                                                <BsSearch className={clsx('search-bar-icon')} style={{color: "white"}}/>
                                            </div>
                                            <ul style={{
                                                display: 'flex',
                                                margin: "10px 0 10px 0",
                                                marginLeft: 0,
                                                padding: 0,
                                                listStyleType: "none"
                                            }}>
                                                {stocks.map((stock) => {
                                                    return (
                                                        <li>
                                                            <Link key={stock.code} to={stock.link}
                                                                  style={{textDecoration: "none"}}>
                                                                <div className={'home-page-company-container'}>
                                                                    <img
                                                                        className={"home-page-company-logo"}
                                                                        src={stock.img}
                                                                        alt={stock.alt}
                                                                    />
                                                                    <p className={'home-page-company-label'}>{stock.code}</p>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>

                                        <p className={'landing-page-text'}><b>Finqube.io</b> simplifies financial data
                                            for
                                            you
                                            and
                                            covers over 50,000+ stocks of 50+
                                            exchanges. Save countless hours by checking financial reports, numerous
                                            data,
                                            and ratios.</p>
                                    </div>
                                </div>


                                <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex">
                                    <img
                                        src={"https://finqube-prod.s3.eu-central-1.amazonaws.com/media/Background_Finqube.png"}
                                        alt={"laptop-img"}
                                        className={'landing-page-laptop'}/>
                                </div>

                            </div>
                        </div>

                        <div style={{display: 'flex', margin: '20px 0'}}>
                            <a href={'https://www.facebook.com/finqube'} target={"_blank"}>
                                <BsFacebook className={'socials'}/>
                            </a>
                            <a href={'https://www.linkedin.com/company/finqube'} target={"_blank"}>
                                <FaLinkedinIn className={'socials'}/>
                            </a>
                            <a href={'https://www.youtube.com/@finqube'} target={"_blank"}>
                                <BsYoutube className={'socials'}/>
                            </a>
                            <a href={'https://twitter.com/finqube'} target={"_blank"}>
                                <BsTwitter className={'socials'}/>
                            </a>
                            <a href={'https://www.tiktok.com/@finqube'} target={"_blank"}>
                                <FaTiktok className={'socials'}/>
                            </a>
                        </div>
                        <div className={'landing-page-footer-container'}>
                            <p className={'landing-page-footer-text'}>
                                This site cannot substitute for professional investment advice or independent factual
                                verification. We would like to remind you that the data contained on finqube.io and via
                                API
                                is not necessarily real-time nor accurate. To use our financial platform, you must
                                accept
                                our <b><a className={'landing-page-footer-link'}
                                          href={'https://web.finqube.io/terms-of-use/'} target={'_blank'}>Terms of
                                Use</a></b>,&nbsp;
                                <b><a className={'landing-page-footer-link'}
                                      href={'https://web.finqube.io/privacy-policy/'}
                                      target={'_blank'}>Privacy&nbsp;
                                    Policy</a></b> and our <b><a className={'landing-page-footer-link'}
                                                                 href={'https://web.finqube.io/disclaimer/'}
                                                                 target={'_blank'}>Disclaimer</a></b> |&nbsp;
                                <b><a className={'landing-page-footer-link'}
                                      href={'https://web.finqube.io/legal-notice/'}
                                      target={'_blank'}>Legal
                                    Notice</a></b> of Finqube GmbH |&nbsp;
                                Copyright © 2023
                            </p>

                        </div>

                    </div>

                    <Modal
                        open={searchModal}
                        onClose={handleCloseSearchModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <SearchModalBody handleCloseSearchModal={handleCloseSearchModal}/>
                    </Modal>

                </div>
            </div>
        </>
    );
}

export default LandingPage;