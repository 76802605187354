import React from 'react';
import FinancialOverviewBarTab from "../CompanyTabs/FinancialTabs/FinancialOverview/FinancialOverviewBarTab";

function FairValueInfoTabs({data, expected, expectedCARG, summary = false}) {
    return (
        <div className={'financial-overview-tabs-bar fair-value-tabs-bar'}>
            {!summary &&
                <>
                    <FinancialOverviewBarTab
                        text={"Expected Growth"}
                        fairValue={true}
                        expected={true}
                        year3={expected.expectedTotalRevenueGrowth3YCARG}
                        year5={expected.expectedTotalRevenueGrowth5YCARG}
                        expectedCARG={expectedCARG}
                    />

                    <div className={'financial-overview-bar-tab-separator fair-value-bar-tab-separator'}/>
                </>
            }

            <FinancialOverviewBarTab
                text={"Revenue Growth"}
                fairValue={true}
                year3={data.totalRevenueGrowth3YCARG}
                year5={data.totalRevenueGrowth5YCARG}
                avg={false}
            />

            <FinancialOverviewBarTab
                text={"Profit Growth"}
                fairValue={true}
                year3={data.netIncomeGrowth3YCARG}
                year5={data.netIncomeGrowth5YCARG}
                avg={false}
            />

            <FinancialOverviewBarTab
                text={"FCF Growth"}
                fairValue={true}
                year3={data.freeCashFlowGrowth3YCARG}
                year5={data.freeCashFlowGrowth5YCARG}
                avg={false}
            />

            <div className={'financial-overview-bar-tab-separator fair-value-bar-tab-separator'}/>

            <FinancialOverviewBarTab
                text={"Profit Margin"}
                fairValue={true}
                year3={data.profitMarginGrowth3YCARG}
                year5={data.profitMarginGrowth5YCARG}
                avg={true}
            />

            <FinancialOverviewBarTab
                text={"FCF Margin"}
                fairValue={true}
                year3={data.fcfMarginGrowth3YCARG}
                year5={data.fcfMarginGrowth5YCARG}
                avg={true}
            />

        </div>
    );
}

export default FairValueInfoTabs;