import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {Modal} from "@material-ui/core";
import axios from "axios";
import Config from "../../Config";
import {
    divide,
    formatNumericValue,
    ISqoreDescription,
    numberWithCommas,
    return_float,
    return_float_fixed,
    return_per_div,
    return_strong_num,
    return_td_color,
    return_value_in_million,
    subtract
} from "../Utils";
import building from "../../assets/default-building.png";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import SelectWatchListModal from "../Tabs/IndicesPageTabs/SelectWatchListModal";
import {useSelector} from "react-redux";
import {toast} from "react-hot-toast";
import companyOverviewTopBarDark from "../../assets/blockers/company-overview-topbar-dark.png";
import companyOverviewTopBarLight from "../../assets/blockers/company-overview-topbar-light.png";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import RestrictedContent from "../RestrictedContent";
import {IoIosCheckmarkCircle, IoIosCloseCircle} from "react-icons/io";
import {BsPencilSquare} from "react-icons/bs";
import CompanyNotesModal from "./CompanyNotesModal";


const company_overview_topbar = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-topbar/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const company_overview_topbar_rating = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-topbar-ratings/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const company_overview_fmp_prices = async (fmp_code) => {
    if (fmp_code !== null) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-fmp-prices/${fmp_code}/`, Config());
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

const CompanyDetailsTab = ({code, exchange, setLogoUrl}) => {
    const navigate = useNavigate()

    const [data, setData] = useState({
        LogoURL: "",
        code: "",
        name: "",
        ISIN: "",
        exchange: "",
        sector: "",
        industry: "",
        currency: "",
        watchlist: false
    })

    const [ratingData, setRatingData] = useState({
        tsiScore: 0,
        tsiRank: 0,
        index_code: 0,

        iSqore: 0,
        iSqoreGrade: 0,
        value: 0,
        valueGrade: 0,
        growth: 0,
        growthGrade: 0,
        profitability: 0,
        profitabilityGrade: 0,
        health: 0,
        healthGrade: 0,
        quality: 0,
        qualityGrade: 0,

        sma50: 0,
        sma200: 0,
    })

    const [fmpCode, setFMPCode] = useState(null)

    const [priceData, setPriceData] = useState({
        price: 0,
        change: 0,
        changesPercentage: 0,
        m_cap_trading: 0,
        adjusted_close_date: ""
    })

    const [selectedTicker, setSelectedTicker] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = (item) => {
        setSelectedTicker(
            {
                'Company Name': item.name,
                'company_id': item.id
            })
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const {data: companyOverviewTopbarReturn, isLoading: companyOverviewTopbarIsLoading} = useQuery({
        queryKey: [`company-overview-topbar/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_overview_topbar(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (!companyOverviewTopbarIsLoading) {
            setData({
                ...companyOverviewTopbarReturn,
            })
            setLogoUrl(companyOverviewTopbarReturn['LogoURL'])
            setFMPCode(companyOverviewTopbarReturn['fmp_code'])
            setPriceData({
                price: return_float_fixed(return_float(companyOverviewTopbarReturn['adjusted_close'])),
                change: return_float_fixed(return_float(companyOverviewTopbarReturn['changeInValue'])),
                changesPercentage: return_float_fixed(return_float(companyOverviewTopbarReturn['perChangeInValue'])),
                m_cap_trading: numberWithCommas(return_value_in_million(return_float(companyOverviewTopbarReturn['m_cap_trading']))),
                adjusted_close_date: companyOverviewTopbarReturn['adjusted_close_date']
            })
            if (!companyOverviewTopbarReturn.fmp_code || companyOverviewTopbarReturn.fmp_code === "") {
                setPriceData({
                    price: return_float_fixed(return_float(companyOverviewTopbarReturn['adjusted_close'])),
                    change: return_float_fixed(return_float(companyOverviewTopbarReturn['changeInValue'])),
                    changesPercentage: return_float_fixed(return_float(companyOverviewTopbarReturn['perChangeInValue'])),
                    m_cap_trading: numberWithCommas(return_value_in_million(return_float(companyOverviewTopbarReturn['m_cap_trading']))),
                    adjusted_close_date: companyOverviewTopbarReturn['adjusted_close_date']
                })
            }
        }
    }, [companyOverviewTopbarReturn, companyOverviewTopbarIsLoading])


    const {data: ratingReturn, isLoading: ratingIsLoading} = useQuery({
        queryKey: [`company-overview-topbar-rating/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_overview_topbar_rating(code, exchange),
        staleTime: 5 * 60000
    })

    const [analystRecommendation, setAnalystRecommendation] = useState({
        text: "None",
        color: "white",
        bgColor: "#CACBCD"
    })

    useEffect(() => {
        if (ratingReturn && !ratingIsLoading) {
            let analystRecommendation = "None"

            const buy = ratingReturn?.buy ?? 0

            const hold = ratingReturn?.hold ?? 0

            const sell = ratingReturn?.sell ?? 0

            if (buy > hold && buy > sell) {
                analystRecommendation = 'Bullish'
            } else if (hold > buy && hold > sell) {
                analystRecommendation = 'Neutral'
            } else if (sell > buy && sell > hold) {
                analystRecommendation = 'Bearish'
            } else if (buy === hold) {
                analystRecommendation = 'Bullish'
            } else if (hold === sell) {
                analystRecommendation = 'Neutral'
            } else if (sell === buy) {
                analystRecommendation = 'Neutral'
            }

            setAnalystRecommendation({
                text: analystRecommendation,
                color: analystRecommendation === 'Bullish' ? "#4ebe96" : analystRecommendation === 'Neutral' ? "#babe4e" : analystRecommendation === 'Bearish' ? "#d84f68" : '#FFFFFF',
                bgColor: analystRecommendation === 'Bullish' ? "#4ebe9629" : analystRecommendation === 'Neutral' ? "#babe4e3b" : analystRecommendation === 'Bearish' ? "#d84f6829" : '#89898966',
            })

            setRatingData({
                total: ratingReturn?.total,

                tsiScore: return_float_fixed(return_float(ratingReturn?.companyranks?.trend_score * 100)),
                tsiRank: parseInt(return_strong_num(ratingReturn?.companyranks?.ranking_trend_score)),

                index_code: ratingReturn?.companyranks?.index?.Code,

                iSqore: ratingReturn?.isqore?.iSqore,
                iSqoreGrade: ratingReturn?.isqore?.iSqoreGrade,
                value: ratingReturn?.isqore?.value,
                valueGrade: ratingReturn?.isqore?.valueGrade,
                growth: ratingReturn?.isqore?.growth,
                growthGrade: ratingReturn?.isqore?.growthGrade,
                profitability: ratingReturn?.isqore?.profitability,
                profitabilityGrade: ratingReturn?.isqore?.profitabilityGrade,
                health: ratingReturn?.isqore?.health,
                healthGrade: ratingReturn?.isqore?.healthGrade,
                quality: ratingReturn?.isqore?.quality,
                qualityGrade: ratingReturn?.isqore?.qualityGrade,

                sma50: ratingReturn?.sma50,
                sma200: ratingReturn?.sma200,
            })
        }
    }, [ratingReturn, ratingIsLoading]);

    const [tsiScores, setTSIScores] = useState([])

    const {data: companyOverviewFMPCodeReturn, isLoading: companyOverviewFMPCodeIsLoading} = useQuery({
        queryKey: [`company-overview-fmp-prices/${fmpCode}/`, fmpCode],
        queryFn: () => company_overview_fmp_prices(fmpCode),
        staleTime: 30000
    })

    useEffect(() => {
        if (!companyOverviewFMPCodeIsLoading && companyOverviewFMPCodeReturn) {
            setPriceData({
                price: return_float_fixed(return_float(companyOverviewFMPCodeReturn.price)),
                change: return_float_fixed(return_float(companyOverviewFMPCodeReturn.change)),
                changesPercentage: return_float_fixed(return_float(companyOverviewFMPCodeReturn.changesPercentage)),
                m_cap_trading: numberWithCommas(return_value_in_million(return_float(companyOverviewFMPCodeReturn.marketCap))),
                adjusted_close_date: new Date(companyOverviewFMPCodeReturn.timestamp * 1000).toString().substring(4, 24)
            })

            const avg50d = formatNumericValue({
                value: subtract(divide(companyOverviewFMPCodeReturn.price, companyOverviewFMPCodeReturn.priceAvg50), 1),
                multiply: 100
            })

            const avg200d = formatNumericValue({
                value: subtract(divide(companyOverviewFMPCodeReturn.price, companyOverviewFMPCodeReturn.priceAvg200), 1),
                multiply: 100
            })

            const high52h = formatNumericValue({
                value: subtract(divide(companyOverviewFMPCodeReturn.price, companyOverviewFMPCodeReturn.yearHigh), 1),
                multiply: 100
            })

            setTSIScores([
                {
                    text: "52w High",
                    price: high52h,
                    check: 0 > high52h && high52h >= -15 ? 'success' : -15 > high52h && high52h >= -25 ? 'warning' : 'danger'
                },
                {
                    text: "50d MA",
                    price: avg50d,
                    check: avg50d >= 5 ? 'success' : avg50d < 5 && avg50d >= -5 ? 'warning' : 'danger'
                },
                {
                    text: "200d MA",
                    price: avg200d,
                    check: avg200d >= 10 ? 'success' : avg200d < 10 && avg200d >= -10 ? 'warning' : 'danger'
                },
            ])
        }
    }, [companyOverviewFMPCodeReturn, companyOverviewFMPCodeIsLoading]);

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const save_ticker = (watchLists) => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/save-ticker-bulk/`, {
            company_id: selectedTicker.company_id,
            watchLists: watchLists
        }, config)
            .then(response => {
                setData((prev) => ({...prev, watchlist: response.data['ticker_exists']}))
                toast.success('Watchlist Updated.', {duration: 1000})
                handleCloseModal()
            })
            .catch(err => console.log(err.message))
    }

    const [openNotesModal, setOpenNotesModal] = useState(false);

    const handleOpenNotesModal = () => {
        setOpenNotesModal(true)
    }

    const handleCloseNotesModal = () => {
        setOpenNotesModal(false)
    }

    return (
        <div
            className={clsx('company-detail-tab')}>

            <div className={clsx('company-detail-container')}>

                <div className={'company-detail-name-container'}>

                    <div style={{display: 'flex'}}>
                        <div style={{height: '100%', display: 'flex'}}>
                            <img className={'company-overview-logo'}
                                 src={data.LogoURL ?? building}
                                 onError={({currentTarget}) => {
                                     currentTarget.onerror = null;
                                     currentTarget.src = building;
                                 }}
                            />
                        </div>
                        <div>
                            <p className={'company-overview-name'}>{data.code} - {data.name}</p>
                            <p className={'company-overview-meta'}>ISIN: {data.ISIN}</p>
                            <p className={'company-overview-meta'}>{data.exchange} > {data.sector} > {data.industry}</p>
                        </div>
                    </div>

                    <div className={'company-overview-market-info-container'}>
                        <div style={{display: 'flex'}}>
                            <p className={'adjusted-close'}
                               style={{margin: "auto 5px auto 0"}}>{priceData.price} {data.currency} &nbsp;
                                <span className={'adjusted-close-last-available'}
                                      style={{color: return_td_color(priceData.change)}}>
                                 {priceData.change}
                                </span>
                            </p>
                            {return_per_div(priceData.changesPercentage, 11, 50, "auto")}
                        </div>
                        <p className={'company-overview-mcap-trading'}>MCap
                            (mln): {priceData.m_cap_trading} {data.currency}</p>
                        <div style={{margin: "auto 0"}} onClick={() => {
                            if (!isAuthenticated) {
                                toast.error("Please login to add stocks into watchlist")
                                navigate('/login/')
                                return
                            }
                            handleOpenModal(data)
                        }}>
                            {data.watchlist ? <AiFillStar
                                style={{
                                    color: "#1BA2DA", marginLeft: "10%", fontSize: 15
                                }}/> : <AiOutlineStar
                                style={{
                                    color: "#1BA2DA", marginLeft: "10%", fontSize: 15
                                }}/>}
                        </div>
                        <div style={{margin: "auto 10px"}}>
                            <BsPencilSquare style={{fontSize: 15, cursor: 'pointer'}}
                                            onClick={() => {
                                                if (!isAuthenticated) {
                                                    toast.error("Please login to add notes for stocks")
                                                    navigate('/login/')
                                                    return
                                                }
                                                handleOpenNotesModal(data)
                                            }}
                            />
                        </div>
                    </div>

                    <p className={'company-overview-meta'}>Price as of {priceData['adjusted_close_date']}</p>

                </div>

                <RestrictedContent
                    conditionText={'You must be subscribed to use this feature'}
                    blockerLg={false}
                    loginRequired={true}
                    subscriptionRequired={true}

                    darkBlockerImg={companyOverviewTopBarDark}
                    lightBlockerImg={companyOverviewTopBarLight}
                    imgWidth={800}
                    code={code}
                    exchange={exchange}

                    content={
                        <div style={{
                            position: 'relative', width: '100%', height: '100%',
                            display: 'flex'
                        }}>
                            {/*<div style={{margin: 'auto'}}>*/}
                            {/*    <p className={'analyst-ratings'}>*/}
                            {/*        {ratingData.total} Analyst*/}
                            {/*        Ratings</p>*/}

                            {/*    {Object.entries(analystRatings).map(([key, item]) => {*/}
                            {/*        return (*/}
                            {/*            <div className={'analyst-progress-container'}>*/}
                            {/*                <div className={"progress analyst-progress-bar"}>*/}
                            {/*                    <div className="progress-bar" role="progressbar"*/}
                            {/*                         style={{*/}
                            {/*                             width: `${item.value}%`,*/}
                            {/*                             backgroundColor: item.bgColor*/}
                            {/*                         }}*/}
                            {/*                         aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>*/}
                            {/*                </div>*/}
                            {/*                <p className={'analyst-progress-text'}>*/}
                            {/*                    {item.value}% {item.label}</p>*/}
                            {/*            </div>*/}
                            {/*        )*/}
                            {/*    })}*/}
                            {/*</div>*/}

                            {/*<div className={'company-overview-top-bar-separator'}/>*/}

                            <div className={'company-overview-top-bar-info-container'}>
                                <p className={'company-overview-top-bar-info-text'}
                                   style={{textAlign: 'center'}}>Trend-Strength</p>

                                {tsiScores.map((tsi, index) => {
                                    return (
                                        <div style={{display: 'flex', marginTop: 2}} key={index}>
                                            {tsi.check === 'success' || tsi.check === 'warning' ?
                                                <IoIosCheckmarkCircle
                                                    style={{
                                                        color: tsi.check === 'success' ? '#28c07e' : '#d8be28',
                                                        fontSize: 15, margin: "auto 5px auto 0"
                                                    }}/>
                                                :
                                                <IoIosCloseCircle
                                                    style={{
                                                        color: '#ea165d', fontSize: 15,
                                                        margin: "auto 5px auto 0"
                                                    }}/>
                                            }
                                            <p className={'company-overview-top-bar-info-score'}
                                               style={{marginRight: 25}}>
                                                {tsi.text}</p>
                                            <p className={'company-overview-top-bar-info-score'}
                                               style={{marginLeft: "auto"}}>
                                                {tsi.price}%
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className={'company-overview-top-bar-separator'}/>

                            <div className={'company-overview-top-bar-info-container'}>
                                <p className={'company-overview-top-bar-info-text'}
                                   style={{marginBottom: 25}}>Analysts</p>

                                <div className={'company-overview-analyst-suggestion'}
                                     style={{
                                         backgroundColor: analystRecommendation.bgColor,
                                         color: analystRecommendation.color
                                     }}>
                                    <p>{analystRecommendation.text}</p>
                                </div>
                            </div>

                            <div className={'company-overview-top-bar-separator'}/>

                            <div style={{display: 'flex', margin: 'auto'}}>
                                <ISqoreDescription iSqore={ratingData.iSqore} iSqoreGrade={ratingData.iSqoreGrade}
                                                   value={ratingData.value} valueGrade={ratingData.valueGrade}
                                                   growth={ratingData.growth} growthGrade={ratingData.growthGrade}
                                                   profit={ratingData.profitability}
                                                   profitGrade={ratingData.profitabilityGrade}
                                                   health={ratingData.health} healthGrade={ratingData.healthGrade}
                                                   quality={ratingData.quality} qualityGrade={ratingData.qualityGrade}
                                />
                            </div>
                        </div>
                    }
                />
            </div>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SelectWatchListModal
                    selectedTicker={selectedTicker}
                    save_ticker={save_ticker}
                    handleCloseModal={handleCloseModal}
                    topBar={true}
                />
            </Modal>

            <Modal
                open={openNotesModal}
                onClose={handleCloseNotesModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <CompanyNotesModal
                    name={data?.name}
                    currency={data?.currency}
                    priceData={priceData}
                    code={code}
                    exchange={exchange}
                    handleCloseModal={handleCloseNotesModal}
                />
            </Modal>

        </div>
    );
}

export default CompanyDetailsTab;
