const ScreenerFilterJson = {
    "General": {
        title: "General",
        display: true,
        components: {
            exchanges: {
                key: "exchanges", name: "Exchanges",
                display: true, value: [
                    {
                        flag: "https://flagcdn.com/108x81/us.png",
                        code: "US",
                        country: "United States",
                        name: "United States (US)"
                    }
                ],
                type: "exchange"
            },
            index: {
                key: "index", name: "Indices",
                display: true, value: [],
                type: "index"
            },
            "sector": {key: "sector", name: "Sector", display: true, value: [], type: "sector"},
            "industry": {key: "industry", name: "Industry", display: true, value: [], type: "industry"},
        }
    },
    "Company Information": {
        title: "Company Information",
        display: true,
        components: {
            m_cap_trading: {
                key: "m_cap_trading",
                name: "MCap",
                display: true,
                value: {
                    compare: "greater-than",
                    value1: 1000000000,
                    value2: null,
                    ops: "million-comma",
                    currency: true
                },
                type: "range"
            },
            // country: {
            //     key: "country", name: "Country",
            //     display: false, value: [],
            //     type: "country"
            // },
            sharesOutstanding: {
                key: "sharesOutstanding",
                name: "Shares Out.",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            sharesFloat: {
                key: "sharesFloat",
                name: "Shares Float",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            percentInsiders: {
                key: "percentInsiders",
                name: "Insiders %",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            percentInstitutions: {
                key: "percentInstitutions",
                name: "Institutions %",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            shortPercentFloat: {
                key: "shortPercentFloat",
                name: "Short % Float",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, ops: "multiply"},
                type: "range"
            }
        }
    },
    "Performance": {
        title: "Performance",
        display: false,
        components: {
            // "upsidePotential": {
            //     key: "upsidePotential",
            //     name: "Upside Potential",
            //     display: false,
            //     value: {compare: "greater-than", value1: null, value2: null, type: 'percentage'},
            //     type: "range"
            // },
            "ytd": {
                key: "ytd",
                name: "Performance YTD",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "week": {
                key: "week",
                name: "Performance Week",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "one_month": {
                key: "one_month",
                name: "Performance 1 month",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "three_month": {
                key: "three_month",
                name: "Performance 3 month",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "one_year": {
                key: "one_year",
                name: "Performance 1 year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "three_year": {
                key: "three_year",
                name: "Performance 3 year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "five_year": {
                key: "five_year",
                name: "Performance 5 year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "five_year_cagr": {
                key: "five_year_cagr",
                name: "Performance 5 year CAGR",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "ten_year": {
                key: "ten_year",
                name: "Performance 10 year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
        }
    },

    "Analyst & Trading": {
        title: "Analyst & Trading",
        display: false,
        components: {
            "adjusted_close": {
                key: "adjusted_close",
                name: "Close Price",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true},
                type: "range"
            },
            // "yesterday_adjusted_close": {
            //     key: "yesterday_adjusted_close",
            //     name: "Yesterday's Change %",
            //     display: false,
            //     value: {compare: "greater-than", value1: null, value2: null},
            //     type: "range"
            // },
            "analyst_price_target": {
                key: "analyst_price_target",
                name: "Analyst Price Target",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "upside_potential": {
                key: "upside_potential",
                name: "Analyst Potential",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "number_of_analyst": {
                key: "number_of_analyst",
                name: "# Analyst",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
        }
    },

    "TSI": {
        title: "Trend-Strength-Indicator",
        display: false,
        components: {
            "trend_score": {
                key: "trend_score",
                name: "TSI Score",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "ranking_trend_score": {
                key: "ranking_trend_score",
                name: "TSI Rank",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
        }
    },
    "iSqore": {
        title: "iSqore",
        display: false,
        components: {
            "iSqore": {
                key: "iSqore",
                name: "iSqore Rating",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, ops: "multiply"},
                type: "range"
            },
            "value": {
                key: "value",
                name: "Value",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, ops: "multiply"},
                type: "range"
            },
            "profitability": {
                key: "profitability",
                name: "Profitability",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, ops: "multiply"},
                type: "range"
            },
            "growth": {
                key: "growth",
                name: "Growth",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, ops: "multiply"},
                type: "range"
            },
            "health": {
                key: "health",
                name: "Health",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, ops: "multiply"},
                type: "range"
            },
            "quality": {
                key: "quality",
                name: "Quality",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, ops: "multiply"},
                type: "range"
            },
        }
    },
    "Rule of 40": {
        title: "Rule of 40",
        display: false,
        components: {
            "additionalcalculations_annual_highGrowthRatio": {
                key: "High Growth Ratio",
                name: "High Growth Ratio (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "ttm_highGrowthRatio": {
                key: "ttm_highGrowthRatio",
                name: "High Growth Ratio TTM",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage'}, type: "range"
            },
            "additionalcalculations_annual_highGrowthScore": {
                key: "additionalcalculations_annual_highGrowthScore",
                name: "High Growth Score (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, ops: "multiply"},
                type: "range"
            },
            "ttm_highGrowthScore": {
                key: "ttm_highGrowthScore",
                name: "High Growth Points TTM",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null}, type: "range"
            },
        }
    },

    "Value": {
        title: "Value",
        display: false,
        components: {
            "forwardPE": {
                key: "forwardPE",
                name: "P/Earnings Forward",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "current_priceEarnings": {
                key: "current_priceEarnings",
                name: "P/Earnings Current",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_priceEarnings": {
                key: "additionalcalculations_annual_priceEarnings",
                name: "P/Earnings Year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_priceEarnings": {
                key: "calculation_3_year_average_priceEarnings",
                name: "P/Earnings 3Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_priceEarnings": {
                key: "calculation_5_year_average_priceEarnings",
                name: "P/Earnings 5Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },

            "current_priceSalesForward": {
                key: "current_priceSalesForward",
                name: "P/Sales Forward",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "current_priceSales": {
                key: "current_priceSales",
                name: "P/Sales Current",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_priceSales": {
                key: "additionalcalculations_annual_priceSales",
                name: "P/Sales Year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_priceSales": {
                key: "calculation_3_year_average_priceSales",
                name: "P/Sales 3Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_priceSales": {
                key: "calculation_5_year_average_priceSales",
                name: "P/Sales 5Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "current_priceFreeCashFlow": {
                key: "current_priceFreeCashFlow",
                name: "P/FCF Current",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_priceFreeCashFlow": {
                key: "additionalcalculations_annual_priceFreeCashFlow",
                name: "P/FCF Year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_priceFreeCashFlow": {
                key: "calculation_3_year_average_priceFreeCashFlow",
                name: "P/FCF 3Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_priceFreeCashFlow": {
                key: "calculation_5_year_average_priceFreeCashFlow",
                name: "P/FCF 5Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },

            "current_earningsYieldForward": {
                key: "current_earningsYieldForward",
                name: "Earnings Yield Forward",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "current_earningsYield": {
                key: "current_earningsYield",
                name: "Earnings Yield Current",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_earningsYield": {
                key: "additionalcalculations_annual_earningsYield",
                name: "Earnings Yield Year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_earningsYield": {
                key: "calculation_3_year_average_earningsYield",
                name: "Earnings Yield 3Y Avg",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_earningsYield": {
                key: "calculation_5_year_average_earningsYield",
                name: "Earnings Yield 5Y Avg",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "current_priceBook": {
                key: "current_priceBook",
                name: "P/Book Current",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_priceBook": {
                key: "additionalcalculations_annual_priceBook",
                name: "P/Book Year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_priceBook": {
                key: "calculation_3_year_average_priceBook",
                name: "P/Book 3Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_priceBook": {
                key: "calculation_5_year_average_priceBook",
                name: "P/Book 5Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "current_evRevenue": {
                key: "current_evRevenue",
                name: "EV Revenue Current",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_evRevenue": {
                key: "additionalcalculations_annual_evRevenue",
                name: "EV Revenue Year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_evRevenue": {
                key: "calculation_3_year_average_evRevenue",
                name: "EV Revenue 3Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_evRevenue": {
                key: "calculation_5_year_average_evRevenue",
                name: "EV Revenue 5Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "current_eveBit": {
                key: "current_eveBit",
                name: "EV/EBIT Current",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_eveBit": {
                key: "additionalcalculations_annual_eveBit",
                name: "EV/EBIT Year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_eveBit": {
                key: "calculation_3_year_average_eveBit",
                name: "EV/EBIT 3Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_eveBit": {
                key: "calculation_5_year_average_eveBit",
                name: "EV/EBIT 5Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "current_eveBitda": {
                key: "current_eveBitda",
                name: "EV/EBITDA Current",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_eveBitda": {
                key: "additionalcalculations_annual_eveBitda",
                name: "EV/EBITDA Year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_eveBitda": {
                key: "calculation_3_year_average_eveBitda",
                name: "EV/EBITDA 3Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_eveBitda": {
                key: "calculation_5_year_average_eveBitda",
                name: "EV/EBITDA 5Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "current_pegRatioForward": {
                key: "current_pegRatioForward",
                name: "Peg Ratio Forward",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "current_pegRatio": {
                key: "current_pegRatio",
                name: "Peg Ratio Current",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_pegRatio": {
                key: "additionalcalculations_annual_pegRatio",
                name: "Peg Ratio Year",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_pegRatio": {
                key: "calculation_3_year_average_pegRatio",
                name: "Peg Ratio 3Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_pegRatio": {
                key: "calculation_5_year_average_pegRatio",
                name: "Peg Ratio 5Y AVG",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "ttm_fcfYield": {
                key: "ttm_fcfYield",
                name: "FCF Yield (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_fcfYield": {
                key: "additionalcalculations_annual_fcfYield",
                name: "FCF Yield (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_fcfYield": {
                key: "calculation_3_year_average_fcfYield",
                name: "FCF Yield (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_fcfYield": {
                key: "calculation_5_year_average_fcfYield",
                name: "FCF Yield (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
        }
    },
    "Profit": {
        title: "Profit",
        display: false,
        components: {
            "ttm_returnOnAssets": {
                key: "ttm_returnOnAssets",
                name: "Return On Assets (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_returnOnAssets": {
                key: "additionalcalculations_annual_returnOnAssets",
                name: "Return On Assets (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_returnOnAssets": {
                key: "calculation_3_year_average_returnOnAssets",
                name: "Return On Assets (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_returnOnAssets": {
                key: "calculation_5_year_average_returnOnAssets",
                name: "Return On Assets (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_returnOnEquity": {
                key: "ttm_returnOnEquity",
                name: "Return On Equity (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_returnOnEquity": {
                key: "additionalcalculations_annual_returnOnEquity",
                name: "Return On Equity (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_returnOnEquity": {
                key: "calculation_3_year_average_returnOnEquity",
                name: "Return On Equity (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_returnOnEquity": {
                key: "calculation_5_year_average_returnOnEquity",
                name: "Return On Equity (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_returnOnCapital": {
                key: "ttm_returnOnCapital",
                name: "ROC (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_returnOnCapital": {
                key: "additionalcalculations_annual_returnOnCapital",
                name: "ROC (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_returnOnCapital": {
                key: "calculation_3_year_average_returnOnCapital",
                name: "ROC (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_returnOnCapital": {
                key: "calculation_5_year_average_returnOnCapital",
                name: "ROC (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_returnOnCapitalEmployed": {
                key: "ttm_returnOnCapitalEmployed",
                name: "ROC Employed (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_returnOnCapitalEmployed": {
                key: "additionalcalculations_annual_returnOnCapitalEmployed",
                name: "ROC Employed (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_returnOnCapitalEmployed": {
                key: "calculation_3_year_average_returnOnCapitalEmployed",
                name: "ROC Employed (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_returnOnCapitalEmployed": {
                key: "calculation_5_year_average_returnOnCapitalEmployed",
                name: "ROC Employed (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_returnOnInvestedCapital": {
                key: "ttm_returnOnInvestedCapital",
                name: "Return On Invested Capital (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_returnOnInvestedCapital": {
                key: "additionalcalculations_annual_returnOnInvestedCapital",
                name: "Return On Invested Capital (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_returnOnInvestedCapital": {
                key: "calculation_3_year_average_returnOnInvestedCapital",
                name: "Return On Invested Capital (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_returnOnInvestedCapital": {
                key: "calculation_5_year_average_returnOnInvestedCapital",
                name: "Return On Invested Capital (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_grossMargin": {
                key: "ttm_grossMargin",
                name: "Gross Margin (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_grossMargin": {
                key: "additionalcalculations_annual_grossMargin",
                name: "Gross Margin (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_grossMargin": {
                key: "calculation_3_year_average_grossMargin",
                name: "Gross Margin (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_grossMargin": {
                key: "calculation_5_year_average_grossMargin",
                name: "Gross Margin (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_operatingMargin": {
                key: "ttm_operatingMargin",
                name: "Operating Margin (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_operatingMargin": {
                key: "additionalcalculations_annual_operatingMargin",
                name: "Operating Margin (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_operatingMargin": {
                key: "calculation_3_year_average_operatingMargin",
                name: "Operating Margin (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_operatingMargin": {
                key: "calculation_5_year_average_operatingMargin",
                name: "Operating Margin (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_netMargin": {
                key: "ttm_netMargin",
                name: "Net Margin (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_netMargin": {
                key: "additionalcalculations_annual_netMargin",
                name: "Net Margin (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_netMargin": {
                key: "calculation_3_year_average_netMargin",
                name: "Net Margin (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_netMargin": {
                key: "calculation_5_year_average_netMargin",
                name: "Net Margin (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_ebitMargin": {
                key: "ttm_ebitMargin",
                name: "EBIT Margin (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_ebitMargin": {
                key: "additionalcalculations_annual_ebitMargin",
                name: "EBIT Margin (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_ebitMargin": {
                key: "calculation_3_year_average_ebitMargin",
                name: "EBIT Margin (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_ebitMargin": {
                key: "calculation_5_year_average_ebitMargin",
                name: "EBIT Margin (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_ebitdaMargin": {
                key: "ttm_ebitdaMargin",
                name: "EBITDA Margin (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_ebitdaMargin": {
                key: "additionalcalculations_annual_ebitdaMargin",
                name: "EBITDA Margin (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_ebitdaMargin": {
                key: "calculation_3_year_average_ebitdaMargin",
                name: "EBITDA Margin (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_ebitdaMargin": {
                key: "calculation_5_year_average_ebitdaMargin",
                name: "EBITDA Margin (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_fcfMargin": {
                key: "ttm_fcfMargin",
                name: "FCF Margin (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "additionalcalculations_annual_fcfMargin": {
                key: "additionalcalculations_annual_fcfMargin",
                name: "FCF Margin (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_fcfMargin": {
                key: "calculation_3_year_average_fcfMargin",
                name: "FCF Margin (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_fcfMargin": {
                key: "calculation_5_year_average_fcfMargin",
                name: "FCF Margin (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "ttm_assetTurnOver": {
                key: "ttm_assetTurnOver",
                name: "Asset Turn Over (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_assetTurnOver": {
                key: "additionalcalculations_annual_assetTurnOver",
                name: "Asset Turn Over (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_assetTurnOver": {
                key: "calculation_3_year_average_assetTurnOver",
                name: "Asset Turn Over (3Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_assetTurnOver": {
                key: "calculation_5_year_average_assetTurnOver",
                name: "Asset Turn Over (5Y AVG)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
        }
    },
    "Growth": {
        title: "Growth",
        display: false,
        components: {
            "growth_quarterly_totalRevenueForward": {
                key: "growth_quarterly_totalRevenueForward",
                name: "Revenue Growth Forward",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_ttm_totalRevenue": {
                key: "growth_quarterly_ttm_totalRevenue",
                name: "Revenue Growth (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_totalRevenue": {
                key: "growth_quarterly_totalRevenue",
                name: "Revenue Growth (QoQ)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_yoy_totalRevenue": {
                key: "growth_quarterly_yoy_totalRevenue",
                name: "Revenue Growth (YoY)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_yearly_totalRevenue": {
                key: "growth_yearly_totalRevenue",
                name: "Revenue Growth (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_cagr_totalRevenue": {
                key: "calculation_3_cagr_totalRevenue",
                name: "Revenue (3Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_cagr_totalRevenue": {
                key: "calculation_5_cagr_totalRevenue",
                name: "Revenue (5Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "growth_quarterly_netIncome_income_statementForward": {
                key: "growth_quarterly_netIncome_income_statementForward",
                name: "Net Income Growth Forward",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_ttm_netIncome_income_statement": {
                key: "growth_quarterly_ttm_netIncome_income_statement",
                name: "Net Income Growth (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_netIncome_income_statement": {
                key: "growth_quarterly_netIncome_income_statement",
                name: "Net Income Growth (QoQ)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_yoy_netIncome_income_statement": {
                key: "growth_quarterly_yoy_netIncome_income_statement",
                name: "Net Income Growth (YoY)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_yearly_netIncome_income_statement": {
                key: "growth_yearly_netIncome_income_statement",
                name: "Net Income Growth (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_cagr_netIncome_income_statement": {
                key: "calculation_3_cagr_netIncome_income_statement",
                name: "Net Income (3Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_cagr_netIncome_income_statement": {
                key: "calculation_5_cagr_netIncome_income_statement",
                name: "Net Income (5Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },


            "growth_quarterly_earningsPerShareForward": {
                key: "growth_quarterly_earningsPerShareForward",
                name: "Earnings Per Share Growth Forward",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_ttm_earningsPerShare": {
                key: "growth_quarterly_ttm_earningsPerShare",
                name: "Earnings Per Share Growth (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_earningsPerShare": {
                key: "growth_quarterly_earningsPerShare",
                name: "Earnings Per Share Growth (QoQ)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_yoy_earningsPerShare": {
                key: "growth_quarterly_yoy_earningsPerShare",
                name: "Earnings Per Share Growth (YoY)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_yearly_earningsPerShare": {
                key: "growth_yearly_earningsPerShare",
                name: "Earnings Per Share Growth (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_cagr_earningsPerShare": {
                key: "calculation_3_cagr_earningsPerShare",
                name: "Earnings Per Share (3Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_cagr_earningsPerShare": {
                key: "calculation_5_cagr_earningsPerShare",
                name: "Earnings Per Share (5Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },


            "growth_quarterly_ttm_ebit": {
                key: "growth_quarterly_ttm_ebit",
                name: "EBIT Growth (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_ebit": {
                key: "growth_quarterly_ebit",
                name: "EBIT Growth (QoQ)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_yoy_ebit": {
                key: "growth_quarterly_yoy_ebit",
                name: "EBIT Growth (YoY)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_yearly_ebit": {
                key: "growth_yearly_ebit",
                name: "EBIT Growth (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_cagr_ebit": {
                key: "calculation_3_cagr_ebit",
                name: "EBIT (3Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_cagr_ebit": {
                key: "calculation_5_cagr_ebit",
                name: "EBIT (5Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "growth_quarterly_ttm_ebitda": {
                key: "growth_quarterly_ttm_ebitda",
                name: "EBITDA Growth (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_ebitda": {
                key: "growth_quarterly_ebitda",
                name: "EBITDA Growth (QoQ)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_yoy_ebitda": {
                key: "growth_quarterly_yoy_ebitda",
                name: "EBITDA Growth (YoY)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_yearly_ebitda": {
                key: "growth_yearly_ebitda",
                name: "EBITDA Growth (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_cagr_ebitda": {
                key: "calculation_3_cagr_ebitda",
                name: "EBITDA (3Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_cagr_ebitda": {
                key: "calculation_5_cagr_ebitda",
                name: "EBITDA (5Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },


            "growth_quarterly_ttm_freeCashFlow": {
                key: "growth_quarterly_ttm_freeCashFlow",
                name: "FCF Growth (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_freeCashFlow": {
                key: "growth_quarterly_freeCashFlow",
                name: "FCF Growth (QoQ)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_yoy_freeCashFlow": {
                key: "growth_quarterly_yoy_freeCashFlow",
                name: "FCF Growth (YoY)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_yearly_freeCashFlow": {
                key: "growth_yearly_freeCashFlow",
                name: "FCF Growth (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_cagr_freeCashFlow": {
                key: "calculation_3_cagr_freeCashFlow",
                name: "FCF (3Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_cagr_freeCashFlow": {
                key: "calculation_5_cagr_freeCashFlow",
                name: "FCF (5Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },


            "growth_quarterly_ttm_capitalExpenditures": {
                key: "growth_quarterly_ttm_capitalExpenditures",
                name: "Capital Expenditures Growth (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_capitalExpenditures": {
                key: "growth_quarterly_capitalExpenditures",
                name: "Capital Expenditures Growth (QoQ)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_yoy_capitalExpenditures": {
                key: "growth_quarterly_yoy_capitalExpenditures",
                name: "Capital Expenditures Growth (YoY)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_yearly_capitalExpenditures": {
                key: "growth_yearly_capitalExpenditures",
                name: "Capital Expenditures Growth (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_cagr_capitalExpenditures": {
                key: "calculation_3_cagr_capitalExpenditures",
                name: "Capital Expenditures (3Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_cagr_capitalExpenditures": {
                key: "calculation_5_cagr_capitalExpenditures",
                name: "Capital Expenditures (5Y CAGR)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "growth_quarterly_cashAndShortTermInvestments": {
                key: "growth_quarterly_cashAndShortTermInvestments",
                name: "Cash and Short Term Investments Growth (QoQ)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_yoy_cashAndShortTermInvestments": {
                key: "growth_quarterly_yoy_cashAndShortTermInvestments",
                name: "Cash And Short Term Investments Growth (YoY)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_yearly_cashAndShortTermInvestments": {
                key: "growth_yearly_cashAndShortTermInvestments",
                name: "Cash And Short Term Investments Growth (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_cashAndShortTermInvestments": {
                key: "calculation_3_year_average_cashAndShortTermInvestments",
                name: "Cash And Short Term Investments (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_cashAndShortTermInvestments": {
                key: "calculation_5_year_average_cashAndShortTermInvestments",
                name: "Cash and Short Term Investments (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "growth_quarterly_totalCash": {
                key: "growth_quarterly_totalCash",
                name: "Total Cash Growth (QoQ)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_yoy_totalCash": {
                key: "growth_quarterly_yoy_totalCash",
                name: "Total Cash Growth (YoY)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_yearly_totalCash": {
                key: "growth_yearly_totalCash",
                name: "Total Cash Growth (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_totalCash": {
                key: "calculation_3_year_average_totalCash",
                name: "Total Cash (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_totalCash": {
                key: "calculation_5_year_average_totalCash",
                name: "Total Cash (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },

            "growth_quarterly_totalDebt": {
                key: "growth_quarterly_totalDebt",
                name: "Total Debt Growth (QoQ)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_quarterly_yoy_totalDebt": {
                key: "growth_quarterly_yoy_totalDebt",
                name: "Total Debt Growth (YoY)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "growth_yearly_totalDebt": {
                key: "growth_yearly_totalDebt",
                name: "Total Debt Growth (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_3_year_average_totalDebt": {
                key: "calculation_3_year_average_totalDebt",
                name: "Total Debt (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_totalDebt": {
                key: "calculation_3_year_average_totalDebt",
                name: "Total Debt (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
        }
    },
    "Health": {
        title: "Health",
        display: false,
        components: {
            "additionalcalculations_quarter_cashRatio": {
                key: "additionalcalculations_quarter_cashRatio",
                name: "Cash Ratio (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_cashRatio": {
                key: "additionalcalculations_annual_cashRatio",
                name: "Cash Ratio (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_cashRatio": {
                key: "calculation_3_year_average_cashRatio",
                name: "Cash Ratio (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_cashRatio": {
                key: "calculation_5_year_average_cashRatio",
                name: "Cash Ratio (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "additionalcalculations_quarter_quickRatio": {
                key: "additionalcalculations_quarter_quickRatio",
                name: "Quick Ratio (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_quickRatio": {
                key: "additionalcalculations_annual_quickRatio",
                name: "Quick Ratio (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_quickRatio": {
                key: "calculation_3_year_average_quickRatio",
                name: "Quick Ratio (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_quickRatio": {
                key: "calculation_5_year_average_quickRatio",
                name: "Quick Ratio (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "additionalcalculations_quarter_currentRatio": {
                key: "additionalcalculations_quarter_currentRatio",
                name: "Current Ratio (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_currentRatio": {
                key: "additionalcalculations_annual_currentRatio",
                name: "Current Ratio (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_currentRatio": {
                key: "calculation_3_year_average_currentRatio",
                name: "Current Ratio (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_currentRatio": {
                key: "calculation_5_year_average_currentRatio",
                name: "Current Ratio (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "additionalcalculations_quarter_equityAssets": {
                key: "additionalcalculations_quarter_equityAssets",
                name: "Equity Assets (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_equityAssets": {
                key: "additionalcalculations_annual_equityAssets",
                name: "Equity Assets (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_equityAssets": {
                key: "calculation_3_year_average_equityAssets",
                name: "Equity Assets (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_equityAssets": {
                key: "calculation_5_year_average_equityAssets",
                name: "Equity Assets (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "additionalcalculations_quarter_debtEquity": {
                key: "additionalcalculations_quarter_debtEquity",
                name: "Debt Equity (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_debtEquity": {
                key: "additionalcalculations_annual_debtEquity",
                name: "Debt Equity (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_debtEquity": {
                key: "calculation_3_year_average_debtEquity",
                name: "Debt Equity (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_debtEquity": {
                key: "calculation_5_year_average_debtEquity",
                name: "Debt Equity (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "additionalcalculations_quarter_netDebtEquity": {
                key: "additionalcalculations_quarter_netDebtEquity",
                name: "Net Debt Equity (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_netDebtEquity": {
                key: "additionalcalculations_annual_netDebtEquity",
                name: "Net Debt Equity (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_netDebtEquity": {
                key: "calculation_3_year_average_netDebtEquity",
                name: "Net Debt Equity (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_netDebtEquity": {
                key: "calculation_5_year_average_netDebtEquity",
                name: "Net Debt Equity (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "additionalcalculations_quarter_debtAsset": {
                key: "additionalcalculations_quarter_debtAsset",
                name: "Debt Asset (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_debtAsset": {
                key: "additionalcalculations_annual_debtAsset",
                name: "Debt Asset (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_debtAsset": {
                key: "calculation_3_year_average_debtAsset",
                name: "Debt Asset (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_debtAsset": {
                key: "calculation_5_year_average_debtAsset",
                name: "Debt Asset (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "additionalcalculations_quarter_netDebtAsset": {
                key: "additionalcalculations_quarter_netDebtAsset",
                name: "Net Debt Asset (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_netDebtAsset": {
                key: "additionalcalculations_annual_netDebtAsset",
                name: "Net Debt Asset (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_netDebtAsset": {
                key: "calculation_3_year_average_netDebtAsset",
                name: "Net Debt Asset (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_netDebtAsset": {
                key: "calculation_5_year_average_netDebtAsset",
                name: "Net Debt Asset (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "ttm_debtEBIT": {
                key: "ttm_debtEBIT",
                name: "Debt EBIT (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_debtEBIT": {
                key: "additionalcalculations_annual_debtEBIT",
                name: "Debt EBIT (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_debtEBIT": {
                key: "calculation_3_year_average_debtEBIT",
                name: "Debt EBIT (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_debtEBIT": {
                key: "calculation_5_year_average_debtEBIT",
                name: "Debt EBIT (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "ttm_netDebtEBIT": {
                key: "ttm_netDebtEBIT",
                name: "Net Debt EBIT (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_netDebtEBIT": {
                key: "additionalcalculations_annual_netDebtEBIT",
                name: "Net Debt EBIT (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_netDebtEBIT": {
                key: "calculation_3_year_average_netDebtEBIT",
                name: "Net Debt EBIT (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_netDebtEBIT": {
                key: "calculation_5_year_average_netDebtEBIT",
                name: "Net Debt EBIT (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "ttm_debtEBITDA": {
                key: "ttm_debtEBITDA",
                name: "Debt EBITDA (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_debtEBITDA": {
                key: "additionalcalculations_annual_debtEBITDA",
                name: "Debt EBITDA (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_debtEBITDA": {
                key: "calculation_3_year_average_debtEBITDA",
                name: "Debt EBITDA (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_debtEBITDA": {
                key: "calculation_5_year_average_debtEBITDA",
                name: "Debt EBITDA (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "ttm_netDebtEBITDA": {
                key: "ttm_netDebtEBITDA",
                name: "Net Debt EBITDA (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_netDebtEBITDA": {
                key: "additionalcalculations_annual_netDebtEBITDA",
                name: "Net Debt EBITDA (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_netDebtEBITDA": {
                key: "calculation_3_year_average_netDebtEBITDA",
                name: "Net Debt EBITDA (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_netDebtEBITDA": {
                key: "calculation_5_year_average_netDebtEBITDA",
                name: "Net Debt EBITDA (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "additionalcalculations_quarter_fcfDebt": {
                key: "additionalcalculations_quarter_fcfDebt",
                name: "FCF/Debt (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_fcfDebt": {
                key: "additionalcalculations_annual_fcfDebt",
                name: "FCF/Debt (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_fcfDebt": {
                key: "calculation_3_year_average_fcfDebt",
                name: "FCF/Debt (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_fcfDebt": {
                key: "calculation_5_year_average_fcfDebt",
                name: "FCF/Debt (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "additionalcalculations_quarter_cashTotalDebt": {
                key: "additionalcalculations_quarter_cashTotalDebt",
                name: "Cash Total Debt (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_cashTotalDebt": {
                key: "additionalcalculations_annual_cashTotalDebt",
                name: "Cash Total Debt (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_cashTotalDebt": {
                key: "calculation_3_year_average_cashTotalDebt",
                name: "Cash Total Debt (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "calculation_5_year_average_cashTotalDebt": {
                key: "calculation_5_year_average_cashTotalDebt",
                name: "Cash Total Debt (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },


            "additionalcalculations_quarter_debtCapital": {
                key: "additionalcalculations_quarter_debtCapital",
                name: "Debt Capital (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_debtCapital": {
                key: "additionalcalculations_annual_debtCapital",
                name: "Debt Capital (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_debtCapital": {
                key: "calculation_3_year_average_debtCapital",
                name: "Debt Capital (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_debtCapital": {
                key: "calculation_5_year_average_debtCapital",
                name: "Debt Capital (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "current_piotroskiFScore": {
                key: "current_piotroskiFScore",
                name: "Piotroski F-Score Current",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_quarter_piotroskiFScore": {
                key: "additionalcalculations_quarter_piotroskiFScore",
                name: "Piotroski F-Score (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_piotroskiFScore": {
                key: "additionalcalculations_annual_piotroskiFScore",
                name: "Piotroski F-Score (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_piotroskiFScore": {
                key: "calculation_3_year_average_piotroskiFScore",
                name: "Piotroski F-Score (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_piotroskiFScore": {
                key: "calculation_5_year_average_piotroskiFScore",
                name: "Piotroski F-Score (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },


            "current_altmanZScore": {
                key: "current_altmanZScore",
                name: "Altman-ZScore (Curr.)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_quarter_altmanZScore": {
                key: "additionalcalculations_quarter_altmanZScore",
                name: "Altman-ZScore (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "additionalcalculations_annual_altmanZScore": {
                key: "additionalcalculations_annual_altmanZScore",
                name: "Altman-ZScore (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_3_year_average_altmanZScore": {
                key: "calculation_3_year_average_altmanZScore",
                name: "Altman-ZScore (3Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
            "calculation_5_year_average_altmanZScore": {
                key: "calculation_5_year_average_altmanZScore",
                name: "Altman-ZScore (5Y Avg)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null},
                type: "range"
            },
        }
    },
    "Dividend": {
        title: "Dividend",
        display: false,
        components: {
            "payoutRatio": {
                key: "payoutRatio",
                name: "Payout Ratio",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            },
            "forwardAnnualDividendRate": {
                key: "forwardAnnualDividendRate",
                name: "Dividend Rate",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true},
                type: "range"
            },
            "forwardAnnualDividendYield": {
                key: "forwardAnnualDividendYield",
                name: "Dividend Yield",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, type: 'percentage', ops: "multiply"},
                type: "range"
            }
        }
    },
    "Financials": {
        title: "Financials",
        display: false,
        components: {

            "ttm_totalRevenue": {
                key: "ttm_totalRevenue",
                name: "Total Revenue (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_quarter_totalRevenue": {
                key: "financialsincomestatement_quarter_totalRevenue",
                name: "Total Revenue (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_annual_totalRevenue": {
                key: "financialsincomestatement_annual_totalRevenue",
                name: "Total Revenue (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "ttm_grossProfit": {
                key: "ttm_grossProfit",
                name: "Gross Profit (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_quarter_grossProfit": {
                key: "financialsincomestatement_quarter_grossProfit",
                name: "Gross Profit (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_annual_grossProfit": {
                key: "financialsincomestatement_annual_grossProfit",
                name: "Gross Profit (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "ttm_operatingIncome": {
                key: "ttm_operatingIncome",
                name: "Operating Income (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_quarter_operatingIncome": {
                key: "financialsincomestatement_quarter_operatingIncome",
                name: "Operating Income (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_annual_operatingIncome": {
                key: "financialsincomestatement_annual_operatingIncome",
                name: "Operating Income (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "ttm_netIncome_income_statement": {
                key: "ttm_netIncome_income_statement",
                name: "Net Income (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_quarter_netIncome": {
                key: "financialsincomestatement_quarter_netIncome",
                name: "Net Income (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_annual_netIncome": {
                key: "financialsincomestatement_annual_netIncome",
                name: "Net Income (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "ttm_ebit": {
                key: "ttm_ebit",
                name: "EBIT (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_quarter_ebit": {
                key: "financialsincomestatement_quarter_ebit",
                name: "EBIT (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_annual_ebit": {
                key: "financialsincomestatement_annual_ebit",
                name: "EBIT (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "ttm_ebitda": {
                key: "ttm_ebitda",
                name: "EBITDA (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_quarter_ebitda": {
                key: "financialsincomestatement_quarter_ebitda",
                name: "EBITDA (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_annual_ebitda": {
                key: "financialsincomestatement_annual_ebitda",
                name: "EBITDA (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "ttm_capitalExpenditures": {
                key: "ttm_capitalExpenditures",
                name: "Cap Ex (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialscashflow_quarter_capitalExpenditures": {
                key: "financialscashflow_quarter_capitalExpenditures",
                name: "Cap Ex (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialscashflow_annual_capitalExpenditures": {
                key: "financialscashflow_annual_capitalExpenditures",
                name: "Cap Ex (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "ttm_freeCashFlow": {
                key: "ttm_freeCashFlow",
                name: "Free Cash Flow (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialscashflow_quarter_freeCashFlow": {
                key: "financialscashflow_quarter_freeCashFlow",
                name: "Free Cash Flow (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialscashflow_annual_freeCashFlow": {
                key: "financialscashflow_annual_freeCashFlow",
                name: "Free Cash Flow (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "financialsbalancesheets_quarter_cashAndShortTermInvestments": {
                key: "financialsbalancesheets_quarter_cashAndShortTermInvestments",
                name: "Cash And Short Term Investment (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsbalancesheets_annual_cashAndShortTermInvestments": {
                key: "financialsbalancesheets_annual_cashAndShortTermInvestments",
                name: "Cash And Short Term Investment (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "financialsbalancesheets_quarter_totalCash": {
                key: "financialsbalancesheets_quarter_totalCash",
                name: "Total Cash (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsbalancesheets_annual_totalCash": {
                key: "financialsbalancesheets_annual_totalCash",
                name: "Total Cash (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "financialsbalancesheets_quarter_totalDebt": {
                key: "financialsbalancesheets_quarter_totalDebt",
                name: "Total Debt (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsbalancesheets_annual_totalDebt": {
                key: "financialsbalancesheets_annual_totalDebt",
                name: "Total Debt (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "financialsbalancesheets_quarter_netDebt": {
                key: "financialsbalancesheets_quarter_netDebt",
                name: "Net Debt (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsbalancesheets_annual_netDebt": {
                key: "financialsbalancesheets_annual_netDebt",
                name: "Net Debt (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "ttm_researchDevelopment": {
                key: "ttm_researchDevelopment",
                name: "Research Development (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_quarter_researchDevelopment": {
                key: "financialsincomestatement_quarter_researchDevelopment",
                name: "Research Development (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsincomestatement_annual_researchDevelopment": {
                key: "financialsincomestatement_annual_researchDevelopment",
                name: "Research Development (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "ttm_totalCashFromOperatingActivities": {
                key: "ttm_totalCashFromOperatingActivities",
                name: "Total Cash From Operating Activities (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialscashflow_quarter_totalCashFromOperatingActivities": {
                key: "financialscashflow_quarter_totalCashFromOperatingActivities",
                name: "Total Cash From Operating Activities (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialscashflow_annual_totalCashFromOperatingActivities": {
                key: "financialscashflow_annual_totalCashFromOperatingActivities",
                name: "Total Cash From Operating Activities (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "ttm_depreciation": {
                key: "ttm_depreciation",
                name: "Depreciation (TTM)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialscashflow_quarter_depreciation": {
                key: "financialscashflow_quarter_depreciation",
                name: "Depreciation (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialscashflow_annual_depreciation": {
                key: "financialscashflow_annual_depreciation",
                name: "Depreciation (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "financialsbalancesheets_quarter_cash": {
                key: "financialsbalancesheets_quarter_cash",
                name: "Cash (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsbalancesheets_annual_cash": {
                key: "financialsbalancesheets_annual_cash",
                name: "Cash (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "financialsbalancesheets_quarter_totalAssets": {
                key: "financialsbalancesheets_quarter_totalAssets",
                name: "Total Assets (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsbalancesheets_annual_totalAssets": {
                key: "financialsbalancesheets_annual_totalAssets",
                name: "Total Assets (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "financialsbalancesheets_quarter_totalLiab": {
                key: "financialsbalancesheets_quarter_totalLiab",
                name: "Total Liab (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsbalancesheets_annual_totalLiab": {
                key: "financialsbalancesheets_annual_totalLiab",
                name: "Total Liab (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },


            "financialsbalancesheets_quarter_totalStockholderEquity": {
                key: "financialsbalancesheets_quarter_totalStockholderEquity",
                name: "Total Stock Holder Equity (Quarter)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            },
            "financialsbalancesheets_annual_totalStockholderEquity": {
                key: "financialsbalancesheets_annual_totalStockholderEquity",
                name: "Total Stock Holder Equity (Year)",
                display: false,
                value: {compare: "greater-than", value1: null, value2: null, currency: true, ops: "million-comma"},
                type: "range"
            }
        }
    },
};

export default ScreenerFilterJson;
