import React, {useEffect, useState} from 'react';
import FairValueInfoTable from "../ValuationTabs/FairValueInfoTable";
import axios from "axios";
import Config from "../../Config";
import {numberWithCommas, return_strong_num, return_value_in_million} from "../Utils";
import FairValueInputTable from "./FairValueInputTable";
import fairValueDark from "../../assets/blockers/fair-value-dark.png";
import fairValueLight from "../../assets/blockers/fair-value-light.png";
import RestrictedContent from "../RestrictedContent";

function FairValueTabs({code, exchange}) {

    const [groupedData, setGroupedData] = useState(null)
    const [groupDataLoaded, setGroupDataLoaded] = useState(false)

    const [netIncomeFutureValues, setNetIncomeFutureValues] = useState([])
    const [FCFFutureValues, setFCFFutureValues] = useState([])
    const [futureValuesLoaded, setFutureValuesLoaded] = useState(false)


    const [expectedCAGRValues, setExpectedCAGRValues] = useState({
        expectedTotalRevenueGrowth3YCARG: null,
        expectedTotalRevenueGrowth5YCARG: null,
    })

    const [expectedCARG, setExpectedCARG] = useState(null)

    useEffect(() => {
        async function fetchFairValueFinancialOutlook() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/fair-value-financial-outlook/${code}/${exchange}/annual/`, Config());
                const fairValueFinancialOutlook = response.data;

                let data = {
                    dates: [],
                    content: {
                        financials: {
                            name: `Financials (${fairValueFinancialOutlook['trading_curr']})`,
                            data: {
                                'Total Revenue': [],
                                '% Growth Yearly': [],

                                'Net Income': [],
                                '% Profit Margin': [],

                                'Free Cash Flow': [],
                                '% FCF Margin': [],

                                'Cash & Equivalents': [],
                                'Total Debt': []
                            }
                        },
                        ratios: {
                            name: 'Ratios',
                            data: {
                                // 'EPS Non-GAAP': [],
                                'ROIC': [],
                                'P/E': [],
                                'P/FCF': [],
                                'P/S': [],
                                'Debt/Equity': [],
                                'Debt/Assets': [],
                            }
                        }
                    }
                }

                fairValueFinancialOutlook.financial.map((item, index) => {
                    data['dates'].push(item.date)

                    data['content']['financials']['data'][`Total Revenue`].push(numberWithCommas(return_value_in_million(return_strong_num(item['value']['totalRevenue']))))
                    data['content']['financials']['data'][`% Growth Yearly`].push(return_strong_num(item['growth']['totalRevenue'] * 100))

                    data['content']['financials']['data'][`Net Income`].push(numberWithCommas(return_value_in_million(return_strong_num(item['value']['netIncome']))))
                    data['content']['financials']['data'][`% Profit Margin`].push(return_strong_num(item['growth']['netIncome'] * 100))

                    data['content']['financials']['data']['Free Cash Flow'].push(numberWithCommas(return_value_in_million(return_strong_num(item['value']['freeCashFlow']))))
                    data['content']['financials']['data']['% FCF Margin'].push(return_strong_num(item['growth']['freeCashFlow'] * 100))

                    data['content']['financials']['data'][`Cash & Equivalents`].push(numberWithCommas(return_value_in_million(return_strong_num(item['value']['cashAndShortTermInvestments']))))
                    data['content']['financials']['data'][`Total Debt`].push(numberWithCommas(return_value_in_million(return_strong_num(item['value']['totalDebt']))))

                    data['content']['ratios']['data'][`ROIC`].push(`${return_strong_num(item['value']['returnOnInvestedCapital'] * 100)}`)

                    data['content']['ratios']['data'][`P/E`].push(`${return_strong_num(item['value']['priceEarnings'])}`)
                    data['content']['ratios']['data'][`P/FCF`].push(`${return_strong_num(item['value']['priceFreeCashFlow'])}`)
                    data['content']['ratios']['data'][`P/S`].push(`${return_strong_num(item['value']['priceSales'])}`)

                    data['content']['ratios']['data'][`Debt/Equity`].push(`${return_strong_num(item['value']['debtEquity'])}`)
                    data['content']['ratios']['data'][`Debt/Assets`].push(`${return_strong_num(item['value']['debtAsset'])}`)

                })
                setGroupedData(data)

                setExpectedCAGRValues({
                    expectedTotalRevenueGrowth3YCARG: fairValueFinancialOutlook['expectedTotalRevenueGrowth3YCARG'],
                    expectedTotalRevenueGrowth5YCARG: fairValueFinancialOutlook['expectedTotalRevenueGrowth5YCARG']
                })
                setExpectedCARG(fairValueFinancialOutlook['expectedCARG'])
                setGroupDataLoaded(true)
            } catch (error) {
                console.error('Error fetching data:', error);
                setGroupedData(null)
                setGroupDataLoaded(false)

                setExpectedCAGRValues({
                    expectedTotalRevenueGrowth3YCARG: null,
                    expectedTotalRevenueGrowth5YCARG: null,
                })

                setExpectedCARG(null)
            }
        }

        void fetchFairValueFinancialOutlook()
    }, [code, exchange])

    const initialMarginExp = {profit_margin_exp: 0, fcf_margin_exp: 0}

    const [lowProfitMargin, setLowProfitMargin] = useState(initialMarginExp)
    const [lowProfitMarginLoaded, setLowProfitMarginLoaded] = useState(false)

    const [midProfitMargin, setMidProfitMargin] = useState(initialMarginExp)
    const [midProfitMarginLoaded, setMidProfitMarginLoaded] = useState(false)

    const [highProfitMargin, setHighProfitMargin] = useState(initialMarginExp)
    const [highProfitMarginLoaded, setHighProfitMarginLoaded] = useState(false)

    const initialYearsData = {
        code: {
            code: code,
            exchange: exchange
        },
        exp_growth: {
            year1: 0,
            year3: 0,
            year5: 0,
        },
        growth_decline_rate: {
            year1: null,
            year3: null,
            year5: null,
        },
        profit_margin_exp: {
            year1: 0,
            year3: 0,
            year5: 0,
        },
        fcf_margin_exp: {
            year1: 0,
            year3: 0,
            year5: 0,
        },
        desired_pe: {
            year1: 0,
            year3: 0,
            year5: 0,
        },
        desired_pfcf: {
            year1: 0,
            year3: 0,
            year5: 0,
        },
        discount_rate: {
            year1: null,
            year3: null,
            year5: null,
        },
    }

    const [yearsData, setYearsData] = useState(initialYearsData)

    useEffect(() => {
        const fetch_years_data = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/fair-value-years-data/${code}/${exchange}/`, Config());
                const data = response.data

                setYearsData({
                    ...initialYearsData,
                    ...data
                })
            } catch (e) {

            }
        }

        void fetch_years_data()
    }, [code, exchange])

    const initialEditableFields = {
        code: {
            code: code,
            exchange: exchange
        },
        exp_growth: {
            text: "Revenue Growth %",
            info: "Anticipated sales growth over the next 10 years, taking into account historical performance and market trends.",
            year1: 0,
            year3: 0,
            year5: 0,
            fields: {
                low: {editable: true, value: '-'},
                mid: {editable: true, value: '-'},
                high: {editable: true, value: '-'}
            }
        },
        growth_decline_rate: {
            text: "Growth Decline Rate %",
            info: "This variable considers the expected decrease in the growth rate over time, providing a more conservative calculation of future growth.",
            year1: null,
            year3: null,
            year5: null,
            fields: {
                low: {editable: true, value: 2},
                mid: {editable: true, value: 2},
                high: {editable: true, value: 2}
            }
        },
        profit_margin_exp: {
            text: "Profit Margin %",
            info: "Expected net profit relative to sales in the next 10 years, considering the company's historical performance and industry dynamics.",
            year1: 0,
            year3: 0,
            year5: 0,
            fields: {
                low: {editable: true, value: '-'},
                mid: {editable: true, value: '-'},
                high: {editable: true, value: '-'}
            }
        },
        fcf_margin_exp: {
            text: "FCF Margin %",
            info: "Expected free cash flow margin over the next 10 years, based on the company's operational efficiency and potential market changes.",
            year1: 0,
            year3: 0,
            year5: 0,
            fields: {
                low: {editable: true, value: '-'},
                mid: {editable: true, value: '-'},
                high: {editable: true, value: '-'}
            }
        },
        desired_pe: {
            text: "Desired P/E",
            info: "Desired valuation of the company in 10 years is determined based on the strength of its business model. We apply a base P/E of 15. The higher the moat the higher the P/E can be.",
            year1: 0,
            year3: 0,
            year5: 0,
            fields: {
                low: {editable: true, value: 15},
                mid: {editable: true, value: 18},
                high: {editable: true, value: 21}
            }
        },
        desired_pfcf: {
            text: "Desired P/FCF",
            info: "Similar to the P/E ratio, this metric determines the fair valuation of the company in 10 years, considering its competitive advantages (moat) and profitability. We apply a base P/FCF of 15. The higher the moat the higher the P/FCF can be.",
            year1: 0,
            year3: 0,
            year5: 0,
            fields: {
                low: {editable: true, value: 15},
                mid: {editable: true, value: 18},
                high: {editable: true, value: 21}
            }
        },
        discount_rate: {
            text: "Desired Return %",
            info: "This rate represents the opportunity cost of investing in a specific stock, reflecting the expected rate of return that an investor would require, and it is determined based on market conditions and the company's risk profile.",
            year1: null,
            year3: null,
            year5: null,
            fields: {
                low: {editable: true, value: 10},
                mid: {editable: true, value: 12},
                high: {editable: true, value: 14}
            }
        },
    }

    const [editableFields, setEditableFields] = useState(initialEditableFields)

    const changeInputData = (e, key, innerKey) => {

        if (e.target.value === '') {
            return
        }

        if (e.target.value.includes('.') && e.target.value.split('.')[1].length > 2) {
            return
        }

        setEditableFields({
            ...editableFields,
            [key]: {
                ...editableFields[key],
                fields: {
                    ...editableFields[key]['fields'],
                    [innerKey]: {
                        ...editableFields[key]['fields'][innerKey],
                        value: e.target.value
                    }
                }
            }
        })
    }

    const [currentPrice, setCurrentPrice] = useState(0)

    const initialFairValueCalculation = {
        earnings_valuation: {
            current_price: 0,
            intrinsic_value: 0,
            fair_value: 0,
            fair_value_evaluation_per: 0,
            fair_value_evaluation: "",
        },
        dcf_valuation: {
            current_price: 0,
            intrinsic_value: 0,
            fair_value: 0,
            fair_value_evaluation_per: 0,
            fair_value_evaluation: "",
        },
        potential_return: 0
    }

    const [lowFairValueCalculation, setLowFairValueCalculation] = useState(initialFairValueCalculation)

    const [midFairValueCalculation, setMidFairValueCalculation] = useState(initialFairValueCalculation)

    const [highFairValueCalculation, setHighFairValueCalculation] = useState(initialFairValueCalculation)

    useEffect(() => {
        setLowFairValueCalculation(initialFairValueCalculation)
        setMidFairValueCalculation(initialFairValueCalculation)
        setHighFairValueCalculation(initialFairValueCalculation)

        setLowProfitMargin(initialMarginExp)
        setLowProfitMarginLoaded(false)

        setMidProfitMargin(initialMarginExp)
        setMidProfitMarginLoaded(false)

        setHighProfitMargin(initialMarginExp)
        setHighProfitMarginLoaded(false)

        setNetIncomeFutureValues([])
        setFCFFutureValues([])
        setFutureValuesLoaded(false)

        let high = Math.floor(parseFloat(return_strong_num(expectedCARG * 100)))

        if (high === null || isNaN(high) || high < 8) {
            high = 8;
        } else if (high > 30) {
            high = 30
        }

        const mid = high - 3
        const low = high - 6

        setEditableFields({
            ...initialEditableFields,
            code: {
                code: code,
                exchange: exchange
            },
            exp_growth: {
                ...initialEditableFields.exp_growth,
                fields: {
                    low: {editable: true, value: low},
                    mid: {editable: true, value: mid},
                    high: {editable: true, value: high}
                }
            }
        })

        // setYearsData(initialYearsData)

    }, [code, exchange, expectedCARG])

    const fairValuation = async (code, exchange, exp_growth, growth_decline_rate, desired_pe, desired_pfcf, profit_margin_exp, fcf_margin_exp, discount_rate, type, setFairValueCalculation) => {
        if (editableFields.exp_growth.fields.high.value === '-') {
            return
        }

        const earning_response = await axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/pe-valuation-test/`,
            {
                code,
                exchange,
                exp_growth,
                growth_decline_rate,
                desired_pe,
                desired_pfcf,
                profit_margin_exp,
                fcf_margin_exp,
                discount_rate
            }, Config());

        const earning = earning_response.data;

        const dcf_response = await axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/dcf-valuation-test/`,
            {
                code,
                exchange,
                exp_growth,
                growth_decline_rate,
                desired_pe,
                desired_pfcf,
                profit_margin_exp,
                fcf_margin_exp,
                discount_rate
            }, Config());

        const dcf = dcf_response.data

        setCurrentPrice(dcf.current_price)

        setFairValueCalculation({
            earnings_valuation: {
                ...earning
            },
            dcf_valuation: {
                ...dcf
            },
            potential_return: dcf.potential_return
        })

        let earning_profit_margin_exp = earning.profit_margin_exp
        let dcf_fcf_margin_exp = dcf.fcf_margin_exp

        if (earning_profit_margin_exp === null || earning_profit_margin_exp < 8) {
            earning_profit_margin_exp = 8
        } else if (earning_profit_margin_exp > 30) {
            earning_profit_margin_exp = 30
        }

        if (dcf_fcf_margin_exp === null || dcf_fcf_margin_exp < 8) {
            dcf_fcf_margin_exp = 8
        } else if (dcf_fcf_margin_exp > 30) {
            dcf_fcf_margin_exp = 30
        }

        if (type === 'low') {
            setLowProfitMargin({
                profit_margin_exp: earning_profit_margin_exp - 6,
                fcf_margin_exp: dcf_fcf_margin_exp - 6
            })
            setLowProfitMarginLoaded(true)
        } else if (type === "mid") {
            setMidProfitMargin({
                profit_margin_exp: earning_profit_margin_exp - 3,
                fcf_margin_exp: dcf_fcf_margin_exp - 3
            })
            setMidProfitMarginLoaded(true)
        } else {
            setNetIncomeFutureValues(earning.future_values)
            setFCFFutureValues(dcf.future_values)

            setFutureValuesLoaded(true)

            setHighProfitMargin({
                profit_margin_exp: earning_profit_margin_exp,
                fcf_margin_exp: dcf_fcf_margin_exp
            })
            setHighProfitMarginLoaded(true)
        }
    }

    useEffect(() => {
        if (lowProfitMarginLoaded && midProfitMarginLoaded && highProfitMarginLoaded) {
            setEditableFields({
                ...editableFields,
                profit_margin_exp: {
                    ...editableFields.profit_margin_exp,
                    fields: {
                        low: {editable: true, value: lowProfitMargin.profit_margin_exp},
                        mid: {editable: true, value: midProfitMargin.profit_margin_exp},
                        high: {editable: true, value: highProfitMargin.profit_margin_exp}
                    }
                },
                fcf_margin_exp: {
                    ...editableFields.fcf_margin_exp,
                    fields: {
                        low: {editable: true, value: lowProfitMargin.fcf_margin_exp},
                        mid: {editable: true, value: midProfitMargin.fcf_margin_exp},
                        high: {editable: true, value: highProfitMargin.fcf_margin_exp}
                    }
                },
            })
        }
    }, [lowProfitMarginLoaded, midProfitMarginLoaded, highProfitMarginLoaded])

    useEffect(() => {
        fairValuation(code, exchange,
            editableFields.exp_growth.fields.low.value,
            editableFields.growth_decline_rate.fields.low.value,
            editableFields.desired_pe.fields.low.value,
            editableFields.desired_pfcf.fields.low.value,
            editableFields.profit_margin_exp.fields.low.value,
            editableFields.fcf_margin_exp.fields.low.value,
            editableFields.discount_rate.fields.low.value,
            'low',
            setLowFairValueCalculation)
    }, [
        editableFields.code,
        editableFields.exp_growth.fields.low.value,
        editableFields.growth_decline_rate.fields.low.value,
        editableFields.desired_pe.fields.low.value,
        editableFields.desired_pfcf.fields.low.value,
        editableFields.profit_margin_exp.fields.low.value,
        editableFields.fcf_margin_exp.fields.low.value,
        editableFields.discount_rate.fields.low.value
    ])

    useEffect(() => {
        fairValuation(code, exchange,
            editableFields.exp_growth.fields.mid.value,
            editableFields.growth_decline_rate.fields.mid.value,
            editableFields.desired_pe.fields.mid.value,
            editableFields.desired_pfcf.fields.mid.value,
            editableFields.profit_margin_exp.fields.mid.value,
            editableFields.fcf_margin_exp.fields.mid.value,
            editableFields.discount_rate.fields.mid.value,
            'mid',
            setMidFairValueCalculation)

    }, [
        editableFields.code,
        editableFields.exp_growth.fields.mid.value,
        editableFields.growth_decline_rate.fields.mid.value,
        editableFields.desired_pe.fields.mid.value,
        editableFields.desired_pfcf.fields.mid.value,
        editableFields.profit_margin_exp.fields.mid.value,
        editableFields.fcf_margin_exp.fields.mid.value,
        editableFields.discount_rate.fields.mid.value
    ])

    useEffect(() => {
        fairValuation(code, exchange,
            editableFields.exp_growth.fields.high.value,
            editableFields.growth_decline_rate.fields.high.value,
            editableFields.desired_pe.fields.high.value,
            editableFields.desired_pfcf.fields.high.value,
            editableFields.profit_margin_exp.fields.high.value,
            editableFields.fcf_margin_exp.fields.high.value,
            editableFields.discount_rate.fields.high.value,
            'high',
            setHighFairValueCalculation)

    }, [
        editableFields.code,
        editableFields.exp_growth.fields.high.value,
        editableFields.growth_decline_rate.fields.high.value,
        editableFields.desired_pe.fields.high.value,
        editableFields.desired_pfcf.fields.high.value,
        editableFields.profit_margin_exp.fields.high.value,
        editableFields.fcf_margin_exp.fields.high.value,
        editableFields.discount_rate.fields.high.value
    ])

    useEffect(() => {
        async function fetchFairValueFinancialOutlookFutureValues() {
            try {

                const data = {
                    code: code,
                    exchange: exchange,
                    totalRevenue: groupedData['content']['financials']['data'][`Total Revenue`].slice(0, 2).map(item => item.replace(',', '')),
                    netIncome: netIncomeFutureValues,
                    freeCashFlow: FCFFutureValues,
                    eps: groupedData['content']['ratios']['data'][`EPS Non-GAAP`].slice(0, 2)
                }

                const response = await axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/fair-value-financial-outlook-future-values/`, data, Config());
                const fairValueFinancialOutlookFutureValues = response.data;

                let groupData = {...groupedData}

                const length = groupedData['content']['financials']['data'][`Net Income`].length

                groupData['content']['financials']['data'][`Net Income`] = [...netIncomeFutureValues.map(item => numberWithCommas(Math.floor(return_strong_num(item)))), ...groupedData['content']['financials']['data'][`Net Income`].slice(2, length)]
                groupData['content']['financials']['data'][`% Profit Margin`] = [...fairValueFinancialOutlookFutureValues['growth']['netIncome'].map(item => return_strong_num(item * 100)), ...groupedData['content']['financials']['data'][`% Profit Margin`].slice(2, length)]

                groupData['content']['financials']['data'][`Free Cash Flow`] = [...FCFFutureValues.map(item => numberWithCommas(Math.floor(return_strong_num(item)))), ...groupedData['content']['financials']['data'][`Free Cash Flow`].slice(2, length)]
                groupData['content']['financials']['data'][`% FCF Margin`] = [...fairValueFinancialOutlookFutureValues['growth']['fcf'].map(item => return_strong_num(item * 100)), ...groupedData['content']['financials']['data'][`% FCF Margin`].slice(2, length)]

                groupData['content']['ratios']['data']['P/E'] = [...fairValueFinancialOutlookFutureValues['values']['P/E'].map(item => return_strong_num(item)), ...groupedData['content']['ratios']['data'][`P/E`].slice(2, length)]
                groupData['content']['ratios']['data']['P/FCF'] = [...fairValueFinancialOutlookFutureValues['values']['P/FCF'].map(item => return_strong_num(item)), ...groupedData['content']['ratios']['data'][`P/FCF`].slice(2, length)]
                groupData['content']['ratios']['data']['P/S'] = [...fairValueFinancialOutlookFutureValues['values']['P/S'].map(item => return_strong_num(item)), ...groupedData['content']['ratios']['data'][`P/S`].slice(2, length)]

                setGroupedData(groupData)
                setFutureValuesLoaded(false)

            } catch (error) {
                // Handle errors, e.g., show an error message
                console.error('Error fetching data:', error);
            }
        }

        if (groupDataLoaded && futureValuesLoaded) {
            void fetchFairValueFinancialOutlookFutureValues()
        }
    }, [groupDataLoaded, futureValuesLoaded]);

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use  this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={fairValueDark}
            lightBlockerImg={fairValueLight}
            code={code}
            exchange={exchange}

            content={
                <div style={{position: 'relative', width: '100%', height: '100%'}}>
                    <div className={'row g-0'}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <FairValueInputTable
                                editableFields={editableFields}
                                yearsData={yearsData}
                                lowFairValueCalculation={lowFairValueCalculation}
                                midFairValueCalculation={midFairValueCalculation}
                                highFairValueCalculation={highFairValueCalculation}
                                changeInputData={changeInputData}
                                currentPrice={currentPrice}
                            />
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                             style={{padding: "0 5px"}}>
                            <FairValueInfoTable code={code} exchange={exchange} groupedData={groupedData}
                                                expectedCAGRValues={expectedCAGRValues} expectedCARG={expectedCARG}/>
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default FairValueTabs;
