import {FaLinkedinIn, FaTiktok} from 'react-icons/fa'
import {BsFacebook, BsTwitter, BsYoutube} from 'react-icons/bs'
import logo from '../assets/finqube-navbar-logo.png'
import React from "react";
import {useNavigate} from "react-router-dom";

const RegisterLayout = ({children}) => {
    const navigate = useNavigate()
    return (
        <div className={'accounts-container'}>
            <div className={'ornament-2'}/>
            <div className={'login-whole-container'}>
                <div className="container">
                    <div className={'landing-page-navbar'}>
                        <a style={{marginLeft: 'auto'}} href={'https://web.finqube.io/about'}
                           className={'landing-page-navbar-link'}>About</a>
                        <a href={'https://web.finqube.io/about'} className={'landing-page-navbar-link'}>FAQ</a>
                        <a href={'https://web.finqube.io/tools/'} className={'landing-page-navbar-link'}>Tools</a>
                        {/*<a href={'https://web.finqube.io/blog'} className={'landing-page-navbar-link'}>Blog</a>*/}
                        <p onClick={() => {
                            navigate('/payment/')
                        }} className={'landing-page-navbar-link'}>Pricing</p>
                    </div>
                </div>
                <div className="container" style={{margin: 'auto'}}>
                    <div className="row accounts-content-container">
                        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block col-xl-6 col-lg-6 col-md-5">
                            <div className={'h-100'} style={{display: 'flex', flexDirection: 'column'}}>

                                <div style={{display: 'flex'}}>
                                    <img onClick={() => {
                                        navigate('/')
                                    }} className={'login-logo'} src={logo}/>
                                </div>

                                <div style={{margin: "60px 0 auto 0", textAlign: 'left'}}>
                                    <p className={'register-heading-text'}>Finqube.io simplifies financial data for
                                        you.</p>
                                    <p className={'register-heading-main'}>Invest Smart</p>
                                    <p className={'register-heading-para'}>Make confident investment decisions. Research
                                        quickly and conveniently over 50,000+ stocks with in depth fundamental data and
                                        ratios. <br/><br/> Save countless hours by checking reports, data, numbers and
                                        ratios. A
                                        cutting-edge financial platform that helps to analyze and interpret stocks.
                                    </p>
                                </div>
                                <div style={{display: 'flex', margin: '20px 0'}}>
                                    <a href={'https://www.facebook.com/finqube'} target={"_blank"}>
                                        <BsFacebook className={'socials'}/>
                                    </a>
                                    <a href={'https://www.linkedin.com/company/finqube'} target={"_blank"}>
                                        <FaLinkedinIn className={'socials'}/>
                                    </a>
                                    <a href={'https://www.youtube.com/@finqube'} target={"_blank"}>
                                        <BsYoutube className={'socials'}/>
                                    </a>
                                    <a href={'https://twitter.com/finqube'} target={"_blank"}>
                                        <BsTwitter className={'socials'}/>
                                    </a>
                                    <a href={'https://www.tiktok.com/@finqube'} target={"_blank"}>
                                        <FaTiktok className={'socials'}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="d-xl-none d-lg-none d-md-none col-sm-12 col-xs-12 my-1">
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <img onClick={() => {
                                    navigate('/')
                                }} className={'login-logo'} style={{margin: 'auto'}} src={logo}/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12 col-xs-12" style={{display: 'flex'}}>
                            {children}
                        </div>
                        <div className="d-xl-none d-lg-none d-md-none col-sm-12 col-xs-12 mt-2"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterLayout;
