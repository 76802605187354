import axios from 'axios';
import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    SIGNUP_FAILED,
    SIGNUP_SUCCESS,
    COMMON_STOCK_LOAD_SUCCESS,
    COMMON_STOCK_LOADED_FAILED,
    ETF_LOAD_SUCCESS,
    ETF_LOADED_FAILED,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_FAILED,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAILED,
    REFRESH_TOKEN, CHANGE_THEME
} from "./types";
import {toast} from "react-hot-toast";
import {raise_error} from '../Container/Utils';
import Config from "../Config";


export const load_common_stock = (code, exchange) => dispatch => {
    try {
        dispatch({
            type: COMMON_STOCK_LOAD_SUCCESS,
            payload: {code, exchange}
        })
    } catch (e) {
        dispatch({
            type: COMMON_STOCK_LOADED_FAILED
        })
    }
}

export const load_common_etf = (code, exchange) => dispatch => {
    try {
        dispatch({
            type: ETF_LOAD_SUCCESS,
            payload: {code, exchange}
        })
    } catch (e) {
        dispatch({
            type: ETF_LOADED_FAILED
        })
    }
}

export const load_user = () => async dispatch => {
    const config = Config()
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-accounts/load-user/`, config)
        dispatch({
            type: LOAD_USER_SUCCESS,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: LOAD_USER_FAILED
        })
    }
}

export const signup = (data, navigate) => async dispatch => {
    await toast.promise(axios.post(`${process.env.REACT_APP_API_URL}/finqube-accounts/register/`, data, Config()), {
        loading: 'Signing up...',
        success: (res) => {
            dispatch({
                type: SIGNUP_SUCCESS
            });
            navigate('/check-inbox/')
            return 'Account created'
        },
        error: (err) => {
            dispatch({
                type: SIGNUP_FAILED
            })
            return raise_error(err)
        }
    })
}

export const login = (data, navigate) => async dispatch => {
    await toast.promise(axios.post(`${process.env.REACT_APP_API_URL}/finqube-accounts/login/`, data, Config()), {
        loading: 'Logging in...',
        success: (res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            })
            if (res.data.user.subscription === null) {
                navigate('/payment')
            } else {
                navigate('/market-overview/')
            }
            return "Logged In, Welcome"
        },
        error: (err) => {
            dispatch({
                type: LOGIN_FAILED
            })
            return raise_error(err)
        }
    })
}

export const RefreshToken = () => async dispatch => {
    const config = Config()
    axios.post(`${process.env.REACT_APP_API_URL}/finqube-accounts/refresh-token/`, {access: localStorage.getItem('access_token')}, config)
        .then((res) => {
            dispatch({
                payload: res.data,
                type: REFRESH_TOKEN
            })
        })
        .catch(() => {
            dispatch(Logout())
        })
}

export const VerifyToken = (navigate) => async dispatch => {
    const config = Config()
    axios.post(`${process.env.REACT_APP_API_URL}/finqube-accounts/verify-token/`, {access: localStorage.getItem('access_token')}, config)
        .then(() => {

        })
        .catch(() => {
            toast('Session Timeout, please login again', {duration: 10000})
            dispatch(Logout())
            navigate('/login/')
            window.location.reload();
        })
}

export const Logout = () => async dispatch => {
    try {
        dispatch({
            type: LOGOUT_SUCCESS
        })
    } catch (e) {
        dispatch({
            type: LOGOUT_FAILED
        })
    }
}

export const free_subscription = (navigate) => async dispatch => {
    const config = Config()
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/finqube-payment/free-subscription/`, {}, config)
        dispatch({
            type: SUBSCRIPTION_SUCCESS,
            payload: res.data
        })
        navigate('/market-overview/')
    } catch (e) {
        dispatch({
            type: SUBSCRIPTION_FAILED
        })
    }
}

export const paypal_subscription = (body, navigate) => async dispatch => {
    const config = Config()
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/finqube-payment/paypal-payment/`, body, config)
        dispatch({
            type: SUBSCRIPTION_SUCCESS,
            payload: res.data
        })
        toast.success('Subscription Successful', {duration: 3000})
        navigate('/market-overview/')
    } catch (e) {
        dispatch({
            type: SUBSCRIPTION_FAILED
        })
    }
}

export const change_theme = (theme) => dispatch => {
    dispatch({
        type: CHANGE_THEME,
        payload: theme
    })
}
