import React, {useEffect, useState} from 'react';
import {add, return_float, return_float_fixed} from "../../Utils";
import priceTargetDark from "../../../assets/blockers/price-target-dark.png";
import priceTargetLight from "../../../assets/blockers/price-target-light.png";
import AnalystForcastChart from "./AnalystForcastChart";
import PriceTargetInfoOuterContainer from "./PriceTargetInfoOuterContainer";
import CompanyOverViewPerformanceTable from "../CompanyTables/CompanyOverViewPerformanceTable";
import axios from "axios";
import Config from "../../../Config";
import FinancialOutlookChart from "./FinancialOutlookChart";
import ValuationDataTable from "../../ValuationTabs/ValuationDataTable";
import EarningTabs from "../EarningTabs";
import RestrictedContent from "../../RestrictedContent";

function AnalystForecast({code, exchange}) {
    const [highlights, setHighlights] = useState(null)

    const [analystRating, setAnalystRating] = useState({
        total: "",
        buy: "",
        hold: "",
        sell: ""
    })

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-analyst-forcast-highlights/${code}/${exchange}/`, Config())
            .then(response => {
                setHighlights(response.data)

                const data = response.data

                let analystRating = {}
                analystRating['total'] = (add(add(add(add(return_float(data['analystRatings']['Buy']), return_float(data['analystRatings']['StrongBuy'])), return_float(data['analystRatings']['Hold'])), return_float(data['analystRatings']['Sell'])), return_float(data['analystRatings']['StrongSell'])))

                analystRating['buy'] = return_float_fixed(add(return_float(data['analystRatings']['Buy']), return_float(data['analystRatings']['StrongBuy'])))

                analystRating['hold'] = return_float_fixed(return_float(data['analystRatings']['Hold']))

                analystRating['sell'] = return_float_fixed(add(return_float(data['analystRatings']['Sell']), return_float(data['analystRatings']['StrongSell'])))

                setAnalystRating(analystRating)
            })
    }, [code, exchange])

    const [tableData, setTableData] = useState(null)

    useEffect(() => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/get-valuation-table/`,
            {
                code: code,
                exchange: exchange,
            }, config)
            .then(response => {
                setTableData(response.data)
            })
            .catch(error => console.log(error.message))
    }, [code, exchange])

    return (
        <div>
            <RestrictedContent
                conditionText={'You must be paid subscriber to use  this feature'}
                blockerLg={true}
                loginRequired={true}
                subscriptionRequired={true}

                darkBlockerImg={priceTargetDark}
                lightBlockerImg={priceTargetLight}
                code={code}
                exchange={exchange}

                content={
                    <>
                        {highlights &&
                            <div className="row g-0">
                                <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <div className={'price-target-display-container-xl'}>
                                        <AnalystForcastChart code={code} exchange={exchange} upside={highlights.upside}
                                                             currency={highlights.currency}/>
                                    </div>
                                    <div className={'price-target-display-container-lg'} style={{marginBottom: 10}}>
                                        <PriceTargetInfoOuterContainer code={code} highlights={highlights}
                                                                       analystRating={analystRating}/>
                                    </div>

                                    <div className={'price-target-display-container-lg'}>
                                        <AnalystForcastChart code={code} exchange={exchange}
                                                             upside={highlights.upside}
                                                             currency={highlights.currency}/>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <CompanyOverViewPerformanceTable title={'Performance'}
                                                                     performance={highlights.performance}
                                                                     priceTarget={true}/>
                                </div>

                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className={'price-target-display-container-xl'}>
                                        <PriceTargetInfoOuterContainer code={code} highlights={highlights}
                                                                       analystRating={analystRating}/>
                                    </div>
                                </div>
                            </div>
                        }

                        <EarningTabs code={code} exchange={exchange}/>

                        {tableData &&
                            <div className={"row g-0 mt-2"}>
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                    <FinancialOutlookChart code={code} tableData={tableData} />
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                    <div className={'financial-outlook-valuation'}>
                                        <ValuationDataTable tableData={tableData} />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            />
        </div>
    );
}

export default AnalystForecast;