import IndexTopBar from "./IndexTopBar";
import IndexStats from "./IndexStats";
import IndicesSelectableTable from "./IndicesSelectableTable";
import TsiIndexTable from "../../TSI/TSIIndexTable";

const Index = ({
                   index,
                   selectedIndexData,
                   loadingSelectedIndex,
                   tsi
               }) => {

    return (
        <>
            {
                loadingSelectedIndex ?
                    <div className={'generic-loading-container'}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    : tsi
                        ?
                        <TsiIndexTable selectedIndexData={selectedIndexData}/>
                        :
                        <>
                            <IndexTopBar index={index} selectedIndexData={selectedIndexData}/>
                            <IndexStats index={index} selectedIndexData={selectedIndexData}/>
                            <IndicesSelectableTable selectedIndexData={selectedIndexData} tsi={tsi}/>
                        </>
            }
        </>
    );
}

export default Index;
