import axios from "axios";

function Config() {
    const config = {
        header: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Accept': 'application/json',
            'Security-header-xyz': 'Security-header-xyz',
            'Strict-Transport-Security': 'max-age',
            'X-Content-Type-Options': 'nosniff',
            'X-Frame-Options': 'DENY'
        }
    }

    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
    axios.defaults.headers.common['Accept'] = 'application/json'
    axios.defaults.headers.common['Security-header-xyz'] = 'Security-header-xyz'

    axios.defaults.headers.common['Strict-Transport-Security'] = 'max-age'
    axios.defaults.headers.common['X-Content-Type-Options'] = 'nosniff'
    axios.defaults.headers.common['X-Frame-Options'] = 'DENY'

    return config
}

export default Config;