import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    SIGNUP_FAILED,
    SIGNUP_SUCCESS,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_FAILED,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAILED,

    COMMON_STOCK_LOAD_SUCCESS,
    COMMON_STOCK_LOADED_FAILED,
    ETF_LOAD_SUCCESS,
    ETF_LOADED_FAILED,
    REFRESH_TOKEN, CHANGE_THEME
} from "../actions/types";

const initialState = {
    access_token: null,
    isAuthenticated: false,
    user: {
        subscription: null
    },
    common_stock: {code: 'AAPL', exchange: "US"},
    etf: {code: 'ESPO', exchange: 'US'},
    theme: 'light'
}

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('access_token', payload.access_token)
            return {
                ...state,
                access_token: payload.access_token,
                isAuthenticated: true,
                user: payload.user
            }
        case REFRESH_TOKEN:
            localStorage.setItem('access_token', payload.access_token)
            return {
                ...state,
                access_token: payload.access_token,
            }
        case SIGNUP_SUCCESS:
            return {
                ...state
            }
        case LOAD_USER_SUCCESS:
            return {
                ...state,
                user: payload.user
            }
        case SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                user: payload.user
            }
        case COMMON_STOCK_LOAD_SUCCESS:
            return {
                ...state,
                common_stock: payload
            }
        case ETF_LOAD_SUCCESS:
            return {
                ...state,
                etf: payload
            }
        case CHANGE_THEME:
            return {
                ...state,
                theme: payload
            }
        case LOGOUT_SUCCESS:
            localStorage.removeItem('access_token')
            return {
                access_token: null,
                isAuthenticated: false,
                user: {
                    subscription: null
                },
                common_stock: {code: 'AAPL', exchange: "US"},
                etf: {code: 'ESPO', exchange: 'US'},
                theme: state.theme
            }
        case LOGIN_FAILED:
        case SIGNUP_FAILED:
        case LOGOUT_FAILED:
        case SUBSCRIPTION_FAILED:
        case LOAD_USER_FAILED:
        case COMMON_STOCK_LOADED_FAILED:
        case ETF_LOADED_FAILED:
            return {
                ...state
            }
        default:
            return state
    }
}
